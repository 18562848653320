import React from 'react';
import '../../assets/css/components/pageNotFound.css';
import logoPrometheus from '../../assets/images/logoPrometheus.fw.png'

const PageNotFound = ({ codeResponse, messageResponse }) => {
    return (
        <>
            <div className="background">
                <div className="ground"></div>
            </div>
            <div className="container-not-found">
                <div className="left-section">
                    <div className="inner-content">
                        <h1 className="heading">{ codeResponse || 404 }</h1>
                        <p className="subheading">{ messageResponse || "Parece que la página que estabas buscando ya no está aquí." }</p>
                    </div>
                </div>
                <div className="right-section">
                    <img className='mt-3' src={logoPrometheus}></img>
                </div>
            </div>
        </>
    )
}

export default PageNotFound;