import React from 'react';
import { LabelTituloGeneral } from './LabelTituloGeneral';
import { SearchTable }  from '../../components/utils/SearchTable';

export const ContenedorParametros = ({
    titulo = '',
    children,
    tablaId = null
}) => {
    return (
        <>
            <div className='flex items-center' >
                {children}                        
            </div>

            <hr className='mt-2 mb-[10px]'/>
        </>
    )
}