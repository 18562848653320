import React, { useEffect, useState, useRef } from 'react';
import { SearchTable } from '../../components/utils/SearchTable';
import { Modal } from '../../components/modal/Modal';
import { useProductos } from '../../../hooks/useProductos';
import { Tooltip } from '../../components/utils/Tooltip';
import { useVentas } from '../../../hooks/useVentas';
import { useModal } from '../../../hooks/useModal';
import { useClientes } from '../../../hooks/useClientes';
import { notify } from '../../../utils/utils';
import { ModalPopUp } from '../../components/modal/ModalPopUp';
import logo from '../../../assets/images/logoPrometheuslargo.png';
import html2canvas from 'html2canvas';
import PDFExporter from '../../components/PDFExporter';
import moment from 'moment-timezone';
import InputText from '../../components/InputText';
import SelectText from '../../components/SelectText';

import { Side } from '../../components/modal/Side';


export const RegistroVenta = () => {

    const divRef = useRef('divVoucher');

    const { listarVentas,
        obtenerUltimaVenta,
        obtenerVenta,
        ventaEliminar,
        eliminarVenta,
        guardarEditarVenta,
        editarValorVenta,
        ventas,
        venta,
        ventaDetalle,
        ventaDetalles,
        
        listarDetalleVentas,
        listarDetalleVentaPorCodVenta,
        obtenerDetalleVenta,
        obtenerDetalleVentaPorCodDetalleVenta,
        eliminarVentaDetalle,
        guardarEditarDetalleVenta,
        editarValorDetalleVenta,
        editarValorDetalleVenta2,
            } = useVentas()
    
    const { listarClientes, clientes } = useClientes()
    const { listarProductos, productos } = useProductos()

    const [ isOpenModalListadoProductos, openModalListadoProductos, closeModalListadoProductos ] = useModal();
    const [ isOpenModalPopUp, openModalPopUp, closeModalPopUp ] = useModal();
    // const [ codVentaGeneral, setCodVentaGeneral] = useState(0)
    const [ isOpenModalListadoClientes, openModalListadoClientes, closeModalListadoClientes ] = useModal();
    // const [productoCantidad, setProductoCantidad ] = useState()

    const printRef = useRef();
    const printRef2 = useRef();

    const idTablaProductos = "tablaProductos"
    const idTablaClientes = "tablaClientes"
    const [ montoTotalR, setMontoTotalR ] = useState(0.00)

    const ventaDatos={
        MontoTotal:0,
    }
    
    // const detalleVentaModalDefault = {
    //     CodVenta:0,
    //     CodProducto:0,
    //     Nombre:"",
    //     Precio:0,
    //     Cantidad:1,
    //     PrecioTotal:0,
    // }

    // const [detalleVentaModal,setDetalleVentaModal] = useState(detalleVentaModalDefault)

    // const editarValorDetalleVentaModal = (key, value) => {
    //     setDetalleVentaModal(detalleVentaModal => {
    //         return {
    //             ...detalleVentaModal,
    //             [key]: value
    //         }
    //     });
    // }

    // const agregarProducto = async(producto)  => {
    //     if(detalleVentaModal.Cantidad <= 0){
    //         notify("Ingrese una cantidad válida","error")
    //         return
    //     }
    //     console.log("ANTES DE ENTRAR AL si COdUltimaVenta == 0 -> ",CodUltimaVentaUse )
    //     // obtenerUltimaVenta()
    //     // return
    //     if  (CodUltimaVentaUse == 0){
    //         console.log("ENTRO AL IF ")
    //         await guardarEditarVenta()
    //         await obtenerUltimaVenta()
    //         console.log("codUltimaVenta-> ",CodUltimaVentaUse)
    //     }
    //     console.log("PRODUCTO -> ", producto)
    //     console.log("detalleVentaModal -> ", detalleVentaModal)
    
    //     detalleVenta.CodProducto = producto.CodProducto
    //     detalleVenta.NombreProducto = producto.Nombre
    //     detalleVenta.PrecioUnitario = producto.PrecioVenta
    //     detalleVenta.Cantidad = parseFloat(detalleVentaModal.Cantidad)
    //     detalleVenta.PrecioTotal = parseFloat(detalleVentaModal.Cantidad) * parseFloat(producto.PrecioVenta)

    //     venta.MontoTotal =  parseFloat(venta.MontoTotal) + parseFloat(detalleVenta.PrecioTotal)
    //     ventaDatos.MontoTotal = venta.MontoTotal
    //     console.log("MONTO TOTAL USE -> ", venta.MontoTotal)
    //     console.log("ventaDatos.MontoTotal TOTAL USE -> ", ventaDatos.MontoTotal)
    //     setMontoTotalR(ventaDatos.MontoTotal)
    //     console.log("MONTO TOTAL -> ", montoTotalR)

    //     console.log("ANTES DE GUARDAR EL PRODUCTO EN LA VENTA-> ", detalleVenta)
    //     await guardarEditarDetalleVenta()
    //     console.log("codUltimaVenta-> ",CodUltimaVentaUse)
    //     closeModalListadoProductos()
        
    //     setDetalleVentaModal(detalleVentaModalDefault)
    //     console.log("MONTO TOTAL FINAL -> ", ventaMontoTotal)
    // }
    
    const shareOnWhatsApp = () => {
        html2canvas(divRef.current)
        .then((canvas) => {
            const dataURL = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = dataURL;
            link.download = 'div_image.png';
            link.click();
        })
        .catch((error) => {
        console.error('Error capturing div as image:', error);
        });
    };


    
    let linkMensaje = `NOTA DE VENTA:NV01-000523%0a`
    productos?.map((productol,i) => (
        linkMensaje  +=  ` 2 ${productol.Nombre} S/${productol.PrecioVenta}%0a`
    ))
    linkMensaje += `Total: S/500.00`


    // const guardarVenta = async ()=>{
    //     await guardarEditarVenta()
    //     limpiarVentas()
    //     openModalPopUp()

    // }



    useEffect(()=>{
        listarClientes()
        listarProductos()
        // listarDetalleVentaPorCodVenta(codUltimaVenta)
    },[])

    // useEffect(()=>{
        
    // },[detalleVenta])


    const printDiv = () => {
        var divContents = printRef.current.innerHTML;
        var a = window.open('', '', 'height=600px, width=500px');
        // a.document.write('<html><body>');
        // a.document.write('<div>')
        a.document.write(divContents);
        // a.document.write('</div>')
        // a.document.write('</body></html>');
        a.document.close();

        // setTimeout(() => {
        //     a.print();
        // }, 1000)
            a.print();
    }
    
    const printDiv2 = () => {
        var divContents = printRef2.current.innerHTML;
        var a = window.open('', '', 'height=600px, width=500px');
        // a.document.write('<html><body>');
        // a.document.write('<div>')
        a.document.write(divContents);
        // a.document.write('</div>')
        // a.document.write('</body></html>');
        a.document.close();

        // setTimeout(() => {
        //     a.print();
        // }, 1000)
            a.print();
    }

    const tableDataV = [{nombre:"Producto1",Precio:"S/50"},{nombre:"Producto2",Precio:"S/50"}];

    const documentos = [
        {CodDocumento: 1 , NombreDocumento: "Proforma"  },
        {CodDocumento: 2 , NombreDocumento: "Boleta"  },
        {CodDocumento: 3 , NombreDocumento: "Factura"  },
        {CodDocumento: 4 , NombreDocumento: "Nota Venta"  },
    ]

    const opcionesDocumento = documentos.map((opcion) => ({
        id: opcion.CodDocumento,
        label: opcion.NombreDocumento,
    }));


    const opcionesCliente = clientes.map((opcion) => ({
        id: opcion.CodCliente,
        label: opcion.Nombres,
    }));

    return (
        <>          

                <div>

                    <div className='hidden' id='divPDF' ref={divRef}>
                    <div  className="border " style={{display: 'flex', flexDirection: 'column', justifyContent: '',fontFamily:' Helvetica, Arial, sans-serif;'}}>
                            <div style={{width:'100%',textAlign:'center',marginBottom:'1px',marginTop:'5px'}}>
                                <label style={{textDecoration: 'bold',color:'black',fontSize:'15px',fontWeight:'bold'}}>
                                    NOTA DE VENTA 
                                </label>              
                                <label style={{display:'block',color:'black',zIndex:'100',marginTop:'0px',fontSize:'15px',borderBottom:'1px solid'}}>
                                    NV01 - 000523
                                </label>
                            </div>
                            <div style={{textAlign:'center',marginTop:'0',fontWeight:'bold'}}>
                                <label style={{display:'block',color:'black',zIndex:'100',marginTop:'0px',fontSize:'15px',borderBottom:'2px solid'}}>
                                DATOS CLIENTE
                                </label>
                            </div>
                            <label style={{display:'block',color:'black',zIndex:'100',marginTop:'0px',fontSize:'13px'}}>
                                CLIENTE: Henry Cerna Quispe 
                            </label>
                            <label style={{display:'block',color:'black',zIndex:'100',marginTop:'0px',fontSize:'13px',borderBottom:'1px solid'}}>
                                Telefono: 999999999 
                            </label>
                            <div  style={{textAlign:'center',marginTop:'0',fontWeight:'bold'}}>
                                <label style={{display:'block',color:'black',zIndex:'100',marginTop:'0px',fontSize:'15px',borderBottom:'2px solid'}}>
                                DETALLE VENTA
                                </label>
                            </div>
                            <label style={{display:'block',color:'black',zIndex:'100',marginTop:'0px',fontSize:'13px'}}>
                                FECHA: 16/05/2023 
                            </label>
                            <label style={{display:'block',color:'black',zIndex:'100',marginTop:'0px',fontSize:'13px',borderBottom:'0px solid'}}>
                                HORA: 17:00:20 
                            </label>
                            <table style={{borderCollapse:'collapse',height:'auto',width:'auto',color:'black',marginTop:'5px',zIndex:'100',fontSize:'13px',}}>
                                <thead style={{borderTop:'0px solid black',borderBottom:'1px solid black'}} >
                                    <tr >
                                        <th >Producto</th>
                                        <th >Precio</th>
                                        <th >Cantidad</th>
                                        <th >Total</th>
                                    </tr>
                                </thead>
                                <tbody style={{verticalAlign:'top',whiteSpace:'pre-line'}}>
                                    {productos.map((detalleVenta,i) => (
                                        <tr key={i+1}  style={{textAlign:"center",alignContent:"center",alignItems:"center" }}>
                                            <td>Producto{i+1} con nombre largo para la prueba</td>
                                            <td>S/50.00</td>
                                            <td>1</td>
                                            <td>S/50.00</td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr style={{textAlign:"center",alignContent:"center",alignItems:"center",fontSize:'15px',borderBottom:'1px solid black'}}>
                                        <td colspan={2} style={{borderTop:'1px solid #000000'}}>TOTAL </td>
                                        <td  style={{borderTop:'1px solid #000000',fontWeight:'bold',textAlign:'center',padding:'5px'}}>10</td>
                                        <td  style={{borderTop:'1px solid #000000',fontWeight:'bold',textAlign:'center',padding:'5px'}}>S/500.00</td>
                                    </tr>
                                </tfoot>
                            </table>
                            <label style={{display:'block',color:'black',zIndex:'100',marginTop:'10px',fontSize:'13px',textAlign:'center'}}>
                                ¡GRACIAS POR SU PREFERENCIA! 
                            </label>
                        </div>                        
                    </div>


                </div>


            <div className='flex items-center flex-wrap justify-center lg:border rounded '>


            {/* <InputText classBox='' id='Nombre' label="Nombre" defaultValue={producto.Nombre} onChange={(e) => editarValorProducto("Nombre", e.target.value)}/>  */}
            




                <div className=" wrapper  ">
                    <form className='form mt-3  lg:mt-5 lg:mb-0 flex-auto lg:w-[300px] '>
                        {/* <SelectText
                            options={opcionesDocumento}
                            label="Documento"
                            id="comboDocumento"
                            defaultValue={venta.Documento ? opcionesDocumento.find((option) => option.label === venta.NombreDocumento) : null}
                            onChange={(valor) => editarValorVenta("Categoria", valor.label)}
                        />

                        <SelectText
                            options={opcionesCliente}
                            label="Cliente"
                            id="comboCliente"
                            defaultValue={venta.Cliente ? opcionesCliente.find((option) => option.label === venta.Cliente) : null}
                            onChange={(valor) => editarValorVenta("Cliente", valor.id)}
                        /> */}
                                                    


                    {/* <InputText classBox='' id='PrecioVenta' label="Precio de Venta" type="number" defaultValue={producto.PrecioVenta} onChange={(e) => editarValorProducto("PrecioVenta", e.target.value)}/>  */}


                    
                        <div className="lg:w-[300px]  h-auto  w-[350px] group  justify-between lg:mt-2" > {/*SELECT DE DOCUMENTO*/}
                            <select className=' inputRegistro text-center center lg:w-[300px]  w-[350px] text-white bg-[#2f4d6d] lg:text-[15px] '
                                    type="text" 
                                    defaultValue={venta.CodDocumento} 
                                    // onChange={(e) => {editarValorVenta("CodDocumento", e.target.value);editarValorVenta("Documento",e.target.options[e.target.selectedIndex].text);guardarEditarVenta()}}>
                                    onChange={(e) => {editarValorVenta("CodDocumento", e.target.value);editarValorVenta("Documento",e.target.options[e.target.selectedIndex].text);}}>
                                <option value={1}>Proforma</option>
                                <option value={2}>Boleta</option>
                                <option value={3}>Factura</option>
                                <option value={4}>Nota Venta</option>
                            </select>
                            <label className='lg:w-[300px] text-white labelRegistro lg:text-[8px] text-[20px]' style={{color:'#d1f1f1'}}>Seleccione Documento</label>
                        </div>

                        <div className=" w-[350px] lg:text-center  lg:w-[300px] group mt-[20px]" >{/*INPUT DE CLIENTE*/}
                            <div className='items-center '>
                                <input className='w-[350px] lg:w-[300px] inputRegistro text-left text-white ' 
                                    type="text" 
                                    defaultValue={venta.Cliente} 
                                    onClick={()=>openModalListadoClientes()}
                                />

                                <span className=" lg:w-[300px] highlight w-[200px]"></span><span className="bar w-[300px]"></span>
                                <label className='w-[350px]  lg:w-[300px] labelRegistro text-white' style={{color:'#d1f1f1'}}>Cliente</label>
                                {/* <button onClick={()=>{openModalListadoClientes();guardarEditarVenta()}} type='button' data-tip data-for={'agregarCliente'} className="boton-iconBusqueda  lg:text-2xl infoBusqueda rounded hover:bg-[#f66820] bg-[#f6682f] text-white hover:text-gray-300 tooltipstered  "> */}
                                <button onClick={()=>{openModalListadoClientes();}} type='button' data-tip data-for={'agregarCliente'} className="boton-iconBusqueda  lg:text-2xl infoBusqueda rounded hover:bg-[#f66820] bg-[#f6682f] text-white hover:text-gray-300 tooltipstered  ">
                                    <i className="fa fa-user" aria-hidden="true"></i>
                                </button>          
                            </div>                    
                        </div>

                        <div className="  lg:w-full group mt-[20px]  " > {/*BOTONES DE PRODUCTO Y SERVICIO*/}
                            <label className=' text-white labelRegistro ' style={{color:'#d1f1f1'}} >Agregar</label>
                                <button onClick={()=>{openModalListadoProductos();guardarEditarVenta()}} type='button' data-tip data-for={'agregarProducto'} className="py-1 px-2  ml-[80px] lg:text-2xl text-xl rounded hover:bg-[#f66820] bg-[#f6682f] text-white hover:text-gray-300 tooltipstered  ">
                                    <i className="fas fa-box" aria-hidden="true"></i>
                                </button>  
                    
                                {/* <button onClick={()=>{openModalListadoProductos();guardarEditarVenta()}} type='button' data-tip data-for={'agregarServicio'} className="py-1 px-2 ml-2 lg:text-2xl text-xl rounded hover:bg-[#f66820] bg-[#f6682f] text-white hover:text-gray-300 tooltipstered  "> */}
                                <button onClick={()=>{openModalListadoProductos();}} type='button' data-tip data-for={'agregarServicio'} className="py-1 px-2 ml-2 lg:text-2xl text-xl rounded hover:bg-[#f66820] bg-[#f6682f] text-white hover:text-gray-300 tooltipstered  ">
                                    <i className="fas fa-briefcase" aria-hidden="true"></i>
                                </button>  
                        </div>
                    </form>
                </div>
            </div>
        
            {/* containerScroll */}
            <div className=" text-right mt-2 " >
                <label className='  lg:ml-1 text-white lg:text-xs text-xl ' >Total S/</label>
                <input className='  lg:h-[20px] w-[200px] lg:w-[100px] text-white text-[35px] lg:text-[15px]  bg-transparent border-none hover:border-none text-right' 
                    defaultValue={venta.MontoTotal} 
                    onChange={(e) => editarValorVenta("MontoTotal", e.target.value)} />
                <hr className=' mb-2'/>
            </div>

            {/* <button onClick={shareOnWhatsApp}>Compartir en WhatsApp</button> */}
            {/* <PDFExporter products={tableDataV} fileName="tabla"></PDFExporter> */}

            <div className=''>
                <label className='lg:text-[12px]'> *DETALLE DE LA VENTA</label>
            </div>
            <div className="lg:h-[35%] h-[330px] containerScroll  contenedor-tabla  ">
                <table className="tableResponsive mt-[4px] lg:mt-0" >
                    <thead className=''>
                        <tr >
                            <th className='w-[20px]'>N°</th>
                            <th >Nombre</th>
                            <th>Precio</th>
                            <th>Cantidad</th>
                            <th>Precio Total</th>
                            <th></th>
                        </tr>

                    </thead>

                    <tbody>
                        {
                            ventaDetalles.length >0?
                            ventaDetalles?.map((detalleVenta,i) => (

                            <tr key={detalleVenta.CodDetalleVenta}  >
                                <td className='border-y' data-th="tablenum">{++i}</td>
                                <td data-label="Producto" className='border-y' data-th="Nombre">{detalleVenta.NombreProducto}</td>
                                <td data-label="Precio" className='border-y' data-th="precio">S/{detalleVenta.PrecioUnitario}</td>
                                <td data-label="Cantidad" className='border-y center text-center' data-th="cantidad">
                                    <input type="Number" className='caret-black text-black border text-center w-[80px]' defaultValue={detalleVenta.Cantidad}/> 
                                </td>
                                <td data-label="Total" className='border-y' data-th="preciototal">S/{detalleVenta.PrecioTotal}</td>
                                <td data-th="Opciones">
                                    
                                    <button  onClick={()=> eliminarVentaDetalle(detalleVenta.CodDetalleVenta)} className="hover:text-red-300 text-red-500 text-xl px-[5px] py-1 rounded ml-3">
                                        <i className="fas fa-trash"></i>
                                    </button>
                                </td>
                            </tr>

                        ))
                        : <tr><td colSpan={'9'} className='text-center'>No hay productos agregados</td></tr>

                        }

                    </tbody>
                </table>
            </div>
            <div className='text-center item-center  '>
                <button className='mt-3 border bg-green-700 hover:bg-green-500 p-3 center text-xl rounded w-[200px]' onClick={()=>guardarEditarVenta()}>GUARDAR VENTA</button>
            </div> 

                    
            <Tooltip id={'agregarCliente'}>Seleccionar Cliente</Tooltip>
            <Tooltip id={'agregarProducto'}>Agregar Producto </Tooltip>
            <Tooltip id={'agregarServicio'}>Agregar Servicio</Tooltip>
            
            {/* MODAL DE LISTADO DE CLIENTES */}
            <Side 
                isOpen={isOpenModalListadoClientes} closeModal={closeModalListadoClientes} 
                action={()=>closeModalListadoClientes()}
                title="Seleccione un cliente"
                textButtons = {{denied: 'Cerrar'}}
                validButton = {{denied: true}}      
            >
                <div className='lg:w-[300px] w-[600px]' >
                    <div className='mb-2'>
                        <SearchTable tablaId={idTablaClientes}></SearchTable>
                    </div>
                    <div className="containerScroll lg:w-auto  relative contenedor-tabla max-h-[500px] lg:max-h-auto">
                        <table id={idTablaClientes} className="tableResponsiveClientesRegistroVenta " >
                            <thead className=''>
                                <tr >
                                    <th className='text-center  w-[50px] lg:invisible' ></th>
                                    <th className='w-[80px]'>Nombre</th>
                                    <th className='hidden'>Nombre</th>
                                    <th className='w-[100px]'>Celular</th>
                                </tr>
                            </thead>
                            <tbody>
                                {clientes.map((cliente) => (
                                    <tr className="cursor-pointer" key={cliente.CodCliente} onClick={()=>{editarValorVenta("CodCliente",cliente.CodCliente);editarValorVenta("Cliente", cliente.Nombres.split(" ")[0] + " " + cliente.ApellidoPaterno); closeModalListadoClientes()}} >
                                        <td data-th="Foto" className='w-[35px] tableFotoCliente'>
                                            <img src={cliente.Foto} alt={cliente.Foto} className="lg:ml-0 rounded-full w-[60px] lg:mt-5 h-[60px] lg:w-[80px] lg:h-[80px]"/>
                                        </td>
                                        <td data-th="Nombre" className='w-[70px]'>{cliente.ApellidoPaterno} {cliente.ApellidoMaterno} <span className='lg:hidden'>{cliente.Nombres}</span></td>
                                        <td data-th="Nombre" className='hidden'>{cliente.Nombres}</td>
                                        <td data-th="Celular" className=""><i className='lg:visible invisible fa fa-mobile p-1'></i>{cliente.Celular}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Side>


        {/* MODAL DE LISTADO DE PRODUCTOS */}
            <Side 
                isOpen={isOpenModalListadoProductos} closeModal={closeModalListadoProductos} 
                action={closeModalListadoProductos}
                title="Seleccione sus Productos"
                // textButtons = {{confirm: 'Seleccionar', denied:"Cerrar"}}
                // validButton = {{confirm: true, denied:true}}       
                textButtons = {{denied: 'Cerrar'}}
                validButton = {{denied: true}}      
            >
                <div className='lg:w-[320px] ' >
                <div className='mb-2'>
                    <SearchTable tablaId={idTablaProductos}></SearchTable>
                </div>
                    <div className="containerScroll  relative contenedor-tabla max-h-[800px] lg:max-h-auto">
                        <table id={idTablaProductos} className="tableResponsiveRegistroVenta " >
                            <thead className=''>
                                <tr >
                                    {/* <th></th> */}
                                    <th colSpan={2}>Producto</th>
                                    <th>Descripcion</th>
                                    <th>Stock</th>
                                    <th>Precio</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {productos.map((producto) => (
                                    <tr key={producto.CodProducto}
                                        onClick={()=>{
                                                        editarValorDetalleVenta("CodProducto",producto.CodProducto);
                                                        editarValorDetalleVenta("NombreProducto",producto.Nombre);
                                                        editarValorDetalleVenta("PrecioUnitario",producto.PrecioVenta); 
                                                        editarValorDetalleVenta("CodVenta",venta.CodVenta);
                                                        editarValorDetalleVenta("PrecioTotal",producto.PrecioVenta * 1);
                                                        // await guardarEditarDetalleVenta();
                                                        listarDetalleVentaPorCodVenta(venta.CodVenta)
                                                        closeModalListadoProductos() 

                                                        }}
                                        // onClick={async () => {
                                        //             const nuevoPrecioTotal = producto.PrecioVenta * 1;
                                        //             const newVentaDetalle = {
                                        //             CodProducto: producto.CodProducto,
                                        //             NombreProducto: producto.Nombre,
                                        //             PrecioUnitario: producto.PrecioVenta,
                                        //             CodVenta: venta.CodVenta,
                                        //             PrecioTotal: nuevoPrecioTotal,
                                        //             };

                                        //             // Actualiza todas las propiedades juntas
                                        //             await editarValorDetalleVenta2(newVentaDetalle);
                                        //             guardarEditarDetalleVenta();
                                        //             listarDetalleVentaPorCodVenta(venta.CodVenta);
                                        //             closeModalListadoProductos();
                                        //         }}
                                        style={{textAlign:"center",alignContent:"center",alignItems:"center"}}>
                                        <td  data-th="Foto" className='tableFoto center'>
                                            <img src={producto.Foto} alt={producto.Descripcion} className="rounded-full w-[60px] h-[60px] lg:w-[120px] lg:h-[120px]"/>
                                        </td>
                                        <td data-th="Nombre" className='w-[200px]'>{producto.Nombre}</td>
                                        <td data-th="Descripcion" className='w-[300px]'>{producto.Descripcion}</td>
                                        <td data-th="Stock">{producto.Stock}</td>
                                        <td data-th="PrecioVenta">S/ {parseFloat(producto.PrecioVenta).toFixed(2)}</td>
                                        {/* <td data-th="Opciones">
                                            <div className='justify  text-center  align-middle '>
                                                <input type='number' defaultValue={1} onChange={{}} className='  caret-black  border w-[60px] h-[30px] text-gray-500  text-center  rounded'/>
                                                <button onClick={()=> guardarEditarDetalleVenta(producto)} className=" ml-2  hover:text-green-300 text-green-500 text-2xl p-1 rounded">
                                                    <i className="fas fa-check"></i>
                                                </button>
                                            </div>
                                        </td> */}
                                    </tr>

                                    
                                
                                ))}

                            </tbody>
                        </table>
                    </div>
                </div>
            </Side>

            <ModalPopUp
                isOpen={isOpenModalPopUp} closeModal={closeModalPopUp} 
                action={console.log("Click modal")}
                title="Seleccione una opción"
                textButtons = {{denied: 'Cerrar'}}
                validButton = {{denied: true}}      
            >     
                {/* IMPRESION TIPO VOUCHER  */}
                <div ref={printRef} className="m-5 hidden">
                    <div className="border " style={{display: 'flex', flexDirection: 'column', justifyContent: '',fontFamily:' Helvetica, Arial, sans-serif;'}}>
                        <div style={{width:'100%',textAlign:'center',marginBottom:'1px',marginTop:'5px'}}>
                            <label style={{textDecoration: 'bold',color:'black',fontSize:'15px',fontWeight:'bold'}}>
                                NOTA DE VENTA 
                            </label>              
                            <label style={{display:'block',color:'black',zIndex:'100',marginTop:'0px',fontSize:'15px',borderBottom:'1px solid'}}>
                                NV01 - 000523
                            </label>
                        </div>
                        <div  style={{textAlign:'center',marginTop:'0',fontWeight:'bold'}}>
                            <label style={{display:'block',color:'black',zIndex:'100',marginTop:'0px',fontSize:'15px',borderBottom:'2px solid'}}>
                            DATOS CLIENTE
                            </label>
                        </div>
                        <label style={{display:'block',color:'black',zIndex:'100',marginTop:'0px',fontSize:'13px'}}>
                            CLIENTE: Henry Cerna Quispe 
                        </label>
                        <label style={{display:'block',color:'black',zIndex:'100',marginTop:'0px',fontSize:'13px',borderBottom:'1px solid'}}>
                            Telefono: 999999999 
                        </label>
                        <div  style={{textAlign:'center',marginTop:'0',fontWeight:'bold'}}>
                            <label style={{display:'block',color:'black',zIndex:'100',marginTop:'0px',fontSize:'15px',borderBottom:'2px solid'}}>
                            DETALLE VENTA
                            </label>
                        </div>
                        <label style={{display:'block',color:'black',zIndex:'100',marginTop:'0px',fontSize:'13px'}}>
                            FECHA: 16/05/2023 
                        </label>
                        <label style={{display:'block',color:'black',zIndex:'100',marginTop:'0px',fontSize:'13px',borderBottom:'0px solid'}}>
                            HORA: 17:00:20 
                        </label>
                        <table style={{borderCollapse:'collapse',height:'auto',width:'auto',color:'black',marginTop:'5px',zIndex:'100',fontSize:'13px',}}>
                            <thead style={{borderTop:'0px solid black',borderBottom:'1px solid black'}} >
                                <tr >
                                    <th >Producto</th>
                                    <th >Precio</th>
                                    <th >Cantidad</th>
                                    <th >Total</th>
                                </tr>
                            </thead>
                            <tbody style={{verticalAlign:'top',whiteSpace:'pre-line'}}>
                                {productos.map((detalleVenta,i) => (
                                    <tr key={i+1}  style={{textAlign:"center",alignContent:"center",alignItems:"center" }}>
                                        <td>Producto{i+1} con nombre largo para la prueba</td>
                                        <td>S/50.00</td>
                                        <td>1</td>
                                        <td>S/50.00</td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot >
                                <tr style={{textAlign:"center",alignContent:"center",alignItems:"center",fontSize:'15px',borderBottom:'1px solid black'}}>
                                    <td colspan="2" style={{borderTop:'1px solid #000000'}}>TOTAL </td>
                                    <td  style={{borderTop:'1px solid #000000',fontWeight:'bold',textAlign:'center',padding:'5px'}}>10</td>
                                    <td  style={{borderTop:'1px solid #000000',fontWeight:'bold',textAlign:'center',padding:'5px'}}>S/500.00</td>
                                </tr>
                            </tfoot>
                        </table>
                        <label style={{display:'block',color:'black',zIndex:'100',marginTop:'10px',fontSize:'13px',textAlign:'center'}}>
                            ¡GRACIAS POR SU PREFERENCIA! 
                        </label>
                    </div>                          
                </div>
                {/* IMPRESION TIPO HOJA A4 */}
                <div ref={printRef2} className="m-5 hidden">
                    <div className="border " style={{display: 'flex',justifyContent:'space-between', flexDirection: 'column', fontFamily:' Helvetica, Arial, sans-serif'}}>

                        
                        <div style={{display:'flex',color:'black',justifyContent:'space-between'}}>

                            <div style={{float: 'left',display:'flex'}}>
                                <table>
                                    <tr>
                                        <td  style={{marginTop:'10px',float:'left', display: 'inline'}}>
                                            <img src={logo} alt={logo} style={{height:'90px',position:'relative'}}/>
                                        </td>
                                    </tr>
                                    {/* <tr>
                                        <td >
                                            Los Geranios 180 Comas<br /> Telefonos: 999999999 | correo: correo@correo.com
                                        </td>
                                    </tr> */}
                                </table>
                            </div>
                                    
                            <div style={{display:'flex',textAlign:'center',float:'right',padding:'5px',color:'black',border:'2px solid gray'}}>
                                <table style={{padding:'5px',textAlign:'center',textDecoration:'bold', fontWeight:'bold'}} >
                                    <tr >
                                        <td >R.U.C Nº 20605928511</td>
                                    </tr>
                                    <tr>
                                        <td>NOTA VENTA</td>
                                    </tr>
                                    <tr>
                                        <td >NV01 - 000525</td>
                                    </tr>
                                </table>
                            </div>

                        </div>


                        <div style={{display:'flex',color:'black',justifyContent:'space-between'}}>

                            <div style={{float: 'left',display:'flex'}}>
                                <table>

                                    <tr>
                                        <td >
                                            Los Geranios 180 Comas<br /> Telefonos: 999999999 | correo: correo@correo.com
                                        </td>
                                    </tr>
                                </table>
                            </div>
                                    
                            <div style={{display:'flex',textAlign:'center',float:'right',padding:'5px',color:'black'}}>
                                {/* <table style={{padding:'5px',textAlign:'center',textDecoration:'bold', fontWeight:'bold'}} >
                                    <tr >
                                        <td >R.U.C Nº 20605928511</td>
                                    </tr>
                                    <tr>
                                        <td>NOTA VENTA</td>
                                    </tr>
                                    <tr>
                                        <td >NV01 - 000525</td>
                                    </tr>
                                </table> */}
                            </div>

                        </div>

                        
                        <div style={{float:' left', marginBottom: '0.2cm', marginTop: '0.3cm',display:'flex',color:'black'}}>
                            <div style={{float:' left'}}>
                                <table >
                                    <tr>
                                        <td style={{width:'2cm', textAlign: 'left'}}>Nombre</td>
                                        <td style={{width: '0.5cm'}}>:</td>
                                        <td>Henry Cerna Quispe</td>
                                    </tr>
                                    <tr>
                                        <td style={{width:'2cm', textAlign: 'left'}}>DNI</td>
                                        <td style={{width: '0.5cm'}}>:</td>
                                        <td>89765412</td>
                                    </tr>
                                    <tr>
                                        <td style={{width:'2cm', textAlign: 'left'}}>Direccion</td>
                                        <td style={{width: '0.5cm'}}>:</td>
                                        <td>Los Geranios Nº 180 Urb. La Alborada - Comas </td>
                                    </tr>
                                </table>
                            </div>

                            <div style={{float:' left'}}>
                                <table >
                                    <tr>
                                        <td style={{width:'2cm', textAlign: 'left'}}>Fecha</td>
                                        <td style={{width: '0.5cm'}}>:</td>
                                        <td>16/05/2023</td>
                                    </tr>
                                    <tr>
                                        <td style={{width:'2cm', textAlign: 'left'}}>Vendedor</td>
                                        <td style={{width: '0.5cm'}}>:</td>
                                        <td>Henry Cerna</td>
                                    </tr>
                                    {/* <tr>
                                        <td style={{width:'2cm', textAlign: 'left'}}>DNI</td>
                                        <td style={{width: '0.5cm'}}>:</td>
                                        <td>89765412</td>
                                    </tr> */}
                                </table>
                            </div>


                        </div>


                        <table style={{borderCollapse:'collapse',height:'auto',width:'auto',color:'black',marginTop:'5px',zIndex:'100',fontSize:'13px',borderRight:'1px solid black'}}>
                            <thead style={{borderTop:'0px solid black',borderBottom:'1px solid black'}} >
                                <tr >
                                    <th style={{padding: '3px 5px',textAlign:'center', width:'1cm' ,border: '1px solid black'}}>CODIGO</th>
                                    <th style={{padding:' 3px 5px', border: '1px solid black'}}>DESCRIPCIÓN</th>
                                    <th style={{padding:' 3px 5px',textAlign:'center', width:'1cm',border:'1px solid black'}}>CANTIDAD</th>
                                    <th style={{padding:' 3px 5px',textAlign:' right', width:'2cm',border:'1px solid black'}}>P.UNITARIO</th>
                                    <th style={{padding:' 3px 5px',textAlign:' right', width:'2cm',border:'1px solid black'}}>DESCUENTO</th>
                                    <th style={{padding:' 3px 5px',textAlign:' right', width:'2cm',border:'1px solid black'}}>IMPORTE</th>
                                </tr>

                            </thead>
                            <tbody style={{verticalAlign:'top',borderBottom: '1px solid black',whiteSpace:'pre-line'}}>

                                {productos.map((detalleVenta,i) => (

                                    <tr>
                                        <td style={{borderLeft:'1px solid',padding:' 3px 5px',textAlign:' center', height:' 15px'}} >CPV0125{i+1}</td>
                                        <td style={{borderLeft:'1px solid',padding:' 3px 5px',textAlign:'left'}} >Este es un producto{i+1} de prueba</td>
                                        <td style={{borderLeft:'1px solid',padding:' 3px 5px',textAlign:' right'}} >{i*2 + 1}</td>
                                        <td style={{borderLeft:'1px solid',padding:' 3px 5px',textAlign:' right'}} >S/30.00</td>
                                        <td style={{borderLeft:'1px solid',padding:' 3px 5px',textAlign:' right'}} ></td>
                                        <td style={{borderLeft:'1px solid',padding:' 3px 5px',textAlign:' right'}} >S/80.00</td>
                                    </tr>

                                ))}

                            </tbody>

                            <tfoot >
                                    <tr>
                                        <td colspan="4" style={{borderBottom: '0px' , borderLeft:'0px solid', borderTop:'1px solid #000000', paddingLeft:'15px'}}></td>
                                        <td style={{borderTop:'1px solid #000000', borderLeft:'1px solid', textAlign: 'left', marginLeft: '15px'}}>SUB.TOTAL</td>
                                        <td style={{padding:'3px 5px', borderBottom:'1px solid #000000', borderTop:'1px solid #000000', textAlign:'right', marginLeft:'15px'}}>dsa</td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" style={{borderBottom:'0px', borderLeft:'0px', paddingLeft:'15px'}}></td>
                                        <td style={{borderTop: '1px solid #000000',borderBottom: '1px solid',borderLeft:'1px solid', textAlign: 'left', marginLeft: '15px'}}>DESCUENTO</td>
                                        <td style={{padding: '3px 5px', borderBottom:'1px solid #000000', borderTop:'1px solid #000000', textAlign: 'right', marginLeft: '15px'}}>S/00.00</td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" style={{borderBottom:' 0px solid #000000', borderLeft:'0px'}}></td>
                                        <td style={{borderBottom: '1px solid', textAlign: 'left',borderLeft:'1px solid', marginLeft: '15px'}}>IGV</td>
                                        <td style={{padding: '3px 5px', borderBottom:' 1px solid #000000', textAlign:' right', marginLeft:' 15px'}}>S/22.00</td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" style={{borderBottom: '0px solid #000000', borderLeft:' 0px'}}></td>
                                        <td style={{textAlign: 'left',borderBottom: '1px solid', borderLeft:'1px solid',marginLeft: '15px'}}>TOTAL</td>
                                        <td style={{padding: '3px 5px', borderBottom:' 1px solid #000000', textAlign:' right', marginLeft:' 15px'}}>S/522.00</td>
                                    </tr>
                                </tfoot>
                        </table>

                        {/* <label style={{display:'block',color:'black',marginTop:'50',zIndex:'100',fontSize:'15px',}}>
                           ******************************
                        </label>
                        
                        <label style={{textAlign:"center",alignContent:"center",alignItems:"center",display:'block',color:'black',marginTop:'50',zIndex:'100',fontSize:'18px',}}>
                            TOTAL : S/500.00
                        </label>
                        <label style={{display:'block',color:'black',marginTop:'50',zIndex:'100',fontSize:'15px',}}>
                           ******************************
                        </label> */}
                    <label style={{display:'block',color:'black',zIndex:'100',marginTop:'10px',fontSize:'13px',textAlign:'center'}}>
                        Medios de Pago:
                    </label>

                    </div>                          
                </div>

                
                <div > 
                    <div className='flex flex-col text-center '>
                        <div className='text-center center'>

                            <label className='p-1 ml-[25%] text-center center flex text-gray-600 '><i className='center text-center fa fa-print mr-2'></i>  IMPRESION </label>
                        </div>
                        <div>
                            <button
                                className="fa fa-file-text text-[80px] text-white-500 py-5 hover:bg-blue-600 bg-blue-500 rounded font-bold capitalize px-6 py-2 outline-none focus:outline-none mr-5 ease-linear transition-all duration-150 "
                                type="button"
                                onClick={()=>printDiv()}
                                >

                            </button>
                            <button
                                className="fas fa-file text-[80px] text-yellow-500 py-5 hover:bg-blue-600 bg-blue-500 rounded font-bold capitalize px-6 py-2 outline-none focus:outline-none mr-5 ease-linear transition-all duration-150 "
                                type="button"
                                onClick={()=>printDiv2()}
                                >

                            </button>
                        </div>
                    </div>

                    <div className='flex flex-col text-center '>
                        <div className='text-center center'>
                        <label className='p-1 mt-2 ml-[25%] text-center center flex text-gray-600 '><i className='center text-center fa fa-share-alt mr-2'></i>  Compartir </label>

                        <button className="rounded text-[80px] px-6 hover:bg-green-600 bg-green-500 mr-5  transition-all duration-150" type="button">
                        <a className="fa fa-whatsapp  text-white-500 hover:bg-green-600 bg-green-500  " target="_blank"
                            href={`https://api.whatsapp.com/send/?text=${linkMensaje}`}
                        >
                        </a>
                    </button>

                    <button
                        className="fas fa-list text-[80px] text-white-500 py-5 hover:bg-blue-600 bg-blue-500 rounded font-bold capitalize px-6 py-2 outline-none focus:outline-none mr-5 ease-linear transition-all duration-150 "
                        type="button"
                        onClick={{}}
                        >

                    </button>
                    </div>
                    </div>
                </div>

            </ModalPopUp>

        </>
    )

}