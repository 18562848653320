import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function InputText(props) {
    return (
    <Box
        component="form"
        className={` ${props.classBox}  mt-9 caret-black  w-full`}
        noValidate
        autoComplete="off"
    >
        <TextField
            fullWidth
            className={` ${props.classField}   w-full focus:ring-orange-500 focus:border-orange-500`}
            label={props.label}
            id={props.id}
            type={props.type || 'text'}
            required={props.required}
            defaultValue={props.defaultValue}
            onChange={props.onChange}
            multiline
            rows={props.multilinea}
            onKeyPress= {props.onKeyPress}
            sx={{
            '& .MuiInputLabel-root': {
                color: props.color || 'gray', // Cambia el color del label a anaranjado
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                borderColor: props.color || '',
                },
                '&:hover fieldset': {
                borderColor: props.color || '#f6682f', // Cambia el color del borde al pasar el mouse
                },
                '&.Mui-focused fieldset': {
                borderColor: props.color || '#f6682f', // Cambia el color del borde en focus
                },
                '& input': {
                color: 'black',
                backgroundColor: 'white',
                borderRadius: '8px',
                padding: '10px',
                caretColor: '#ff0', // Cambia el color del caret a negro
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                outline: 'none',
                },
            },
            }}
            InputProps={{
                style: {
                    // color: 'orange',
                    caretColor: 'black', // Cambia el color del caret a negro
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    padding: '10px',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    outline: 'none',
                },
            }}
        />
        </Box>
    );
};

