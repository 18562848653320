import React from 'react';
import '../../assets/css/views/login.css'
import '../../assets/css/views/estiloSlider.css'

import {
    useHistory
} from "react-router-dom";


export const Web = () => {
    const history = useHistory();

    const irALogin = async () => {
        history.push('/Login')
    }

    return (
        <div className="flex flex-col justify-between h-screen">
            <div className="h-[56px] flex items-center justify-start lg:justify-center " style={{background:'#002A78'}}>
                <div className="flex-shrink-0 flex justify-center items-center" style={{ width: '250px'}}>
                </div>
            </div>
            <div className="grow dark:bg-slate-900">
                
                <div className="container flex justify-center items-center">
                    <div className='dark:text-white items-center w-[120px] m-[30px] text-center cursor-pointer' onClick={() => irALogin()}>
                    </div>

                    <div className='dark:text-white items-center w-[120px] m-[30px] text-center cursor-pointer'>
                    </div>

                    <div className='dark:text-white items-center w-[120px] m-[30px] text-center cursor-pointer'>
                    </div>
                </div>
            </div>
            <div className="dark:text-white footer" style={{background:'#002A78',height: '60px'}}>
                <footer className='flex items-center justify-between h-full'>
                    <div style={{textAlign:'left',marginLeft: '50px'}}>
                        <label> Copyright Abexa Cloud Company 2018</label>
                    </div>
                    <div style={{textAlign:'right',marginRight: '50px'}}>
                        <label> Politica de privacidad Terminos de uso</label>
                    </div>
                </footer>
            </div>
        </div>
    )
}
