import React, { useState, useContext } from 'react';
import { Box, Button, TextField, FormControl, InputLabel, Select, MenuItem, Switch, Typography, Autocomplete, Stepper, Step, StepLabel, Modal, Grid, Divider } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { UserContext } from '../../../context/provider/UserProvider';
import { useCajas } from '../../../hooks/useCajas';

// Datos para los selects
const tipoDocumentos = ['Factura', 'Boleta', 'Guía Remisión', 'Declaración Jurada', 'Ticket'];
const clientes = [
    { label: 'Compañía Minera Luren', ruc: '20100179807' },
    { label: 'Recolectora de Residuos', ruc: '65456432102' },
];
const tiposDocumentoIdentidad = ['DNI', 'Carné de extranjería', 'Pasaporte'];
const vehiculos = [{ label: 'Camión ABC123', placa: 'ABC-123' }, { label: 'Camioneta XYZ456', placa: 'XYZ-456' }];
const conductores = [
    { nombres: 'Juan', apellidos: 'Perez', licencia: '12345678' },
    { nombres: 'María', apellidos: 'Lopez', licencia: '87654321' },
];
const ubicacionesPredefinidas = ['Almacén Principal', 'Sede Central', 'Oficina Lima'];
const departamentos = ['Lima', 'Arequipa', 'Cusco'];
const provincias = {
    Lima: ['Lima', 'Callao', 'Huaral'],
    Arequipa: ['Arequipa', 'Camaná', 'Caravelí'],
    Cusco: ['Cusco', 'Urubamba', 'Calca'],
};
const distritos = {
    Lima: ['Miraflores', 'San Isidro', 'Barranco'],
    Callao: ['Bellavista', 'La Punta', 'Ventanilla'],
    Huaral: ['Huaral', 'Aucallama', 'Chancay'],
};

// Componente de Vista de Impresión
const VistaImpresion = ({ documentData, clienteData, destinatarioData, puntoPartidaData, puntoLlegadaData, transporteData, fechaTraslado }) => {
    return (
        <Box sx={{ width: '100%', padding: 4, backgroundColor: 'white', color: 'black' }}>
            <Typography variant="h4" align="center" gutterBottom>
                Resumen de Documento de Transporte
            </Typography>
            <Divider sx={{ mb: 3 }} />

            {/* Documentos Relacionados */}
            <Typography variant="h6">Documentos Relacionados</Typography>
            <Typography>Tipo de Documento: {documentData.tipoDocumento}</Typography>
            <Typography>Serie: {documentData.serie}</Typography>
            <Typography>Número: {documentData.numero}</Typography>
            <Typography>RUC Emisor: {documentData.rucEmisor}</Typography>
            <Divider sx={{ mt: 2, mb: 3 }} />

            {/* Remitente */}
            <Typography variant="h6">Remitente</Typography>
            <Typography>Cliente: {clienteData.cliente?.label}</Typography>
            <Typography>Tipo de Documento: {clienteData.tipoDocumento}</Typography>
            <Typography>Número de Documento: {clienteData.numeroDocumento}</Typography>
            <Typography>Nombres: {clienteData.nombres}</Typography>
            <Typography>Apellido Paterno: {clienteData.apellidoPaterno}</Typography>
            <Typography>Apellido Materno: {clienteData.apellidoMaterno}</Typography>
            <Divider sx={{ mt: 2, mb: 3 }} />

            {/* Destinatario */}
            <Typography variant="h6">Destinatario</Typography>
            <Typography>Cliente: {destinatarioData.cliente?.label}</Typography>
            <Typography>Tipo de Documento: {destinatarioData.tipoDocumento}</Typography>
            <Typography>Número de Documento: {destinatarioData.numeroDocumento}</Typography>
            <Typography>Nombres: {destinatarioData.nombres}</Typography>
            <Typography>Apellido Paterno: {destinatarioData.apellidoPaterno}</Typography>
            <Typography>Apellido Materno: {destinatarioData.apellidoMaterno}</Typography>
            <Divider sx={{ mt: 2, mb: 3 }} />

            {/* Punto de Partida */}
            <Typography variant="h6">Punto de Partida</Typography>
            <Typography>Ubicación Predefinida: {puntoPartidaData.ubicacionPredefinida}</Typography>
            <Typography>Departamento: {puntoPartidaData.departamento}</Typography>
            <Typography>Provincia: {puntoPartidaData.provincia}</Typography>
            <Typography>Distrito: {puntoPartidaData.distrito}</Typography>
            <Typography>Dirección: {puntoPartidaData.direccion}</Typography>
            <Divider sx={{ mt: 2, mb: 3 }} />

            {/* Punto de Llegada */}
            <Typography variant="h6">Punto de Llegada</Typography>
            <Typography>Ubicación Predefinida: {puntoLlegadaData.ubicacionPredefinida}</Typography>
            <Typography>Departamento: {puntoLlegadaData.departamento}</Typography>
            <Typography>Provincia: {puntoLlegadaData.provincia}</Typography>
            <Typography>Distrito: {puntoLlegadaData.distrito}</Typography>
            <Typography>Dirección: {puntoLlegadaData.direccion}</Typography>
            <Divider sx={{ mt: 2, mb: 3 }} />

            {/* Transporte */}
            <Typography variant="h6">Transporte</Typography>
            <Typography>Vehículo: {transporteData.vehiculo?.label}</Typography>
            <Typography>Conductores:</Typography>
            {transporteData.conductores.map((conductor, index) => (
                <Typography key={index}>- {conductor.nombres} {conductor.apellidos} (Licencia: {conductor.licencia})</Typography>
            ))}
            <Typography>Pagador del Flete: {transporteData.pagadorFlete}</Typography>
            <Typography>Fecha de Traslado: {fechaTraslado.format('DD/MM/YYYY')}</Typography>
        </Box>
    );
};

// Componente Principal que contiene el flujo y la opción de imprimir
export const Caja = () => {
    const { stateUser } = useContext(UserContext);
    const { listarCajas } = useCajas();
    const [activeStep, setActiveStep] = useState(0);
    const [isCreating, setIsCreating] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [fechaTraslado, setFechaTraslado] = useState(dayjs());
    const [showPrintView, setShowPrintView] = useState(false);

    // Estado para cada paso
    const [documentData, setDocumentData] = useState({
        tipoDocumento: '',
        serie: '',
        numero: '',
        rucEmisor: '',
    });

    const [clienteData, setClienteData] = useState({
        cliente: null,
        tipoDocumento: '',
        numeroDocumento: '',
        nombres: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
    });

    const [destinatarioData, setDestinatarioData] = useState({
        cliente: null,
        tipoDocumento: '',
        numeroDocumento: '',
        nombres: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
    });

    const [puntoPartidaData, setPuntoPartidaData] = useState({
        ubicacionPredefinida: '',
        departamento: '',
        provincia: '',
        distrito: '',
        direccion: '',
    });

    const [puntoLlegadaData, setPuntoLlegadaData] = useState({ ...puntoPartidaData });
    const [transporteData, setTransporteData] = useState({
        vehiculo: '',
        conductores: [],
        pagadorFlete: '',
    });

    const handleNext = () => setActiveStep((prev) => prev + 1);
    const handleBack = () => setActiveStep((prev) => prev - 1);
    const handleSwitchChange = (event) => setIsCreating(!event.target.checked);
    const openBuscarDocumentosModal = () => setOpenModal(true);
    const closeBuscarDocumentosModal = () => setOpenModal(false);

    const handlePrint = () => {
        setShowPrintView(true);
        setTimeout(() => {
            window.print();
            setShowPrintView(false);
        }, 500);
    };

    if (showPrintView) {
        return (
            <VistaImpresion
                documentData={documentData}
                clienteData={clienteData}
                destinatarioData={destinatarioData}
                puntoPartidaData={puntoPartidaData}
                puntoLlegadaData={puntoLlegadaData}
                transporteData={transporteData}
                fechaTraslado={fechaTraslado}
            />
        );
    }

    return (
        <Box sx={{ p: 3, width: '100%',  margin: 'auto' }}>
        {/* Stepper con estilo degradado */}
        <div className='overflow-x-auto'>
          
            <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4 }}>
                {['Documentación', 'Remitente', 'Destinatario', 'Partida', 'Destino', 'Transporte'].map((label, index) => (
                  <Step key={label}>
                        <StepLabel sx={{ color: `rgba(80, 20, 150, ${1 - index * 0.15})` }}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>

            {/* Paso 1: Documentos Relacionados */}
            {activeStep === 0 && (
                <Box fullWidth>
                    <Typography color={'gray'} variant="h6" mb={3}>Documentos Relacionados</Typography>
                    <Grid container alignItems="center" spacing={2} mb={2}>
                        <Grid item>
                            <Switch checked={!isCreating} onChange={handleSwitchChange} inputProps={{ 'aria-label': 'controlled' }} />
                        </Grid>
                        <Grid item>
                            <Typography color={'gray'}>{isCreating ? 'Crear Documento' : 'Cargar Documento'}</Typography>
                        </Grid>
                        {!isCreating && (
                            <Grid item>
                                <Button variant="contained" onClick={openBuscarDocumentosModal}>Buscar Documentos</Button>
                            </Grid>
                        )}
                    </Grid>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>Tipo de Documento</InputLabel>
                        <Select
                        
                            value={documentData.tipoDocumento}
                            onChange={(e) => setDocumentData({ ...documentData, tipoDocumento: e.target.value })}
                        >
                            {tipoDocumentos.map((doc, index) => (
                                <MenuItem key={index} value={doc}>{doc}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField fullWidth label="Serie" variant="outlined" value={documentData.serie} onChange={(e) => setDocumentData({ ...documentData, serie: e.target.value })} sx={{ mb: 2 }} />
                    <TextField fullWidth label="Número" variant="outlined" value={documentData.numero} onChange={(e) => setDocumentData({ ...documentData, numero: e.target.value })} sx={{ mb: 2 }} />
                    <TextField fullWidth label="RUC Emisor" variant="outlined" value={documentData.rucEmisor} onChange={(e) => setDocumentData({ ...documentData, rucEmisor: e.target.value })} sx={{ mb: 2 }} />
                    <Grid container justifyContent="space-between" mt={4}>
                        <Button variant="text" disabled={activeStep === 0} onClick={handleBack}>Atrás</Button>
                        <Button variant="contained" onClick={handleNext}>Siguiente</Button>
                    </Grid>
                </Box>
            )}

            {/* Paso 2: Remitente */}
            {activeStep === 1 && (
                <Box>
                    <Typography variant="h6" mb={3}>Remitente</Typography>
                    <Autocomplete
                        options={clientes}
                        getOptionLabel={(option) => `${option.label} - RUC: ${option.ruc}`}
                        renderInput={(params) => <TextField {...params} label="Cliente" />}
                        onChange={(event, value) => setClienteData({ ...clienteData, cliente: value })}
                        sx={{ mb: 2 }}
                    />
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>Tipo de Documento</InputLabel>
                        <Select
                            value={clienteData.tipoDocumento}
                            onChange={(e) => setClienteData({ ...clienteData, tipoDocumento: e.target.value })}
                        >
                            {tiposDocumentoIdentidad.map((tipo, index) => (
                                <MenuItem key={index} value={tipo}>{tipo}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField fullWidth label="Número de Documento" variant="outlined" value={clienteData.numeroDocumento} onChange={(e) => setClienteData({ ...clienteData, numeroDocumento: e.target.value })} sx={{ mb: 2 }} />
                    <TextField fullWidth label="Nombres" variant="outlined" value={clienteData.nombres} onChange={(e) => setClienteData({ ...clienteData, nombres: e.target.value })} sx={{ mb: 2 }} />
                    <TextField fullWidth label="Apellido Paterno" variant="outlined" value={clienteData.apellidoPaterno} onChange={(e) => setClienteData({ ...clienteData, apellidoPaterno: e.target.value })} sx={{ mb: 2 }} />
                    <TextField fullWidth label="Apellido Materno" variant="outlined" value={clienteData.apellidoMaterno} onChange={(e) => setClienteData({ ...clienteData, apellidoMaterno: e.target.value })} sx={{ mb: 2 }} />
                    <Grid container justifyContent="space-between" mt={4}>
                        <Button variant="text" onClick={handleBack}>Atrás</Button>
                        <Button variant="contained" onClick={handleNext}>Siguiente</Button>
                    </Grid>
                </Box>
            )}

            {/* Paso 3: Destinatario */}
            {activeStep === 2 && (
                <Box>
                    <Typography variant="h6" mb={3}>Destinatario</Typography>
                    <Autocomplete
                        options={clientes}
                        getOptionLabel={(option) => `${option.label} - RUC: ${option.ruc}`}
                        renderInput={(params) => <TextField {...params} label="Cliente" />}
                        onChange={(event, value) => setDestinatarioData({ ...destinatarioData, cliente: value })}
                        sx={{ mb: 2 }}
                    />
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>Tipo de Documento</InputLabel>
                        <Select
                            value={destinatarioData.tipoDocumento}
                            onChange={(e) => setDestinatarioData({ ...destinatarioData, tipoDocumento: e.target.value })}
                        >
                            {tiposDocumentoIdentidad.map((tipo, index) => (
                                <MenuItem key={index} value={tipo}>{tipo}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField fullWidth label="Número de Documento" variant="outlined" value={destinatarioData.numeroDocumento} onChange={(e) => setDestinatarioData({ ...destinatarioData, numeroDocumento: e.target.value })} sx={{ mb: 2 }} />
                    <TextField fullWidth label="Nombres" variant="outlined" value={destinatarioData.nombres} onChange={(e) => setDestinatarioData({ ...destinatarioData, nombres: e.target.value })} sx={{ mb: 2 }} />
                    <TextField fullWidth label="Apellido Paterno" variant="outlined" value={destinatarioData.apellidoPaterno} onChange={(e) => setDestinatarioData({ ...destinatarioData, apellidoPaterno: e.target.value })} sx={{ mb: 2 }} />
                    <TextField fullWidth label="Apellido Materno" variant="outlined" value={destinatarioData.apellidoMaterno} onChange={(e) => setDestinatarioData({ ...destinatarioData, apellidoMaterno: e.target.value })} sx={{ mb: 2 }} />
                    <Grid container justifyContent="space-between" mt={4}>
                        <Button variant="text" onClick={handleBack}>Atrás</Button>
                        <Button variant="contained" onClick={handleNext}>Siguiente</Button>
                    </Grid>
                </Box>
            )}

            {/* Paso 4: Punto de Partida */}
            {activeStep === 3 && (
                <Box>
                    <Typography variant="h6" mb={3}>Punto de Partida</Typography>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>Ubicación Predefinida</InputLabel>
                        <Select
                            value={puntoPartidaData.ubicacionPredefinida}
                            onChange={(e) => setPuntoPartidaData({ ...puntoPartidaData, ubicacionPredefinida: e.target.value })}
                        >
                            {ubicacionesPredefinidas.map((ubicacion, index) => (
                                <MenuItem key={index} value={ubicacion}>{ubicacion}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>Departamento</InputLabel>
                        <Select
                            value={puntoPartidaData.departamento}
                            onChange={(e) => setPuntoPartidaData({ ...puntoPartidaData, departamento: e.target.value })}
                        >
                            {departamentos.map((departamento, index) => (
                                <MenuItem key={index} value={departamento}>{departamento}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>Provincia</InputLabel>
                        <Select
                            value={puntoPartidaData.provincia}
                            onChange={(e) => setPuntoPartidaData({ ...puntoPartidaData, provincia: e.target.value })}
                        >
                            {provincias[puntoPartidaData.departamento]?.map((provincia, index) => (
                                <MenuItem key={index} value={provincia}>{provincia}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>Distrito</InputLabel>
                        <Select
                            value={puntoPartidaData.distrito}
                            onChange={(e) => setPuntoPartidaData({ ...puntoPartidaData, distrito: e.target.value })}
                        >
                            {distritos[puntoPartidaData.provincia]?.map((distrito, index) => (
                                <MenuItem key={index} value={distrito}>{distrito}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        label="Dirección"
                        variant="outlined"
                        value={puntoPartidaData.direccion}
                        onChange={(e) => setPuntoPartidaData({ ...puntoPartidaData, direccion: e.target.value })}
                        sx={{ mb: 2 }}
                    />
                    <Grid container justifyContent="space-between" mt={4}>
                        <Button variant="text" onClick={handleBack}>Atrás</Button>
                        <Button variant="contained" onClick={handleNext}>Siguiente</Button>
                    </Grid>
                </Box>
            )}

            {/* Paso 5: Punto de Llegada */}
            {activeStep === 4 && (
                <Box>
                    <Typography variant="h6" mb={3}>Punto de Llegada</Typography>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>Ubicación Predefinida</InputLabel>
                        <Select
                            value={puntoLlegadaData.ubicacionPredefinida}
                            onChange={(e) => setPuntoLlegadaData({ ...puntoLlegadaData, ubicacionPredefinida: e.target.value })}
                        >
                            {ubicacionesPredefinidas.map((ubicacion, index) => (
                                <MenuItem key={index} value={ubicacion}>{ubicacion}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>Departamento</InputLabel>
                        <Select
                            value={puntoLlegadaData.departamento}
                            onChange={(e) => setPuntoLlegadaData({ ...puntoLlegadaData, departamento: e.target.value })}
                        >
                            {departamentos.map((departamento, index) => (
                                <MenuItem key={index} value={departamento}>{departamento}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>Provincia</InputLabel>
                        <Select
                            value={puntoLlegadaData.provincia}
                            onChange={(e) => setPuntoLlegadaData({ ...puntoLlegadaData, provincia: e.target.value })}
                        >
                            {provincias[puntoLlegadaData.departamento]?.map((provincia, index) => (
                                <MenuItem key={index} value={provincia}>{provincia}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>Distrito</InputLabel>
                        <Select
                            value={puntoLlegadaData.distrito}
                            onChange={(e) => setPuntoLlegadaData({ ...puntoLlegadaData, distrito: e.target.value })}
                        >
                            {distritos[puntoLlegadaData.provincia]?.map((distrito, index) => (
                                <MenuItem key={index} value={distrito}>{distrito}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        label="Dirección"
                        variant="outlined"
                        value={puntoLlegadaData.direccion}
                        onChange={(e) => setPuntoLlegadaData({ ...puntoLlegadaData, direccion: e.target.value })}
                        sx={{ mb: 2 }}
                    />
                    <Grid container justifyContent="space-between" mt={4}>
                        <Button variant="text" onClick={handleBack}>Atrás</Button>
                        <Button variant="contained" onClick={handleNext}>Siguiente</Button>
                    </Grid>
                </Box>
            )}

            {/* Paso 6: Transporte */}
            {activeStep === 5 && (
                <Box>
                    <Typography variant="h6" mb={3}>Transporte</Typography>
                    <Autocomplete
                        options={vehiculos}
                        getOptionLabel={(option) => `${option.label} - Placa: ${option.placa}`}
                        renderInput={(params) => <TextField {...params} label="Vehículo" />}
                        onChange={(event, value) => setTransporteData({ ...transporteData, vehiculo: value })}
                        sx={{ mb: 2 }}
                    />
                    <Autocomplete
                        multiple
                        options={conductores}
                        getOptionLabel={(option) => `${option.nombres} ${option.apellidos} - Licencia: ${option.licencia}`}
                        renderInput={(params) => <TextField {...params} label="Conductores" />}
                        onChange={(event, value) => setTransporteData({ ...transporteData, conductores: value })}
                        sx={{ mb: 2 }}
                    />
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>Pagador del Flete</InputLabel>
                        <Select
                            value={transporteData.pagadorFlete}
                            onChange={(e) => setTransporteData({ ...transporteData, pagadorFlete: e.target.value })}
                        >
                            {clientes.map((cliente, index) => (
                                <MenuItem key={index} value={cliente.label}>{cliente.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Fecha de Traslado"
                            value={fechaTraslado}
                            onChange={(newValue) => setFechaTraslado(newValue)}
                            renderInput={(params) => <TextField {...params} fullWidth sx={{ mb: 2 }} />}
                        />
                    </LocalizationProvider>
                    <Grid container justifyContent="space-between" mt={4}>
                        <Button variant="text" onClick={handleBack}>Atrás</Button>
                        <Button variant="contained" onClick={handleNext}>Siguiente</Button>
                    </Grid>
                </Box>
            )}

            {/* Modal para Buscar Documentos */}
            <Modal open={openModal} onClose={closeBuscarDocumentosModal}>
                <Box sx={{ width: 400, backgroundColor: 'white', p: 4, margin: 'auto', mt: '20vh' }}>
                    <Typography variant="h6">Buscar Documentos</Typography>
                    {/* Contenido del modal para buscar documentos */}
                </Box>
            </Modal>

            {/* Botón para Imprimir */}
            {activeStep === 6 && (
                <Box sx={{ textAlign: 'center', mt: 4 }}>
                    <Button variant="contained" onClick={handlePrint}>Imprimir Documento</Button>
                </Box>
            )}
        </Box>
    );
};
