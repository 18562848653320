import React, { useEffect, useState, useRef } from 'react';
import { BotonNuevo } from '../components/buttons/BotonNuevo';
import { ContenedorParametros } from '../components/utils/ContenedorParametros';
import { SearchTable } from '../components/utils/SearchTable';
import { Modal } from '../components/modal/Modal';
import fotoDefault from '../../assets/images/productoDefault.jpg';
import { useProductos } from '../../hooks/useProductos';
import { useVentas } from '../../hooks/useVentas';
import ExcelExporter from '../components/ExcelExporter';

// import { BotonNuevo } from '../components/buttons/BotonNuevo';


export const ListaVentas = () => {
    
useEffect(()=>{
    listarVentas()
},[])

    const { venta, listarVentas,ventas, editarValorVenta, obtenerVenta,guardarEditarVenta,
        isOpenModal, closeModal, eliminarVenta, isOpenModalEliminar, closeModalEliminar, ventaEliminar } = useVentas()

    const idTablaProductos = "tablaProductos"
    
    return (
        <>

        <ContenedorParametros>
            <SearchTable tablaId={idTablaProductos}></SearchTable>
            <ExcelExporter tableId={idTablaProductos} fileName="tabla_excel" sheetName="Sheet 1" buttonText="Exportar a Excel" />
        </ContenedorParametros>

        {/* containerScroll */}
        <div className="containerScroll relative contenedor-tabla ">

                <table id={idTablaProductos} className="tableResponsiveVoucher " >
                    <thead className=''>
                        <tr >
                            <th className='w-[50px] '>N</th>
                            <th>Fecha</th>
                            <th>Doc.</th>
                            <th>Cliente</th>
                            <th>Monto</th>
                            <th>Estado</th>
                            <th></th>
                        </tr>

                    </thead>
                    <tbody>
                        {ventas.map((venta,i) => (
                            <tr key={venta.CodVenta} >
                                <td data-label="num">{++i}</td>
                                <td data-label="Fecha">{(venta.Fecha).split("-").reverse().join("-")}</td>
                                <td data-label="Documento">{venta.Documento}</td>
                                <td data-label="Cliente">{venta.Cliente}</td>
                                <td data-label="Monto">S/{venta.MontoTotal}</td>
                                <td data-label="Estado">{venta.Estado}</td>
                                <td data-label="Opciones">
                                    <button onClick={{}} className="mr-2 hover:text-orange-200 text-orange-500 text-xl px-[5px] py-1 rounded">
                                        <i className="fas fa-list"></i>
                                    </button>   
                                    <button>
                                        <a className="mr-2 fa fa-whatsapp hover:text-green-200 text-green-600 text-2xl" target="_blank" href={`https://api.whatsapp.com/send/?text=${venta.Documento}|${venta.MontoTotal}:%0a${venta.Documento}%0acel: ${venta.Estado}%0a`}></a>
                                    </button>
                                    <button onClick={{}} className="hover:text-blue-200 text-blue-500 text-xl px-[5px] py-1 rounded">
                                        <i className="fas fa-print"></i>
                                    </button>
                                </td>
                            </tr>
                            
                        ))}
                    </tbody>
                </table>
            </div>




            <Modal 
                isOpen={isOpenModal} closeModal={closeModal} 
                action={{}}
                title="Registrar Producto"
            >
            <div className='h-[500px]  lg:h-[500px] '>
                <div className="flex  items-center  justify-center">
                        
                    
                    <div style={{textAlign:'center'}} className="">
                        <label htmlFor="file-1">
                            {/* <img className=' rounded-xl w-[250px] h-[250px] mb-1 lg:w-[150px] lg:h-[150px] cursor-pointer' src={fotoUrlPersona} alt=''  /> */}
                            {/* <input ref={inputFoto} type="file" name="file-1" id="file-1" className="inputfile inputfile-1" accept='.jpg,.png'  onChange={onSelectFile} /> */}
                                <i className="fa fa-image iborrainputfile text-white text-[18px]"></i> 
                                <span className="iborrainputfile"> Seleccione</span>
                        </label>
                    </div>
                </div>


                {/* <div className='form'> */}
                    {/* <div className="group">
                    <input className='inputRegistro' /><span className="highlight"></span><span className="bar"></span>
                    <label className='labelRegistro'>Name</label>
                    </div> */}

                <div className="wrapper">
                    <form className='form'> 
                        <div className="group">
                            <input className='inputRegistro' type="text" required="required"/><span className="highlight"></span><span className="bar"></span>
                            <label className='labelRegistro' >Nombre</label>
                        </div>
                        
                        <div className="group">
                            <input  className='inputRegistro' type="number" required="required"/><span className="highlight"></span><span className="bar"></span>
                            <label className='labelRegistro' >Stock</label>
                        </div>

                        
                        <div className="group">
                            <input  className='inputRegistro' type="number" required="required"/><span className="highlight"></span><span className="bar"></span>
                            <label className='labelRegistro' >Precio Venta S/</label>
                        </div>


                        <div className="group">
                            <textarea className='textareaRegistro' type="textarea" rows="5" required="required"></textarea><span className="highlight"></span><span className="bar"></span>
                            <label className='labelRegistro' >Descripción</label>
                        </div>
                    </form>
                    </div>

            {/* </div> */}

            </div>

            </Modal>
        </>
    )

}