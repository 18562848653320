import React, { useState, useEffect, useContext, useRef, useMemo, useReducer} from 'react';
import { GoogleMap } from '../../utils/googlemaps';
import { BotonNeon } from '../components/buttons/BotonNeon';
import { MapaAlertas } from '../components/pages/incidentes/MapaAlertas';



const incidentesTipo = {
    LISTAR_INCIDENTES: 'LISTAR_INCIDENTES',
    OBTENER_INCIDENTE: 'OBTENER_INCIDENTE',
    CAMBIAR_ESTADO: 'CAMBIAR_ESTADO',
}

const incidentesInicial = {
    incidentes: [],
    incidente: null,
    estadoActual: 9,
    googleMap: new GoogleMap()
}

const incidentesReducer = (state, action) => {
    switch (action.type) {
        case incidentesTipo.LISTAR_INCIDENTES:
            return {
                ...state,
                incidentes: action.payload
            }
        case incidentesTipo.OBTENER_INCIDENTE:
            return {
                ...state,
                incidente: action.payload
            }
        case incidentesTipo.CAMBIAR_ESTADO:
            return {
                ...state,
                estadoActual: action.payload.codEstado
            }
        default:
            throw new Error(`Invalid action type`);
    }
}

const useIncidentes = () => {
    const [ stateIncidentes, dispatchIncidentes ] = useReducer(incidentesReducer, incidentesInicial);
    // const {stateUser, signOut} = useContext(UserContext)
    let marcador = null
    
    const ubicarIncidencia = (incidencia) => {
        if (!marcador) {
            marcador = stateIncidentes.googleMap.crearMarcador({
                visible: false
            });
        }
        
        marcador.setOptions({
            position: {lat: incidencia.latitudRegistro || incidencia.latitud, lng: incidencia.longitudRegistro || incidencia.longitud},
            animation: true,
            visible: true
        })

        stateIncidentes.googleMap.map.setOptions({
            center: {lat: incidencia.latitudRegistro || incidencia.latitud, lng: incidencia.longitudRegistro || incidencia.longitud},
            zoom: 15
        })
    }

    return { stateIncidentes, dispatchIncidentes, ubicarIncidencia};
}

export const Mapa = () => {

    const MostrarUbicacion = () =>{
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(position) { 
              var lat = position.coords.latitude;
              var lng = position.coords.longitude;
              const ubicacion = {
                latitudRegistro: lat,
                latitud:lat,
                longitudRegistro:lng,
                longitud:lng
            }
            ubicarIncidencia(ubicacion)
            
        }
            )}
        }
    const { stateIncidentes, ubicarIncidencia } = useIncidentes();

    return(
        <>
            <div className="relative mapa-incidentes h-[70%]" >
            <button className='border m-2' onClick={()=>MostrarUbicacion()}>  Mostrar mi ubicacion </button>
            <MapaAlertas googleMap={stateIncidentes.googleMap}></MapaAlertas>

            </div> 
                
            

        </>
    )
}