import { useState, useEffect,useContext } from 'react';
import { notify } from '../utils/utils';
import { useModal } from './useModal';
import { AuthFetch, Fetch } from '../services/api';
import { UserContext } from '../context/provider/UserProvider';

const ventaDefault = {
    CodVenta:0,
    CodDocumento:0,
    Documento: "",
    CodCliente:0,
    Cliente:"",
    MontoTotal:0,
    Estado:"Activo",
    Fecha:new Date(),
    CodCaja: 0
}


const ventaDetalleDefault = {
    CodDetalleVenta:0,
    CodProducto:0,
    NombreProducto:"",
    PrecioUnitario:0.00,
    Cantidad:1,
    PrecioTotal:0.00,
    CodVenta:0
}

export const useVentas = () => {
    const { stateUser, signOut } = useContext(UserContext)

    const [ ventas, setVentas] = useState([]);
    const [ venta, setVenta ] = useState(ventaDefault);
        
    const [ ventaDetalles, setVentaDetalles] = useState([]);
    const [ ventaDetalle, setVentaDetalle ] = useState(ventaDetalleDefault);

    const [ ventaMontoTotal, setVentaMontoTotal ] = useState();

    const [ isOpenModalVenta, openModalVenta, closeModalVenta ] = useModal();
    const [ isOpenModalEliminarVenta, openModalEliminarVenta, closeModalEliminarVenta ] = useModal();

    const [ isOpenModalDetalleVenta, openModalDetalleVenta, closeModalDetalleVenta ] = useModal();
    
    // const urlVentas = stateUser.link
    const urlVentas = process.env.REACT_APP_PROMETHEUS_API + '/ventas'

    useEffect(()=>{
        listarVentas()
    },[])

    const listarVentas = async () => {
        console.log("Entró al listar Ventas")
        const response = await AuthFetch({
            url: urlVentas + '?'+ new URLSearchParams({
                cod_empresa : stateUser.codEmpresa
            })
        });
        console.log("response ventas -> ", response)

        if (response.isValid) {
            setVentas(response.content);
        } else {
            notify(response.content, 'error');
        }
    }

    const obtenerUltimaVenta = async () => {
        console.log("USEVENTAS entro al obtenerUltimaVenta Hook")
        const response = await Fetch({
            url: urlVentas + `/ultimaVenta/`
        });
        // venta.CodVenta = response.content
        // detalleVenta.CodVenta = response.content
        console.log("USEVENTAS Response de Obtener Ultima venta ->",response.content )

        if (response.isValid) {
            setVentaDetalle({
                ...ventaDetalle,
                CodVenta: response.content.CodVenta
            });            
            setVenta(response.content)
        } else {
            notify(response.content, 'error');
        }
    }

    const obtenerVenta = async (codVenta) => {
        if (codVenta) {
            const response = await Fetch({
                url: urlVentas + `?${codVenta}`
            });
            if (response.isValid) {
                setVenta(response.content);
            } else {
                notify(response.content, 'error');
            }
        } else {
            setVenta(ventaDefault);
        }
        openModalVenta();
    }

    const ventaEliminar = async (codVenta) =>{
        if (codVenta) {
            const response = await Fetch({
                url:urlVentas + `/${codVenta}`
            });

            if (response.isValid) {
                setVenta(response.content);
            } else {
                notify(response.content, 'error');
            }
        } else {
            setVenta(ventaDefault);
        }
        openModalEliminarVenta();
    }

    const eliminarVenta = async (codVenta)=>{
        const response = await Fetch({
            url: urlVentas + `/${codVenta}`,
            method:'DELETE'
        })
        if (response.isValid) {
            listarVentas()
        }else{
            notify(response.content, 'error' )
        }
        closeModalEliminarVenta()
    }

    const guardarEditarVenta = async () => {
        console.log("GUARDAREDITARVENTA : venta -> ",venta)
        const esGuardar = venta.CodVenta <= 0;
        const response = await Fetch({
            url: urlVentas + '/ventas',
            method: esGuardar ? 'POST' : 'PUT',
            body: JSON.stringify({
                ...(!esGuardar && { CodVenta: venta.CodVenta }),
                CodDocumento:venta.CodDocumento,
                Documento:venta.Documento,
                CodCliente:venta.CodCliente,
                Cliente:venta.Cliente,
                MontoTotal:venta.MontoTotal,
                Estado:venta.Estado,
                Fecha: venta.Fecha,
                CodCaja: venta.CodCaja
            })
        });
        console.log("GUARDAREDITARVENTA : Response  -> ", response)

        if (response.isValid) {
            await obtenerUltimaVenta()
        }else{
            notify(response.content, 'error')
        }
    }

    const editarValorVenta = (key, value) => {
        console.log("EDITARVALORVENTA: ",key , " => ",value)
        setVenta(venta => {
            return {
                ...venta,
                [key]: value
            }
        });
        guardarEditarVenta()

    }

// ---------------------------------- DETALLE VENTAS 

    const listarDetalleVentas = async () => {
        const response = await Fetch({
            url: urlVentas + '/detalleVenta'
        });
        if (response.isValid) {
            setVentaDetalles(response.content);
        } else {
            notify(response.content, 'error');
        }
    }

    const listarDetalleVentaPorCodVenta = async (codVenta) => {
        console.log("LISTARDETALLEVENTAPORCODVENTA: codVenta -> " ,codVenta)
        const response = await Fetch({
            url: urlVentas + `/detalleVenta/venta/${codVenta || venta.CodVenta}`
        });

        //////se perdió
        console.log("LISTARDETALLEVENTAPORCODVENTA: Response -> ", response)
        if (response.isValid) {
            setVentaDetalles(response.content);

        } else {
            notify(response.content, 'error');
        }
    }

    const obtenerDetalleVenta = async (codDetalleVenta) => {
        if (codDetalleVenta) {
            const response = await Fetch({
                url: urlVentas + `/detalleVenta/${codDetalleVenta}`
            });
            if (response.isValid) {
                setVentaDetalle(response.content);
            } else {
                notify(response.content, 'error');
            }
        } else {
            setVentaDetalle(ventaDetalleDefault);
        }
        openModalDetalleVenta();
    }

    const obtenerDetalleVentaPorCodDetalleVenta = async (codVentaDetalle) => {
            const response = await Fetch({
                url: urlVentas + `/detalleVenta/${codVentaDetalle}`
            });
            if (response.isValid) {
                setVentaDetalle(response.content);
            } else {
                notify(response.content, 'error');
            }
    }

    const eliminarVentaDetalle = async (codVentaDetalle)=>{
        console.log("eliminarVentaDetalle: CODDETALLEVENTA-> ",codVentaDetalle)
        const response = await Fetch({
            url: urlVentas + `/detalleVenta/${codVentaDetalle}`,
            method:'DELETE'
        })
        notify(response.content, response.isValid? 'success': 'error' )
        if (response.isValid) {
            listarDetalleVentaPorCodVenta(venta.CodVenta)
        }
    }
    
    const editarValorDetalleVenta = (key, value) => {
        console.log("editarValorDetalleVenta : ",key , " => ",value)
        setVentaDetalle(ventaDetalle  => {
            return {
                ...ventaDetalle ,
                [key]: value
            }
        });
    }

    const editarValorDetalleVenta2 = async (newValues) => {
        setVentaDetalle((prevVentaDetalle) => ({
            ...prevVentaDetalle,
            ...newValues,
        }));
    };
    const guardarEditarDetalleVenta = async () => {
        console.log("guardarEditarDetalleVenta : VentaDetalle -> ", ventaDetalle)
        if (ventaDetalle.CodVenta == 0){
            notify("No se pudo registrar la venta: El codVenta es igual a 0", "error")
            return
        } 
        const esGuardar = ventaDetalle.CodDetalleVenta <= 0;
        const response = await Fetch({
            url: urlVentas + '/detalleVenta',
            method: esGuardar ? 'POST' : 'PUT',
            body: JSON.stringify({
                ...(!esGuardar && { CodDetalleVenta: ventaDetalle.CodDetalleVenta }),
                CodProducto:ventaDetalle.CodProducto,
                NombreProducto:ventaDetalle.NombreProducto,
                PrecioUnitario:ventaDetalle.PrecioUnitario,
                Cantidad:ventaDetalle.Cantidad,
                PrecioTotal:ventaDetalle.PrecioTotal,
                CodVenta:ventaDetalle.CodVenta
            })
        });
        console.log("guardarEditarDetalleVenta : response-> ",response)
        if (response.isValid) {
            await listarDetalleVentaPorCodVenta(venta.CodVenta)
        }else{
            notify(response.content, 'error');
        }
    }



    return { 
        listarVentas,
        obtenerUltimaVenta,
        obtenerVenta,
        ventaEliminar,
        eliminarVenta,
        guardarEditarVenta,
        editarValorVenta,
        ventas,
        venta,
        ventaDetalle,
        ventaDetalles,
        
        listarDetalleVentas,
        listarDetalleVentaPorCodVenta,
        obtenerDetalleVenta,
        obtenerDetalleVentaPorCodDetalleVenta,
        eliminarVentaDetalle,
        guardarEditarDetalleVenta,
        editarValorDetalleVenta,
        editarValorDetalleVenta2,
        }
}