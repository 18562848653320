import React, { useState, useRef, useEffect } from 'react';

export const FormasEntrega = () => {

    
    return (
        <>
        </>
    );
};
