import React, { useEffect, useState, useRef } from 'react';
import { BotonNuevo } from '../../components/buttons/BotonNuevo';
import { ContenedorParametros } from '../../components/utils/ContenedorParametros';
import { SearchTable } from '../../components/utils/SearchTable';
import { Modal } from '../../components/modal/Modal';
import fotoDefault from '../../../assets/images/clienteDefault.jpg';
import { useClientes } from '../../../hooks/useClientes';
import { soloCelular, soloDNI } from '../../../utils/utils';
// import { BotonNuevo } from '../components/buttons/BotonNuevo';
import { Side } from '../../components/modal/Side';
import InputText from '../../components/InputText';
import SelectText from '../../components/SelectText';
import { CloudUpload } from '@mui/icons-material';


import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

// import SearchIcon  from '@mui/icons-material/Edit';
import { Search as SearchIcon } from '@mui/icons-material';

import FavoriteIcon from '@mui/icons-material/Favorite';
import NavigationIcon from '@mui/icons-material/Navigation';



export const Clientes = () => {
    const { cliente, listarClientes, guardarClienteConFoto,clientes, editarValorCliente, obtenerCliente,
        isOpenModal, closeModal, fotoUrlCliente,setFotoFile,setFotoUrlCliente,eliminarCliente, isOpenModalEliminar, 
        closeModalEliminar, clienteEliminar,cargarDatosCliente } = useClientes();
    
    const listaFotos=[

        {cod:0, url:'https://minioapi.prometheusperu.com/api/Archivo/verImagen?NombreCarpeta=pruebas&NombreImagen=/clientes/Marciana'},
        {cod:1, url:'https://cdn.icon-icons.com/icons2/2859/PNG/512/avatar_face_man_boy_male_profile_smiley_happy_people_icon_181657.png'},
        {cod:2, url:'https://cdn.icon-icons.com/icons2/2859/PNG/512/avatar_face_man_boy_male_profile_smiley_happy_people_icon_181660.png'},
        {cod:3, url:'https://media.istockphoto.com/id/1251155229/es/vector/mujer-chica-mujer-dibujos-animados-avatar-dise%C3%B1o-plano.jpg?s=612x612&w=0&k=20&c=o3MHVAcBNqCNWtXiYb1JGlpFLdSlK87VaT_4S-nxQyo='},
        {cod:4, url:'https://static.vecteezy.com/system/resources/previews/002/002/257/original/beautiful-woman-avatar-character-icon-free-vector.jpg'},
        {cod:5, url:'https://images.vexels.com/content/145908/preview/male-avatar-maker-2a7919.png'},
        {cod:-1, url:'https://img.freepik.com/vector-premium/diseno-ilustracion-vector-personaje-avatar-hombre-joven_24877-18514.jpg'},
    ]
    const [ posicionFoto, setPosicionFoto ]= useState(0)
    const idTablaProductos = "tablaProductos"
    const [selectedFile, setSelectedFile] = useState()
    const inputFoto = useRef();
    const [ botonDNI, setBotonDNI ] = useState(false)

    useEffect(() => {
        if (!selectedFile) {
            setFotoUrlCliente(fotoDefault)
            // setSelectedFile(null)
            return
        }
        const objectUrl = URL.createObjectURL(selectedFile)
        setFotoUrlCliente(objectUrl || fotoDefault)
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])

    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(fotoDefault)
            return
        }
        setSelectedFile(e.target.files[0])
        setFotoFile(e.target.files[0])
    }

    useEffect(()=>{
        if(cliente.NumeroDocumento.length == 8){
            setBotonDNI(true)
        }else{
            setBotonDNI(false)
        }
    },[cliente.NumeroDocumento])


    const cambiarFoto=(posicion)=>{
        let codFoto = 0
        if (posicion=="s") {
            codFoto = posicionFoto + 1
            setPosicionFoto(posicionFoto + 1) 
        } else{
            codFoto = posicionFoto - 1
            setPosicionFoto(posicionFoto - 1) 
        }
        const fotoElegida = listaFotos.find(foto => foto.cod === codFoto);
        console.log("FOTOELEGIDA->", fotoElegida)
        setFotoUrlCliente(fotoElegida.url)

    }

    return (
        <>
        <ContenedorParametros>
        <BotonNuevo onClick={() => obtenerCliente()}></BotonNuevo>
        <SearchTable tablaId={idTablaProductos}></SearchTable>
        </ContenedorParametros>


        <div className="containerScroll relative contenedor-tabla ">

                    <table id={idTablaProductos} className="tableResponsive " >
                        <thead className=''>
                            <tr >
                                <th></th>
                                <th>Nombres</th>
                                {/* <th>Apellidos</th> */}
                                <th>Documento</th>
                                {/* <th>Número Documento</th> */}
                                <th>Celular</th>
                                <th>Correo</th>
                                <th>Direccion</th>
                                <th>Estado</th>
                                <th></th>
                            </tr>

                        </thead>
                        <tbody>
                            {clientes.map((cliente) => (

                                <tr key={cliente.CodCliente}  style={{textAlign:"center",alignContent:"center",alignItems:"center"}}>
                                    <td data-th="Foto" className='tableFotoCliente'>
                                        <img src={cliente.Foto} alt={cliente.Foto} className="lg:ml-[-5px] lg:mt-6 ml-[20px] rounded-full w-[60px] h-[60px] lg:w-[150px] lg:h-[150px]"/>
                                    </td>
                                    <td data-th="Nombre" className='tdNombrePersona'>{cliente.Nombres} <br/>{cliente.ApellidoPaterno} {cliente.ApellidoMaterno}</td>
                                    {/* <td data-th="Apellidos">{cliente.ApellidoPaterno} {cliente.ApellidoMaterno}</td> */}
                                    <td data-th="TipoDocumento" className='  '>
                                        <span className=' invisible lg:visible mr-2 fa fa-id-card-o'></span>
                                        <span className='text-center'>{cliente.TipoDocumento == 1?'DNI': 'CE'}</span><br/>
                                        <span >{cliente.NumeroDocumento}</span></td>
                                    {/* <td data-th="NumeroDocumento">{cliente.NumeroDocumento}</td> */}
                                    <td data-th="Celular"><span className='invisible lg:visible mr-2 fa fa-mobile'></span>{cliente.Celular}</td>
                                    <td data-th="Correo"><span className='invisible lg:visible mr-2 fa fa-envelope-o'></span>{cliente.Correo}</td>
                                    <td data-th="Direcion"><span className='invisible lg:visible mr-2 fa fa-map-marker'></span>{cliente.Direccion}</td>
                                    <td data-th="Estado" ><span className={`cursor-pointer text-white bg-green-600 px-2 py-[2px] rounded uppercase text-[16px] font-bold `}>{cliente.Estado}</span></td>
                                    <td data-th="Opciones">
                                        <button  onClick={()=>console.log("CLICK EN CREDITO")} className="hover:text-purple-300 text-purple-500 text-xl px-[5px] py-1 rounded ml-3">
                                            <i className="fa fa-credit-card-alt"></i>
                                        </button>
                                        <button  onClick={()=>console.log("CLICK EN DETALLE CLIENTE")} className="hover:text-yellow-300 text-yellow-500 text-xl px-[5px] py-1 rounded ml-3">
                                            <i className="fas fa-bars"></i>
                                        </button>
                                        <button onClick={() => obtenerCliente(cliente.CodCliente)} className="hover:text-green-300 text-green-500 text-xl px-[5px] py-1 rounded ml-3">
                                            <i className="fa fa-pencil"></i>
                                        </button>
                                        <button  onClick={()=> clienteEliminar(cliente.CodCliente)} className="hover:text-red-300 text-red-500 text-xl px-[5px] py-1 rounded ml-3">
                                            <i className="fa fa-trash-o"></i>
                                        </button>

                                    </td>
                                </tr>

                                
                            
                            ))}

                        </tbody>
                    </table>
            </div>



            <Side 
                isOpen={isOpenModal} closeModal={closeModal} 
                action={() => guardarClienteConFoto()}
                title={cliente.CodCliente>0?"Editar Cliente":"Registrar Cliente"}
            >
                <div className='h-[500px]  lg:h-[500px] mt-3 mb-4 '>
                    <div className="flex  items-center  justify-center">
                        
                        <div className="flex flex-col items-center">
                            <input type="file" ref={inputFoto} style={{ display: 'none' }} accept="image/*" onChange={onSelectFile} />
                            <img 
                                className='hover:ring-2 hover:ring-[#E2504C] rounded w-[250px] h-[250px] mb-1 lg:w-[200px] lg:h-[200px] cursor-pointer'
                                src={fotoUrlCliente} 
                                onClick={() => inputFoto.current.click()}
                                alt=''  
                            />

                            <div className="mt-2">
                                <Fab
                                    variant="extended"
                                    color="success" // Cambiar el color a verde
                                    size="small"
                                    onClick={() => inputFoto.current.click()}
                                >
                                    <CloudUpload sx={{ mr: 1 }} />
                                    <span className="text-xs">Subir Imagen</span>
                                </Fab>
                            </div>
                        </div>

                    </div>

                            <Box display="flex" alignItems="center">
                                <InputText
                                    classBox='w-[250px]'
                                    id='numeroDocumento'
                                    label="Numero de Documento"
                                    defaultValue={cliente.NumeroDocumento}
                                    onChange={(e) => editarValorCliente("NumeroDocumento", e.target.value)} 
                                    onKeyPress={(e) => soloDNI(e, e.target)}
                                    type="number" 
                                    required="required"
                                />
                                <Box sx={{ '& > :not(style)': { m: 1, marginTop: '45px' , marginLeft: '-45px'} }}>
                                    <Fab size="small" color={`${botonDNI? 'secondary':'ds'}`} aria-label="search" onClick={()=>cargarDatosCliente()}>
                                        <SearchIcon />
                                    </Fab>
                                </Box>
                            </Box>

                            <InputText classBox='' id='nombre' label="Nombres"
                                defaultValue={cliente.Nombres}
                                onChange={(e) => editarValorCliente("Nombres", e.target.value)} 
                            />

                            <InputText classBox='' id='apellidoPaterno' label="Apellido Paterno"
                                defaultValue={cliente.ApellidoPaterno}
                                onChange={(e) => editarValorCliente("ApellidoPaterno", e.target.value)} 
                            />

                            <InputText classBox='' id='apellidoMaterno' label="Apellido Materno"
                                defaultValue={cliente.ApellidoMaterno}
                                onChange={(e) => editarValorCliente("ApellidoMaterno", e.target.value)} 
                            />

                            <InputText classBox='' id='celular' label="Celular"
                                defaultValue={cliente.Celular}
                                onChange={(e) => editarValorCliente("Celular", e.target.value)} 
                            />

                            <InputText classBox='' id='correo' label="Correo"
                                defaultValue={cliente.Correo}
                                onChange={(e) => editarValorCliente("Correo", e.target.value)} 
                            />

                            <InputText multilinea={3} classBox='' id='direccion' label="Dirección"
                                defaultValue={cliente.Direccion}
                                onChange={(e) => editarValorCliente("Direccion", e.target.value)} 
                            />
                </div>

            </Side>


            <Side 
                isOpen={isOpenModalEliminar} closeModal={closeModalEliminar} action={() => eliminarCliente(cliente.CodCliente)}
                title= "Eliminar Cliente"
                spinner={{}}
                textButtons={{ confirm: 'Si', denied: 'No' }}
            >
                    <div className="flex gap-8 items-center flex-wrap justify-center">
                        <div className="w-[400px] px-4">
                            <label className="text-center text-gray-800">¿Está seguro de eliminar el cliente? </label>
                            <hr></hr>
                            <div className='text-center mt-5 content-center items-center'>
                                <div className=''>
                                    <label className="text-center  text-gray-800  ">---- {cliente.Nombres}----</label>
                                </div>
                                <div className=' content-center text-center'>
                                    <img className='ml-[25%] rounded-xl w-[180px] h-[180px] mb-1 lg:w-[80px] lg:h-[80px] cursor-pointer' src={fotoUrlCliente} alt=''  />
                                </div>

                            </div>
                        </div>
                    </div>
                    
                
            </Side>   

        </>
    )

}