import React, { useEffect, useState, useRef } from 'react';
import { BotonNuevo } from '../components/buttons/BotonNuevo';
import { ContenedorParametros } from '../components/utils/ContenedorParametros';
import { SearchTable } from '../components/utils/SearchTable';
import { Modal } from '../components/modal/Modal';
import fotoDefault from '../../assets/images/clienteDefault.jpg';
import { useProductos } from '../../hooks/useProductos';
import { SelectFiltro } from '../components/forms/SelectFiltro';
import { Tooltip } from '../components/utils/Tooltip';
// import { BotonNuevo } from '../components/buttons/BotonNuevo';

const products = [
    {
        id: 1,
        nombre: 'Foco Smart Wifi',
        descripcion: "Foco Marca Xiaomi RGB",
        foto: 'https://promart.vteximg.com.br/arquivos/ids/6484932-1000-1000/image-7baf87f86be8454288e73742d99a075d.jpg?v=637970741933000000',
        precioUnidad: 'S/50',
        cantidad: '2',
        precioTotal: 'S/100'
    },    
    {
        id: 2,
        nombre: 'Control Universal Smart',
        descripcion: "Control IR, control universal Smart",
        foto: 'https://media.cablematic.com/__sized__/images_1000/an11100-03-thumbnail-1080x1080-70.jpg',
        precioUnidad: 'S/120',
        cantidad: '3',
        precioTotal: 'S/360'
    },
    {
        id: 3,
        nombre: 'Interruptor Smart',
        descripcion: "Interruptor Smart de 3 botones",
        foto: 'https://www.yaesta.com/uploads/productos/709bf20a3ec7a53965a9fce1995c837caee8dad4.jpeg?v09232022',
        precioUnidad: 'S/100',
        cantidad: '5',
        precioTotal: 'S/500'
    },
    {
        id: 4,
        nombre: 'Foco Smart Wifi',
        descripcion: "Foco Marca Xiaomi RGB",
        foto: 'https://promart.vteximg.com.br/arquivos/ids/6484932-1000-1000/image-7baf87f86be8454288e73742d99a075d.jpg?v=637970741933000000',
        precioUnidad: 'S/50',
        cantidad: '2',
        precioTotal: 'S/100'
    },    
    {
        id: 5,
        nombre: 'Control Universal Smart',
        descripcion: "Control IR, control universal Smart",
        foto: 'https://media.cablematic.com/__sized__/images_1000/an11100-03-thumbnail-1080x1080-70.jpg',
        precioUnidad: 'S/120',
        cantidad: '3',
        precioTotal: 'S/360'
    },
    {
        id: 6,
        nombre: 'Interruptor Smart',
        descripcion: "Interruptor Smart de 3 botones",
        foto: 'https://www.yaesta.com/uploads/productos/709bf20a3ec7a53965a9fce1995c837caee8dad4.jpeg?v09232022',
        precioUnidad: 'S/100',
        cantidad: '5',
        precioTotal: 'S/500'
    },
]

const productos = [
    {
        id: 1, name: 'Foco Smart Wifi',
        href: '#',
        imageSrc: 'https://promart.vteximg.com.br/arquivos/ids/6484932-1000-1000/image-7baf87f86be8454288e73742d99a075d.jpg?v=637970741933000000',
        imageAlt: "Foco Marca Xiaomi RGB",
        price: 'S/50',
        color: 'RGB',
    },
    {
        id: 2, name: 'Enchufe Smart Wifi',
        href: '#',
        imageSrc: 'https://modopolvos.com/wp-content/uploads/2020/11/125201737_4031371376889494_5396668465297460400_o.jpg',
        imageAlt: "Enchufe Marca Generica",
        price: 'S/35',
        color: 'Blanco',
    }, 
    {
        id: 3, name: 'Regleta Smart Wifi',
        href: '#',
        imageSrc: 'https://realplaza.vtexassets.com/arquivos/ids/16991618-800-auto?v=637638648685970000&width=800&height=auto&aspect=true',
        imageAlt: "Regleta supresor de Picos Smart",
        price: 'S/150',
        color: 'blanco',
    }, 
    {
        id: 4, name: 'Lampara Smart Wifi',
        href: '#',
        imageSrc: 'https://home.ripley.com.pe/Attachment/WOP_5/DMK-HOME/L%C3%81MPARA%20DE%20MESA%20LED%20SMART%20IWOOLE.jpg',
        imageAlt: "Lampara DMK multicolor Smart App",
        price: 'S/120',
        color: 'RGB',
    }, 
    {
        id: 5, name: 'Aspiradora Robot Smart',
        href: '#',
        imageSrc: 'https://xiaomioficial.pe/media/catalog/product/cache/2b88132ea045bae0fc2b44a4f558f9b1/h/m/hm070xia01_2.jpg',
        imageAlt: "Aspira, barre, trapea, marca Xiaomi",
        price: 'S/900',
        color: 'Blanco',
    }, 
    {
        id: 6, name: 'Lenovo SmartClock ',
        href: '#',
        imageSrc: 'https://plazavea.vteximg.com.br/arquivos/ids/1938401-1000-1000/image-be99c2c6541748dcb13e8afd000cfd7e.jpg',
        imageAlt: "SmartClock con asistente de Google",
        price: 'S/290',
        color: 'Plomo',
    }, 
    {
        id: 7, name: 'Parlante Smart Xiaomi',
        href: '#',
        imageSrc: 'https://i.ibb.co/Sch0cGF/speaker-L09-G.jpg',
        imageAlt: "Parlante Xiaomi con Asistente de Google",
        price: 'S/200',
        color: 'Plomo',
    }, 
    {
        id: 8, name: 'Cerradura Smart Wifi',
        href: '#',
        imageSrc: 'https://http2.mlstatic.com/D_NQ_NP_870825-MLA50404879178_062022-O.webp',
        imageAlt: "Cerradura Smart Clave, Huella, Tarjeta,llave, App",
        price: 'S/550',
        color: 'Negro',
    }, 
    {
        id: 9, name: 'Google Home Mini',
        href: '#',
        imageSrc: 'https://modopolvos.com/wp-content/uploads/2020/12/google-home-mini-01.jpg',
        imageAlt: "Parlante con Asistente de Google",
        price: 'S/150',
        color: 'Plomo',
    }, 
    {
        id: 10, name: 'Interruptor Smart',
        href: '#',
        imageSrc: 'https://www.yaesta.com/uploads/productos/709bf20a3ec7a53965a9fce1995c837caee8dad4.jpeg?v09232022',
        imageAlt: "Interruptor Smart de 3 botones",
        price: 'S/90',
        color: 'Blanco',
    },
    {
        id: 11, name: 'Control Universal Smart',
        href: '#',
        imageSrc: 'https://media.cablematic.com/__sized__/images_1000/an11100-03-thumbnail-1080x1080-70.jpg',
        imageAlt: "Control IR, control universal Smart",
        price: 'S/200',
        color: 'Negro',
    },  
    {
        id: 1, name: 'Foco Smart Wifi',
        href: '#',
        imageSrc: 'https://promart.vteximg.com.br/arquivos/ids/6484932-1000-1000/image-7baf87f86be8454288e73742d99a075d.jpg?v=637970741933000000',
        imageAlt: "Foco Marca Xiaomi RGB",
        price: 'S/50',
        color: 'RGB',
    },
    {
        id: 2, name: 'Enchufe Smart Wifi',
        href: '#',
        imageSrc: 'https://modopolvos.com/wp-content/uploads/2020/11/125201737_4031371376889494_5396668465297460400_o.jpg',
        imageAlt: "Enchufe Marca Generica",
        price: 'S/35',
        color: 'Blanco',
    }, 
    {
        id: 3, name: 'Regleta Smart Wifi',
        href: '#',
        imageSrc: 'https://realplaza.vtexassets.com/arquivos/ids/16991618-800-auto?v=637638648685970000&width=800&height=auto&aspect=true',
        imageAlt: "Regleta supresor de Picos Smart",
        price: 'S/150',
        color: 'blanco',
    }, 
    {
        id: 4, name: 'Lampara Smart Wifi',
        href: '#',
        imageSrc: 'https://home.ripley.com.pe/Attachment/WOP_5/DMK-HOME/L%C3%81MPARA%20DE%20MESA%20LED%20SMART%20IWOOLE.jpg',
        imageAlt: "Lampara DMK multicolor Smart App",
        price: 'S/120',
        color: 'RGB',
    }, 
    {
        id: 5, name: 'Aspiradora Robot Smart',
        href: '#',
        imageSrc: 'https://xiaomioficial.pe/media/catalog/product/cache/2b88132ea045bae0fc2b44a4f558f9b1/h/m/hm070xia01_2.jpg',
        imageAlt: "Aspira, barre, trapea, marca Xiaomi",
        price: 'S/900',
        color: 'Blanco',
    }, 
    {
        id: 6, name: 'Lenovo SmartClock ',
        href: '#',
        imageSrc: 'https://plazavea.vteximg.com.br/arquivos/ids/1938401-1000-1000/image-be99c2c6541748dcb13e8afd000cfd7e.jpg',
        imageAlt: "SmartClock con asistente de Google",
        price: 'S/290',
        color: 'Plomo',
    }, 
    {
        id: 7, name: 'Parlante Smart Xiaomi',
        href: '#',
        imageSrc: 'https://i.ibb.co/Sch0cGF/speaker-L09-G.jpg',
        imageAlt: "Parlante Xiaomi con Asistente de Google",
        price: 'S/200',
        color: 'Plomo',
    }, 
    {
        id: 8, name: 'Cerradura Smart Wifi',
        href: '#',
        imageSrc: 'https://http2.mlstatic.com/D_NQ_NP_870825-MLA50404879178_062022-O.webp',
        imageAlt: "Cerradura Smart Clave, Huella, Tarjeta,llave, App",
        price: 'S/550',
        color: 'Negro',
    }, 
    {
        id: 9, name: 'Google Home Mini',
        href: '#',
        imageSrc: 'https://modopolvos.com/wp-content/uploads/2020/12/google-home-mini-01.jpg',
        imageAlt: "Parlante con Asistente de Google",
        price: 'S/150',
        color: 'Plomo',
    }, 
    {
        id: 10, name: 'Interruptor Smart',
        href: '#',
        imageSrc: 'https://www.yaesta.com/uploads/productos/709bf20a3ec7a53965a9fce1995c837caee8dad4.jpeg?v09232022',
        imageAlt: "Interruptor Smart de 3 botones",
        price: 'S/90',
        color: 'Blanco',
    },
    {
        id: 11, name: 'Control Universal Smart',
        href: '#',
        imageSrc: 'https://media.cablematic.com/__sized__/images_1000/an11100-03-thumbnail-1080x1080-70.jpg',
        imageAlt: "Control IR, control universal Smart",
        price: 'S/200',
        color: 'Negro',
    },  
]

const clientes = [
    {
        Id: 1,
        Nombre: 'Henry Cerna Quispe',
        Foto: 'https://img.freepik.com/vector-premium/diseno-avatar-persona_24877-38133.jpg?w=2000',
        DNI: "44445566",
        Celular: '999888777',
        Direccion: 'Los Geranios Comas',
    },
    {
        Id: 2,
        Nombre: 'Nombre de ejemplo',
        Foto: 'https://img.freepik.com/vector-premium/diseno-avatar-persona_24877-38135.jpg?w=2000',
        DNI: "46975748",
        Celular: '999888777',
        Direccion: 'Los Geranios Comas',
    },
    {
        Id: 3,
        Nombre: 'Henry2 Cerna2 Quispe2',
        Foto: 'https://img.freepik.com/vector-premium/diseno-ilustracion-vector-personaje-avatar-hombre-joven_24877-18533.jpg',
        DNI: "46975748",
        Celular: '999888677',
        Direccion: 'Los Geranios Comas',
    },
    {
        Id: 4,
        Nombre: 'Henry Cerna Quispe',
        Foto: 'https://img.freepik.com/vector-premium/avatar-ilustracion-vector-hombre-joven-sonriente_92795-2729.jpg?w=2000',
        DNI: "46975748",
        Celular: '999888777',
        Direccion: 'Los Geranios Comas',
    },
    {
        Id: 5,
        Nombre: 'Henry Cerna Quispe',
        Foto: 'https://img.freepik.com/vector-premium/diseno-avatar-persona_24877-38130.jpg?w=2000',
        DNI: "46975748",
        Celular: '911111111',
        Direccion: 'Los Geranios Comas',
    },
    {
        Id: 6,
        Nombre: 'Henry Cerna Quispe',
        Foto: 'https://st2.depositphotos.com/1007566/10136/v/950/depositphotos_101369642-stock-illustration-avatar-person-design.jpg',
        DNI: "46975748",
        Celular: '999888777',
        Direccion: 'Los Geranios Comas',
    },
    {
        Id: 7,
        Nombre: 'Henry Cerna Quispe',
        Foto: 'https://us.123rf.com/450wm/djvstock/djvstock1608/djvstock160803163/60730710-chico-avatar-persona-personas-icono-de-la-gente-ilustraci%C3%B3n-aislada-y-plana-gr%C3%A1fico-vectorial.jpg',
        DNI: "46975748",
        Celular: '999888777',
        Direccion: 'Los Geranios Comas',
    },
    {
        Id: 1,
        Nombre: 'Henry Cerna Quispe',
        Foto: 'https://img.freepik.com/vector-premium/diseno-avatar-persona_24877-38133.jpg?w=2000',
        DNI: "46975748",
        Celular: '999888777',
        Direccion: 'Los Geranios Comas',
    },
    {
        Id: 2,
        Nombre: 'Nombre de ejemplo',
        Foto: 'https://img.freepik.com/vector-premium/diseno-avatar-persona_24877-38135.jpg?w=2000',
        DNI: "46975748",
        Celular: '999888777',
        Direccion: 'Los Geranios Comas',
    },
    {
        Id: 3,
        Nombre: 'Henry2 Cerna2 Quispe2',
        Foto: 'https://img.freepik.com/vector-premium/diseno-ilustracion-vector-personaje-avatar-hombre-joven_24877-18533.jpg',
        DNI: "46975748",
        Celular: '999888777',
        Direccion: 'Los Geranios Comas',
    },
    {
        Id: 4,
        Nombre: 'Henry Cerna Quispe',
        Foto: 'https://img.freepik.com/vector-premium/avatar-ilustracion-vector-hombre-joven-sonriente_92795-2729.jpg?w=2000',
        DNI: "46975748",
        Celular: '999888777',
        Direccion: 'Los Geranios Comas',
    },
    {
        Id: 5,
        Nombre: 'Henry Cerna Quispe',
        Foto: 'https://img.freepik.com/vector-premium/diseno-avatar-persona_24877-38130.jpg?w=2000',
        DNI: "46975748",
        Celular: '999888777',
        Direccion: 'Los Geranios Comas',
    },
    {
        Id: 6,
        Nombre: 'Henry Cerna Quispe',
        Foto: 'https://st2.depositphotos.com/1007566/10136/v/950/depositphotos_101369642-stock-illustration-avatar-person-design.jpg',
        DNI: "46975748",
        Celular: '999888777',
        Direccion: 'Los Geranios Comas',
    },
    {
        Id: 7,
        Nombre: 'Henry Cerna Quispe',
        Foto: 'https://us.123rf.com/450wm/djvstock/djvstock1608/djvstock160803163/60730710-chico-avatar-persona-personas-icono-de-la-gente-ilustraci%C3%B3n-aislada-y-plana-gr%C3%A1fico-vectorial.jpg',
        DNI: "46975748",
        Celular: '999888777',
        Direccion: 'Los Geranios Comas',
    },
    
]

export const RegistroServicio = () => {
    const { personas, listarPersonas, guardarEditarPersona, persona, 
        editarValorPersona, obtenerPersona, isOpenModal, closeModal , cargarDatosPersona,
        fotoUrlPersona, setFotoFile,setFotoUrlPersona,eliminarPersona,isOpenModalEliminar,closeModalEliminar,personaEliminar, 
        isOpenModalListado, openModalListado, closeModalListado,isOpenModalListadoProductos, openModalListadoProductos, 
        closeModalListadoProductos} = useProductos();

    const idTablaProductos = "tablaProductos"
    const idTablaClientes = "tablaClientes"

    const [selectedFile, setSelectedFile] = useState()
    const inputFoto = useRef();

    useEffect(() => {
        if (!selectedFile) {
            setFotoUrlPersona(fotoDefault)
            // setSelectedFile(null)
            return
        }
        const objectUrl = URL.createObjectURL(selectedFile)
        setFotoUrlPersona(objectUrl || fotoDefault)
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])

    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(fotoDefault)
            return
        }
        setSelectedFile(e.target.files[0])
        setFotoFile(e.target.files[0])
    }

    return (
        <>  
        
            <div className='flex items-center flex-wrap justify-center border rounded-xl   '>
            
                <div className=" wrapper  ">
                
                    <form className='form mt-5 mb-5 flex-auto  '> 
                        
                        <div className="  w-[350px] lg:w-full group justify-between mt-[30px]" >
                            
                            <select className='inputRegistro text-center center w-[350px] text-white bg-[#5a738b] ' type="text" required="required">
                                    <option> Nota Venta</option>
                                    <option> Boleta</option>
                                    <option> Factura</option>
                            </select>
                            <label className='text-white labelRegistro' >Seleccione Documento</label>


                        </div>
                        
                        <div className=" w-[350px]  lg:w-full group mt-[20px]" >
                            <div className='items-center '>
                                <input className='w-[350px] inputRegistro text-white' type="text" required="required"/><span className="highlight w-[200px]"></span><span className="bar w-[200px]"></span>
                                <label className='w-[350px] labelRegistro text-white'>Proveedor</label>
                                <button onClick={()=>openModalListado()}  data-tip data-for={'agregarProveedor'} className="boton-iconBusqueda  infoBusqueda rounded hover:bg-[#f66820] bg-[#f6682f] text-white hover:text-gray-300 tooltipstered  ">
                                    <i className="fa fa-user" aria-hidden="true"></i>
                                </button>                               
                            </div>                    
                        </div>

                        <div className="  lg:w-full group mt-[20px]  " >
                            <label className=' text-white labelRegistro ' >Agregar</label>

                                <button onClick={()=>openModalListadoProductos()} data-tip data-for={'agregarProducto'} className=" p-1  ml-[80px] text-xl rounded hover:bg-[#f66820] bg-[#f6682f] text-white hover:text-gray-300 tooltipstered  ">
                                    <i className="fas fa-box" aria-hidden="true"></i>
                                </button>  
                    
                                <button onClick={()=>openModalListadoProductos()} data-tip data-for={'agregarServicio'} className="p-1 ml-2 text-xl rounded hover:bg-[#f66820] bg-[#f6682f] text-white hover:text-gray-300 tooltipstered  ">
                                    <i className="fas fa-briefcase" aria-hidden="true"></i>
                                </button>  
                        </div>
                    </form>

                </div>

            </div>
        
            {/* containerScroll */}
            <div className="h-[400px]  mt-3 containerScroll  contenedor-tabla ">
                    <label className=''> *DETALLE VENTA</label>
                        <table className="tableResponsive mt-[-25px]" >
                            <thead className=''>
                                <tr >
                                    <th className='w-[20px]'>N°</th>
                                    <th className='' >Foto</th>
                                    <th >Nombre</th>
                                    <th>Descripcion</th>
                                    <th>Precio</th>
                                    <th>Cantidad</th>
                                    <th>Precio Total</th>
                                    <th></th>
                                </tr>

                            </thead>
                            <tbody>
                                {products.map((producto,i) => (

                                    <tr key={producto.id}  style={{textAlign:"center",alignContent:"center",alignItems:"center"}}>
                                        <td className='border-y' data-th="tablenum">{++i}</td>
                                        <td data-th="Foto" className='tableFotoCliente lg:invisible border-y'>
                                            <img src={producto.foto} alt={producto.descripcion} className="lg:ml-0 ml-[20px] rounded-full w-[60px] h-[60px] lg:w-[120px] lg:h-[120px]"/>
                                        </td>
                                        <td data-label="Producto" className='border-y' data-th="Nombre">{producto.nombre}</td>
                                        <td data-label="Descripcion" className='border-y' data-th="Descripcion"> {producto.descripcion}</td>
                                        <td data-label="Precio" className='border-y' data-th="precio">{producto.precioUnidad}</td>
                                        <td data-label="Cantidad" className='border-y' data-th="cantidad">{producto.cantidad}</td>
                                        <td data-label="Total" className='border-y' data-th="preciototal">{producto.precioTotal}</td>
                                        <td data-th="Opciones">
                                            
                                            <button  onClick={()=> personaEliminar(persona.codPersona)} className="hover:text-red-300 text-red-500 text-xl px-[5px] py-1 rounded ml-3">
                                                <i className="fas fa-trash"></i>
                                            </button>
                                        </td>
                                    </tr>

                                    
                                
                                ))}

                            </tbody>
                        </table>
            </div>
            
            <hr className='mt-2 mb-2'/>
            {/* <div className='   rounded-xl  '> */}
                <div className="   wrapper  " >
                    <div className="  lg:w-full group">
                        <label className='ml-[50%] lg:ml-1  text-white text-2xl ' >Total S/</label>
                        <input className='ml-[60%] lg:ml-2 text-white text-[35px] inputRegistro' type="" required="required"/><span className="highlight"></span><span className=""></span>
                    </div>
                </div>
            {/* </div> */}

            <Tooltip id={'agregarProveedor'}>Seleccionar Proveedor</Tooltip>
            <Tooltip id={'agregarProducto'}>Agregar Producto </Tooltip>
            <Tooltip id={'agregarServicio'}>Agregar Servicio</Tooltip>

            
            <Modal 
                isOpen={isOpenModal} closeModal={closeModal} 
                action={() => guardarEditarPersona()}
                title="Registrar Cliente"
            >
            <div className='h-[500px]  lg:h-[500px] '>
                <div className="flex  items-center  justify-center">
                        
                    
                    <div style={{textAlign:'center'}} className="">
                        <label htmlFor="file-1">
                            <img className=' rounded-xl w-[200px] h-[200px] mb-1 lg:w-[150px] lg:h-[150px] cursor-pointer' src={fotoUrlPersona} alt=''  />
                            <input ref={inputFoto} type="file" name="file-1" id="file-1" className="inputfile inputfile-1" accept='.jpg,.png'  onChange={onSelectFile} />
                                <i className="fa fa-image iborrainputfile text-white text-[18px]"></i> 
                                <span className="iborrainputfile"> Seleccione</span>
                        </label>
                    </div>
                </div>

                <div className="wrapper">
                    <form className='form'> 
                        <div className="group">
                            <input className='inputRegistro' type="text" required="required"/><span className="highlight"></span><span className="bar"></span>
                            <label className='labelRegistro' >Nombre Completo</label>
                        </div>
                        
                        <div className="group">
                            <input  className='inputRegistro' type="number" required="required"/><span className="highlight"></span><span className="bar"></span>
                            <label className='labelRegistro' >DNI</label>
                        </div>

                        
                        <div className="group">
                            <input  className='inputRegistro' type="number" required="required"/><span className="highlight"></span><span className="bar"></span>
                            <label className='labelRegistro' >Celular</label>
                        </div>


                        <div className="group">
                            <textarea className='textareaRegistro' type="textarea" rows="5" required="required"></textarea><span className="highlight"></span><span className="bar"></span>
                            <label className='labelRegistro' >Dirección</label>
                        </div>
                    </form>
                    </div>
            {/* </div> */}

            </div>

            </Modal>

            {/* MODAL DE LISTADO DE CLIENTES */}
            <Modal 
                isOpen={isOpenModalListado} closeModal={closeModalListado} 
                action={() => guardarEditarPersona()}
                title="Seleccione un cliente"
                // textButtons = {{confirm: 'Seleccionar', denied:"Cerrar"}}
                // validButton = {{confirm: true, denied:true}}       
                textButtons = {{denied: 'Cerrar'}}
                validButton = {{denied: true}}      
            >
            <div className='lg:w-[320px] ' >
            <div className='mb-2'>
                <SearchTable tablaId={idTablaClientes}></SearchTable>
            </div>
            <div className="containerScroll  relative contenedor-tabla max-h-[500px] lg:max-h-auto">
                    <table id={idTablaClientes} className="tableResponsive " >
                        <thead className=''>
                            <tr >
                                {/* <th className='w-[30px]'>N°</th> */}
                                <th className='w-[80px] lg:invisible' >Foto</th>
                                <th >Nombre</th>
                                <th>DNI</th>
                                <th>Celular</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {clientes.map((cliente) => (

                                <tr key={cliente.Id}  style={{textAlign:"center",alignContent:"center",alignItems:"center"}}>
                                    {/* <td data-th="Nombre">{cliente.Nombre}</td> */}
                                    <td data-th="Foto" className='tableFotoCliente'>
                                        <img src={cliente.Foto} alt={cliente.Foto} className="lg:ml-0 rounded-full w-[60px] h-[60px] lg:w-[120px] lg:h-[120px]"/>
                                    </td>
                                    <td data-th="Nombre">{cliente.Nombre}</td>
                                    <td data-th="Descripcion"><i className='lg:visible invisible fa fa-address-card-o p-1'></i>{cliente.DNI}</td>
                                    <td data-th="Celular" className=""><i className='lg:visible invisible fa fa-mobile p-1'></i>{cliente.Celular}</td>
                                    <td data-th="Opciones">
                                        <button  onClick={()=> personaEliminar(persona.codPersona)} className="hover:text-green-300 text-green-500 text-xl p-1 rounded ml-3">
                                            <i className="fa fa-check"></i>
                                        </button>
                                    </td>
                                </tr>

                                
                            
                            ))}

                        </tbody>
                    </table>
            </div>
        </div>
            </Modal>


        {/* MODAL DE LISTADO DE PRODUCTOS */}
            <Modal 
                isOpen={isOpenModalListadoProductos} closeModal={closeModalListadoProductos} 
                action={() => guardarEditarPersona()}
                title="Seleccione sus Productos"
                // textButtons = {{confirm: 'Seleccionar', denied:"Cerrar"}}
                // validButton = {{confirm: true, denied:true}}       
                textButtons = {{denied: 'Cerrar'}}
                validButton = {{denied: true}}      
            >
            <div className='lg:w-[320px] ' >
            <div className='mb-2'>
                <SearchTable tablaId={idTablaProductos}></SearchTable>
            </div>
            <div className="containerScroll  relative contenedor-tabla max-h-[500px] lg:max-h-auto">
            <table id={idTablaProductos} className="tableResponsive " >
                        <thead className=''>
                            <tr >
                                <th>Foto</th>
                                <th>Nombre</th>
                                <th>Descripcion</th>
                                <th>Stock</th>
                                <th>Precio</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {productos.map((product) => (

                                <tr key={product.id}  style={{textAlign:"center",alignContent:"center",alignItems:"center"}}>
                                    <td  data-th="Foto" className='tableFoto center'>
                                        <img src={product.imageSrc} alt={product.imageAlt} className="rounded-full w-[60px] h-[60px] lg:w-[120px] lg:h-[120px]"/>
                                    </td>
                                    <td data-th="Nombre">{product.name}</td>
                                    <td data-th="Descripcion">{product.imageAlt}</td>
                                    <td data-th="Stock">500</td>
                                    <td data-th="PrecioVenta">{product.price}</td>
                                    <td data-th="Opciones">
                                        <div className='justify  text-center  align-middle '>

                                            {/* <button onClick={() => obtenerPersona(persona.codPersona)} className="align-middle  border hover:text-blue-300 text-blue-500 text-2xl p-1 rounded">
                                                <i className="fas fa-minus"></i>
                                            </button> */}
                                            <input type='number' defaultValue={1} className='  caret-black  border w-[60px] h-[30px] text-gray-500  text-center  rounded' placeholder=''/>
                                            {/* <button onClick={() => obtenerPersona(persona.codPersona)} className=" align-middle  border  hover:text-blue-300 text-blue-500 text-2xl p-1 rounded">
                                                <i className="fas fa-plus"></i>
                                            </button> */}
                                            <button onClick={() => obtenerPersona(persona.codPersona)} className=" ml-2  hover:text-green-300 text-green-500 text-2xl p-1 rounded">
                                                <i className="fas fa-check"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>

                                
                            
                            ))}

                        </tbody>
                    </table>
            </div>
        </div>
            </Modal>



        </>
    )

}