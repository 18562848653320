import React, { useEffect, useState, useRef } from 'react';
import { BotonNuevo } from '../components/buttons/BotonNuevo';
import { ContenedorParametros } from '../components/utils/ContenedorParametros';
import { SearchTable } from '../components/utils/SearchTable';
import { Modal } from '../components/modal/Modal';
import fotoDefault from '../../assets/images/clienteDefault.jpg';
import { useClientes } from '../../hooks/useClientes';
import { soloCelular, soloDNI } from '../../utils/utils';
// import { BotonNuevo } from '../components/buttons/BotonNuevo';



export const Proveedores = () => {
    const { cliente, listarClientes, guardarClienteConFoto,clientes, editarValorCliente, obtenerCliente,
        isOpenModal, closeModal, fotoUrlCliente,setFotoFile,setFotoUrlCliente,eliminarCliente, isOpenModalEliminar, 
        closeModalEliminar, clienteEliminar,cargarDatosCliente } = useClientes();
    

    const idTablaProductos = "tablaProductos"
    const [selectedFile, setSelectedFile] = useState()
    const inputFoto = useRef();

    useEffect(() => {
        if (!selectedFile) {
            setFotoUrlCliente(fotoDefault)
            // setSelectedFile(null)
            return
        }
        const objectUrl = URL.createObjectURL(selectedFile)
        setFotoUrlCliente(objectUrl || fotoDefault)
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])

    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(fotoDefault)
            return
        }
        setSelectedFile(e.target.files[0])
        setFotoFile(e.target.files[0])
    }

    return (
        <>

        <ContenedorParametros>

        
        <BotonNuevo onClick={() => obtenerCliente()}></BotonNuevo>
        <SearchTable tablaId={idTablaProductos}></SearchTable>
        </ContenedorParametros>


        <div className="containerScroll relative contenedor-tabla ">

                    <table id={idTablaProductos} className="tableResponsive " >
                        <thead className=''>
                            <tr >
                                <th></th>
                                <th>Nombres</th>
                                {/* <th>Apellidos</th> */}
                                <th>Documento</th>
                                {/* <th>Número Documento</th> */}
                                <th>Celular</th>
                                <th>Correo</th>
                                <th>Direccion</th>
                                <th>Estado</th>
                                <th></th>
                            </tr>

                        </thead>
                        <tbody>
                            {
                                clientes.length > 0 ?
                                clientes.map((cliente) => (

                                <tr key={cliente.CodCliente}  style={{textAlign:"center",alignContent:"center",alignItems:"center"}}>
                                    <td data-th="Foto" className='tableFotoCliente'>
                                        <img src={cliente.Foto} alt={cliente.Foto} className="lg:ml-[-5px] lg:mt-6 ml-[20px] rounded-full w-[60px] h-[60px] lg:w-[150px] lg:h-[150px]"/>
                                    </td>
                                    <td data-th="Nombre" className='tdNombrePersona'>{cliente.Nombres} <br/>{cliente.ApellidoPaterno} {cliente.ApellidoMaterno}</td>
                                    {/* <td data-th="Apellidos">{cliente.ApellidoPaterno} {cliente.ApellidoMaterno}</td> */}
                                    <td data-th="TipoDocumento" className='  '>
                                        <span className=' invisible lg:visible mr-2 fa fa-id-card-o'></span>
                                        <span className='text-center'>{cliente.TipoDocumento == 1?'DNI': 'CE'}</span><br/>
                                        <span >{cliente.NumeroDocumento}</span></td>
                                    {/* <td data-th="NumeroDocumento">{cliente.NumeroDocumento}</td> */}
                                    <td data-th="Celular"><span className='invisible lg:visible mr-2 fa fa-mobile'></span>{cliente.Celular}</td>
                                    <td data-th="Correo"><span className='invisible lg:visible mr-2 fa fa-envelope-o'></span>{cliente.Correo}</td>
                                    <td data-th="Direcion"><span className='invisible lg:visible mr-2 fa fa-map-marker'></span>{cliente.Direccion}</td>
                                    <td data-th="Estado" ><span className={`cursor-pointer text-white bg-green-600 px-2 py-[2px] rounded uppercase text-[16px] font-bold `}>{cliente.Estado}</span></td>
                                    <td data-th="Opciones">
                                        <button  onClick={{}} className="hover:text-purple-300 text-purple-500 text-xl px-[5px] py-1 rounded ml-3">
                                            <i className="fa fa-credit-card-alt"></i>
                                        </button>
                                        <button  onClick={{}} className="hover:text-yellow-300 text-yellow-500 text-xl px-[5px] py-1 rounded ml-3">
                                            <i className="fas fa-bars"></i>
                                        </button>
                                        <button onClick={() => obtenerCliente(cliente.CodCliente)} className="hover:text-green-300 text-green-500 text-xl px-[5px] py-1 rounded ml-3">
                                            <i className="fa fa-pencil"></i>
                                        </button>
                                        <button  onClick={()=> clienteEliminar(cliente.CodCliente)} className="hover:text-red-300 text-red-500 text-xl px-[5px] py-1 rounded ml-3">
                                            <i className="fa fa-trash-o"></i>
                                        </button>

                                    </td>
                                </tr>

                                
                            
                            ))
                            :<tr ><td colSpan='14' className='text-center'>No hay informacion para mostrar con los parametros seleccionados</td></tr>
                            
                            }


                        </tbody>
                    </table>
            </div>



            <Modal 
                isOpen={isOpenModal} closeModal={closeModal} 
                action={() => guardarClienteConFoto()}
                title="Registrar Cliente"
            >
            <div className='h-[500px]  lg:h-[500px] '>
                <div className="flex  items-center  justify-center">
                    
                    <div style={{textAlign:'center'}} className="">
                        <label htmlFor="file-1">
                            <img className=' rounded-xl w-[200px] h-[200px] mb-1 lg:w-[150px] lg:h-[150px] cursor-pointer' src={fotoUrlCliente} alt=''  />
                            <input ref={inputFoto} type="file" name="file-1" id="file-1" className="inputfile inputfile-1" accept='.jpg,.png'  onChange={onSelectFile} />
                                <i className="fa fa-image iborrainputfile text-white text-[18px]"></i> 
                                <span className="iborrainputfile"> Seleccione</span>
                        </label>
                    </div>
                </div>

                <div className="wrapper">
                    <form className='form'> 
                        
                        <div className="mt-5 group">
                            <div>
                                <input className='inputRegistro' type="number" required="required" defaultValue={cliente.NumeroDocumento} onChange={(e) => editarValorCliente("NumeroDocumento", e.target.value)} onKeyPress={(e) => soloDNI(e, e.target)}/>
                                    <span className="highlight"></span><span className="bar"></span>
                                <label className='labelRegistro'>DNI</label>
                                
                                <button className="boton-iconBusqueda infoBusqueda rounded bg-[#f6682f] hover:bg-[#f6685f] text-white tooltipstered  " onClick={()=>cargarDatosCliente()} >
                                    <i className="fa fa-search" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>

                        <div className="mt-5 group">
                            <input className='inputRegistro' type="text" required="required" defaultValue={cliente.Nombres} onChange={(e) => editarValorCliente("Nombres", e.target.value)}/>
                            <span className="highlight"></span><span className="bar"></span>
                            <label className='labelRegistro' >Nombres</label>
                        </div>
                        <div className="mt-5 group">
                            <input className='inputRegistro' type="text" required="required" defaultValue={cliente.ApellidoPaterno} onChange={(e) => editarValorCliente("ApellidoPaterno", e.target.value)}/>
                            <span className="highlight"></span><span className="bar"></span>
                            <label className='labelRegistro' >Apellido Paterno</label>
                        </div>
                        <div className="mt-5 group">
                            <input className='inputRegistro' type="text" required="required" defaultValue={cliente.ApellidoMaterno} onChange={(e) => editarValorCliente("ApellidoMaterno", e.target.value)}/>
                            <span className="highlight"></span><span className="bar"></span>
                            <label className='labelRegistro' >Apellido Materno</label>
                        </div>
                        
                        <div className="mt-5 group">
                            <input  className='inputRegistro' type="number" required="required" defaultValue={cliente.Celular} onChange={(e) => editarValorCliente("Celular", e.target.value)} onKeyPress={(e) => soloCelular(e, e.target)}/>
                            <span className="highlight"></span><span className="bar"></span>
                            <label className='labelRegistro' >Celular</label>
                        </div>
                        <div className="mt-5 group">
                            <input  className='inputRegistro' type="text" required="required" defaultValue={cliente.Correo} onChange={(e) => editarValorCliente("Correo", e.target.value)} />
                            <span className="highlight"></span><span className="bar"></span>
                            <label className='labelRegistro' >Correo</label>
                        </div>

                        <div className="mt-5 group">
                            <textarea className='textareaRegistro' type="textarea" rows="5" required="required" defaultValue={cliente.Direccion} onChange={(e) => editarValorCliente("Direccion", e.target.value)}></textarea>
                            <span className="highlight"></span><span className="bar"></span>
                            <label className='labelRegistro' >Dirección</label>
                        </div>

                    </form>
                    </div>

            {/* </div> */}

            </div>

            </Modal>
        </>
    )

}