import { useState, useEffect,useContext } from 'react';
import { notify } from '../utils/utils';
import { useModal } from './useModal';
import fotoDefault from '../assets/images/productoDefault.jpg';
import { AuthFetch, Fetch } from '../services/api';
import { UserContext } from '../context/provider/UserProvider';

const servicioDefault = {
    CodServicio: 0,
    Descripcion: "",
    PrecioVenta: 0,
    Estado: "",
    Categoria: "",
}

export const useServicios = () => {
    const { stateUser, signOut } = useContext(UserContext)

    const [ servicios, setServicios] = useState([]);
    const [ servicio, setServicio ] = useState(servicioDefault);
    const [ isOpenModal, openModal, closeModal ] = useModal();
    const [ isOpenModalEliminar, openModalEliminar, closeModalEliminar ] = useModal();

    const [ isOpenModalListado, openModalListado, closeModalListado ] = useModal();
    const [ isOpenModalListadoProductos, openModalListadoProductos, closeModalListadoProductos ] = useModal();

    // const [ fotoFile, setFotoFile ] = useState(null); 
    // const [ fotoUrlProducto, setFotoUrlProducto ] = useState(fotoDefault);
    const URLEMPRESA = stateUser.link


    useEffect(() => {
        listarServicios();
    }, []);

    const listarServicios = async () => {
        const response = await Fetch({
            url: URLEMPRESA + '/servicios'
        });
        if (response.isValid) {
            setServicios(response.content);
        } else {
            notify(response.content, 'error');
        }
    }

    const obtenerServicio = async (codServicio) => {
        if (codServicio) {
            const response = await Fetch({
                url: URLEMPRESA + `/servicios/${codServicio}`
            });
            if (response.isValid) {
                setServicio(response.content);
                // setFotoUrlPro(response.content.Foto)
            } else {
                notify(response.content, 'error');
            }
        } else {
            setServicio(servicioDefault);
            // setFotoUrlProducto(fotoDefault)
        }
        openModal();
    }

    const servicioEliminar = async (codServicio) =>{
        if (codServicio) {
            const response = await Fetch({
                url:URLEMPRESA + `/servicios/${codServicio}`
            });

            if (response.isValid) {
                setServicio(response.content);
                // setFotoUrlProducto(response.content.Foto)
            } else {
                notify(response.content, 'error');
            }
        } else {
            setServicio(servicioDefault);
            // setFotoUrlProducto(fotoDefault)
        }
        openModalEliminar();
    }

    const eliminarServicio = async (codServicio)=>{
        const response = await Fetch({
            url: URLEMPRESA + `/servicios/${codServicio}`,
            method:'DELETE'
        })
        notify(response.content, response.isValid? 'success': 'error' )
        if (response.isValid) {
            listarServicios()
        }
        closeModalEliminar()
    }

    // const guardarProductoConFoto = async () => {
    //     if (fotoFile != null){
    //         const fotoData = new FormData();
    //         fotoData.append('archivo', fotoFile);
    //         console.log("Nombre del producto -> ", producto)
    //         const response = await Fetch({
    //             url:process.env.REACT_APP_PROMETHEUS_MINIO + '/api/Archivo/subirArchivo?' + new URLSearchParams({
    //                 NombreCarpeta:"pruebas",
    //                 NombreArchivo: `/productos/${producto.Nombre}`,
    //             }),
    //             headers:{
    //                 contentType: 'image/*'
    //                 // 'Content-Type':'image/*'
    //             },
    //             method:'POST',
    //             body : fotoData
    //         });
    //         console.log("RESPONSE FOTO -> ", response)
    //         if (response.isValid) {
    //             setFotoFile(null)
    //             producto.Foto=`https://minioapi.prometheusperu.com/api/Archivo/verImagen?NombreCarpeta=pruebas&NombreImagen=/productos/${producto.Nombre}`
    //             guardarEditarProducto()
    //         }else{
    //             notify(response.exceptions[0].description, 'error');
    //         }
    //     }else{
    //         guardarEditarProducto()
    //     }
    // }
    
    const guardarEditarServicio = async () => {
        const esGuardar = servicio.CodServicio <= 0;
        const response = await Fetch({
            url: URLEMPRESA + '/servicios',
            method: esGuardar ? 'POST' : 'PUT',
            body: JSON.stringify({
                ...(!esGuardar && { CodServicio: servicio.CodServicio }),
                Descripcion: servicio.Descripcion,
                PrecioVenta:servicio.PrecioVenta,
                Estado: servicio.Estado,
                Categoria:servicio.Categoria,
            })
        });

        if (response.isValid) {
            setServicio(servicioDefault);
            await listarServicios();
            closeModal()
            notify(response.content, response.isValid ? 'success' : 'error');
        }
    }

    const editarValorServicio = (key, value) => {
        setServicio(servicio => {
            return {
                ...servicio,
                [key]: value
            }
        });
    }

    return { servicio, listarServicios,servicios, editarValorServicio, obtenerServicio,
            isOpenModal, closeModal, eliminarServicio, isOpenModalEliminar, 
            closeModalEliminar, servicioEliminar ,guardarEditarServicio}

}