import React, { useEffect, useState, useRef } from 'react';


export const RegistroCompra = () => {
    const [errorMessage, setErrorMessage] = useState('');

    const handleAuthenticate = async () => {
      try {
        const credentials = await navigator.credentials.get({
          publicKey: {
            // Configuración de la solicitud de autenticación
            // Puedes personalizar esto según tus necesidades
            challenge: new Uint8Array([/* some random bytes */]),
            userVerification: 'required',
            authenticatorSelection: {
              requireResidentKey: false,
              userVerification: 'preferred',
            },
            attestation: 'direct',
          },
        });
  
        // Aquí puedes manejar el resultado de la autenticación
        console.log('Credenciales:', credentials);
      } catch (error) {
        console.error('Error en la autenticación:', error);
        setErrorMessage('Error en la autenticación. Intenta nuevamente.');
      }
    };

    return (
        <>          


            <div>
                <button onClick={handleAuthenticate}>Autenticar con Huella Digital</button>
                {errorMessage && <p>{errorMessage}</p>}
            </div>

        </>
    )

}