import React,{useRef, useState} from "react";

import { Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterIcon from '@mui/icons-material/Filter';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';



export const SearchTable = ({ tablaId }) => {
    const [ verInput, setVerInput ] = useState() 
    const getByFilter = async (value) =>{
        const tabla = document.querySelector('#' + tablaId);
        const rows = tabla.children[1].children;
        const expresion = new RegExp(`${value}.*`, "i")

        for (let i = 0; i < rows.length; i++) {
            const cols = rows[i].children;
            let ocultar = true;

            for(let j = 0; j < cols.length; j++) {
                const value = cols[j].innerHTML;
                
                const booool = expresion.test(value);
                if (booool) {
                    ocultar = false;
                    break;
                }
            }
            rows[i].style.display = ocultar ? "none" : "";
        }
    }
    const  btnBuscar = useRef() 
    const inputBuscar = useRef()
    // const divPrueba = useRef()

    const mostrarInput = () => {
        console.log("MOSTRAR INPUT")
        verInput ? setVerInput(false) : setVerInput(true);
    }
    // const [inputBuscar2, setInputBuscar2] = useState('');

    

    return (
    <>
        <div className="ml-3 lg:ml-0 " >
            <div className="input-wrapper " > 
                <i 
                    className={`${verInput? ' bg-green-800 text-white h-9 w-9 rounded pt-2 px-2 ' : '  '} lg:hidden z-50 top-8 left-[120px] absolute fa fa-search input-iconBusqueda`} aria-hidden="true"
                    onClick={mostrarInput} 
                ></i>
                <input href={inputBuscar} onChange={e => getByFilter(e.target.value)} 
                    className={ `${verInput? 'hidden' : ''} pl-8 caret-black inputBusqueda h-10 px-4 w-[400px] lg:w-auto text-[13px] focus:outline-none focus:ring-2 hover:ring-2 hover:ring-[#f6682f] focus:ring-[#f6682f] hover:ring-[#f6682f] rounded `} 
                    type="text" placeholder="Búsqueda" autoComplete="off" autoFocus={ false }
                />    
                <button 
                    href={btnBuscar} 
                    onClick={mostrarInput} 
                    className={`${verInput? 'hidden' : ''} absolute rounded hover:bg-[#f6682f] bg-gray-500 hover:text-white h-10 text-xl ml-[-35px]  px-2  `}
                >
                    <i className="fa fa-filter" aria-hidden="true"></i>
                </button>


            </div>
        </div>
    </>
    );
}


// import React from 'react';
// import InputAdornment from '@mui/material/InputAdornment';
// import InputText from './InputText'; // Importa tu componente InputText
// import TextField from '@mui/material/TextField';
// import SearchIcon from '@mui/icons-material/Search';

// const TuComponente = ({ cliente, editarValorCliente, soloDNI }) => {
//     return (
//         <TextField
//             className="w-[250px]"
//             id="numeroDocumento"
//             label="Numero de Documento"
//             defaultValue={cliente.NumeroDocumento}
//             onChange={(e) => editarValorCliente("NumeroDocumento", e.target.value)}
//             onKeyPress={(e) => soloDNI(e, e.target)}
//             InputProps={{
//                 endAdornment: (
//                     <InputAdornment position="end">
//                         <SearchIcon color="primary" />
//                     </InputAdornment>
//                 ),
//             }}
//         />
//     );
// }

// export default TuComponente;
