import { useState, useEffect,useContext } from 'react';
import { notify } from '../utils/utils';
import { useModal } from './useModal';
import { AuthFetch, Fetch } from '../services/api';
import { UserContext } from '../context/provider/UserProvider';
// import { Caja } from '../app/pages/Ventas/Caja';
import dayjs from 'dayjs';

const cajaDefault = {
    CodCaja: 0,
    FechaApertura: "",
    FechaCierre: "",
    UsuarioCreacion : "",
    CodUsuarioCreacion: 0,
    Estado: ""
}


export const useCajas = () => {
    const { stateUser, signOut } = useContext(UserContext)

    const [ cajas, setCajas] = useState([]);
    const [ caja, setCaja ] = useState(cajaDefault);
    const [ isOpenModal, openModal, closeModal ] = useModal();
    const [ isOpenModalEliminar, openModalEliminar, closeModalEliminar ] = useModal();

    const urlCaja = process.env.REACT_APP_PROMETHEUS_API + '/cajas'
    // const urlCaja = stateUser.link 

    // const urlCaja = 'http://localhost:8000' 


    useEffect(() => {
        listarCajas();
    }, []);

    const listarCajas = async () => {
        const response = await AuthFetch({
            url: urlCaja + '?'+ new URLSearchParams({
                cod_empresa : stateUser.codEmpresa
            })
        });
        if (response.isValid) {
            setCajas(response.content);
        } else {
            notify(response.content, 'error');
        }
    }

    const obtenerCaja = async (codCaja) => {
        console.log("obtener caja  -> ",codCaja)
        
        if (codCaja) {
            const response = await AuthFetch({
                url: urlCaja + '/ObtenerCaja?'+ new URLSearchParams({
                    cod_empresa : stateUser.codEmpresa,
                    cod_caja : codCaja
                })
            });
            console.log("CAJA OBTENIDA -> ",response)
            if (response.isValid) {
                setCaja(response.content);
            } else {
                notify(response.content, 'error');
            }
        } else {
            setCaja(cajaDefault);
        }
        openModal();
    }

    const cajaEliminar = async (codCaja) =>{
        if (codCaja) {
            const response = await Fetch({
                url:urlCaja + `/cajas/${codCaja}`
            });

            if (response.isValid) {
                setCaja(response.content);
            } else {
                notify(response.content, 'error');
            }
        } else {
            setCaja(cajaDefault);
        }
        openModalEliminar();
    }

    const eliminarCaja = async (codCaja)=>{
        console.log("CodCaja -> ", codCaja)
        const response = await Fetch({
            url: urlCaja + `/${codCaja}`,
            method:'DELETE'
        })
        notify(response.content, response.isValid? 'success': 'error' )
        if (response.isValid) {
            listarCajas()
        }
        closeModalEliminar()
    }

    const guardarEditarCaja = async () => {

        console.log("FECHA DE APERTURA ->", caja.FechaApertura)
        const formattedDate = dayjs(caja.FechaApertura).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

        console.log("formattedDate -> ",formattedDate);

        const esGuardar = caja.CodCaja <= 0;
        const estadoCaja = caja.CodCaja <= 0? "Aperturada" : "Cerrada"

        const cajaAntes = {

            CodCaja: caja.CodCaja,
            FechaApertura:caja.CodCaja? caja.FechaApertura : formattedDate,
            FechaCierre: formattedDate,
            UsuarioCreacion : stateUser.NomUsuario,
            CodUsuarioCreacion: stateUser.CodUsuario,
            Estado: caja.CodCaja <= 0? "Aperturada" : "Cerrada",
            cod_empresa : stateUser.codEmpresa
        }
        
        console.log("CAJA EN GUARDAR EDITAR CAJA ->", cajaAntes)

        const response = await AuthFetch({ url: urlCaja ,
            method: esGuardar ? 'POST' : 'PUT',
            body: JSON.stringify({
                CodCaja: caja.CodCaja,
                FechaApertura:caja.CodCaja? caja.FechaApertura : formattedDate,
                FechaCierre: formattedDate,
                UsuarioCreacion : stateUser.nomUsuario,
                CodUsuarioCreacion: stateUser.codUsuario,
                Estado: estadoCaja,
                codEmpresa: stateUser.codEmpresa

            })
        });
        console.log("RESPONSE DEL GUARDAR EDITAR CAJA -> ", response)
        if (response.isValid) {
            setCaja(cajaDefault);
            await listarCajas();
            closeModal()
            notify(response.content, response.isValid ? 'success' : 'error');
        }
    }

    const editarValorCaja = (key, value) => {
        console.log("CAJA EDITARVALOR  KEY - VALUE -> ", key, value)
        setCaja(caja => {
            return {
                ...caja,
                [key]: value
            }
        });
    }

    return { caja, listarCajas, cajas, editarValorCaja, obtenerCaja,
            isOpenModal, closeModal,eliminarCaja, isOpenModalEliminar, 
            closeModalEliminar, cajaEliminar,guardarEditarCaja }

}