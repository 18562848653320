import React from 'react';
import { PDFDownloadLink, Document, Page, Text, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Helvetica',
    backgroundColor: '#EAEAEA',
    padding: 20,
  },
  title: {
    fontSize: 24,
    marginBottom: 20,
    textAlign: 'center',
  },
  subtitle: {
    fontSize: 18,
    marginBottom: 10,
    textAlign: 'center',
  },
  productContainer: {
    marginBottom: 10,
    padding: 10,
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
  },
  productName: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  productPrice: {
    fontSize: 12,
    marginBottom: 5,
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 30,
    right: 30,
    textAlign: 'center',
    fontSize: 10,
    color: 'gray',
  },
});

const PDFExporter = ({ products, fileName }) => {
  console.log("product->",products)
  const MyDocument = () => (
    <Document>
      <Page style={styles.page}>
        <Text style={styles.title}>Voucher</Text>
        <Text style={styles.subtitle}>Detalles de la compra</Text>
        {products.map((product, index) => (
          <ProductItem key={index} product={product} />
        ))}
        <Text style={styles.footer}>Este es un voucher de ejemplo</Text>
      </Page>
    </Document>
  );

  const ProductItem = ({ product }) => (
    <div style={styles.productContainer}>
      <Text style={styles.productName}>{product.nombre}</Text>
      <Text style={styles.productPrice}>{product.Precio}</Text>
    </div>
  );

  return (
    <PDFDownloadLink document={<MyDocument />} fileName={`${fileName}.pdf`}>
      {({ loading }) => (loading ? 'Generando el PDF...' : 'Descargar PDF')}
    </PDFDownloadLink>
  );
};

export default PDFExporter;