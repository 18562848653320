import React, { useRef, useEffect, useContext } from 'react';
import { NavLink,useHistory } from "react-router-dom";
import $ from 'jquery';
import { UserContext } from '../../../context/provider/UserProvider';

export const Menu = React.forwardRef(({isOpen, openMenu, closeMenu, toggleContentMenu}, ref) => {    
    const { stateUser } = useContext(UserContext)
    const splitRef = useRef();

    useEffect(() => {
        
        const li = document.getElementsByClassName("li-nav-div")
        for (var i = 0; i < li.length; i++) {
            const linkSubMenu = li[i].parentNode.querySelectorAll('.li-a-submenu')
            
            let up = true

            for (var j = 0; j < linkSubMenu.length; j++) {
                const ulSubMenu = linkSubMenu[j].parentNode.parentNode.parentNode
    
                if (linkSubMenu[j].classList.contains("active")) {
                    const liNavDiv = ulSubMenu.parentNode;
                    liNavDiv.querySelector('.li-nav-div').children[0].classList.add("active")
                    up = false
                }
            }

            if(up) $(li[i]).parent().children('ul').slideUp();
        }

    })
    const cerrarMenu = () =>{
        toggleContentMenu(false)
        closeMenu()
    }
    // const cerrarMenu =()=>{

    //     ()=>closeMenu()()
    // }
    // MENU PADRE
    const toggleMenu = (e) => {
        let element = e.target
        while (!element.classList.contains('li-nav-div')) {
            element = element.parentNode
        }
        const menuParents = $('.li-nav-div')
        for (let i = 0; i < menuParents.length; i++) {
            const menuParent = menuParents[i]
            if (element != menuParent) {
                menuParent.children[0].classList.remove('active')
                $(menuParent.parentNode.children[1]).slideUp()
            }
        }
        if (element.children[0].classList.contains('active')) {
            $(element.parentNode.querySelector('.ul-submenu')).slideUp();
        } else {
            $(element.parentNode.querySelector('.ul-submenu')).slideDown();
        }

        element.children[0].classList.toggle('active')
    }

    return (
        <>
            <div className={`flex menu lg:absolute relative h-full  ${isOpen ? 'active' : ''}`}>
                {/* <ul ref={ref} className="ul-nav lg:py-[20px] py-0 bg-[#272727]">
                    <li id="li" key={1} className="waves-effect ul-nav-li active-li">
                        <div className="li-nav-div" onClick={(e) => toggleMenu(e)}>
                            <a className="nav-link li-a">
                                <div className="w-full flex justify-between">
                                    <span className="text-menu">NOMMENU</span>
                                    <i className="icon-menu fas fa-sort-down"></i>
                                </div>
                            </a>
                        </div>
                        <ul className="ul-submenu" style={{padding: 0}}>
                            <li key={11} id="li-submenu" className="waves-effect ul-nav-li-submenu">
                                <div className="li-nav-div-submenu">
                                    <NavLink to='/dashboard' className="nav-link li-a-submenu">
                                        <span className="text-sub-menu">nommenuhijo</span>
                                    </NavLink>
                                </div>
                            </li>
                        </ul>
                    </li>  
                </ul> */}


            
                <ul ref={ref} className="ul-nav lg:py-[25px] py-1 ">
                    <li id="li" className="waves-effect ul-nav-li active-li">
                        <div className="li-nav-div" onBlur={(e) => toggleMenu(e)} onClick={(e) => toggleMenu(e)}>
                            <a className="nav-link li-a ">
                                <div className="w-full flex justify-between">
                                    <span className="text-menu "> <i className='fa fa-tachometer ' style={{fontSize:'20px',marginRight:'15px'}}/>Inicio</span>
                                </div>                                
                            </a>
                        </div>
                        <ul className="ul-submenu" style={{padding: 0}}>
                            <li key={1} id="li-submenu" className="waves-effect ul-nav-li-submenu">
                                <div className="li-nav-div-submenu">
                                    <NavLink to='/dashboard' className="nav-link li-a-submenu" onClick={()=>cerrarMenu()}>
                                        <span className="text-sub-menu"> <i className=' fa fa-firse' /> Principal</span>
                                    </NavLink>
                                </div>
                            </li>
                        </ul>
                    </li>  

                    <li id="li" className="waves-effect ul-nav-li active-li">
                        <div className="li-nav-div" onClick={(e) => toggleMenu(e)}>
                            <a className="nav-link li-a">
                                <div className="w-full flex justify-between">
                                    {/* <NavLink to={'/ventas'} className="nav-link li-a-submenu"> */}
                                        <span className="  text-menu"> <i className='fa fa-file' style={{fontSize:'20px',marginRight:'15px'}}/>Registros</span>
                                    {/* </NavLink> */}
                                </div>
                            </a>
                        </div>
                        <ul className="ul-submenu" style={{padding: 0}}>
                            <li key={2} id="li-submenu" className="waves-effect ul-nav-li-submenu">
                                <div className="li-nav-div-submenu">
                                    <NavLink to='/caja' className="nav-link li-a-submenu"  onClick={()=>cerrarMenu()}>
                                        <span className="text-sub-menu"><i className=' fa fa-fisre'/> Guía Transportista </span>
                                    </NavLink>
                                </div>
                            </li>


                            {/* <li key={3} id="li-submenu" className="waves-effect ul-nav-li-submenu">
                                <div className="li-nav-div-submenu">
                                    <NavLink to='/registro-ventas' className="nav-link li-a-submenu"  onClick={()=>cerrarMenu()}>
                                        <span className="text-sub-menu"><i className=' fa fa-fisre'/>  Registrar Venta</span>
                                    </NavLink>
                                </div>
                            </li> */}
                      
                            {/* <li key={4} id="li-submenu" className="waves-effect ul-nav-li-submenu">
                                <div className="li-nav-div-submenu">
                                    <NavLink to='/lista-ventas' className="nav-link li-a-submenu" onClick={()=>cerrarMenu()}>
                                        <span className="text-sub-menu"><i className=' fa fa-fires'/> Lista Ventas</span>
                                    </NavLink>
                                </div>
                            </li> */}
                        </ul>

                    </li>


                  
                </ul>

            </div>
        </>

    )
})