import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { UserContext } from '../../context/provider/UserProvider';
import { SocketContext } from '../../context/provider/SocketProvider';
import { notify } from '../../utils/utils';
import logo from '../../assets/images/logoPrometheusInicio.fw.png';
import { BotonNeon } from '../components/buttons/BotonNeon';
import { Loading } from '../components/Loading';
import { TextField, Button, IconButton, InputAdornment, Container, Typography, Box, Card, CardContent, CircularProgress, Grid, Avatar } from '@mui/material';
import { Visibility, VisibilityOff, LockOutlined } from '@mui/icons-material';
// import BackgroundImage from '../../assets/images/background.jpg'; // Asegúrate de agregar tu propia imagen de fondo
import BackgroundImage from '../../assets/images/productoDefault.jpg'

export const iniciarSesion = async (user, password, signIn) => {
    const response = await fetch(process.env.REACT_APP_PROMETHEUS_API + '/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            Usuario: user,
            Clave: password
        })
    }).then(res => res.json());

    console.log("RESPONSE-> ", response);
    if (response.isValid) {
        signIn(response.content);
        return true;
    }
    return false;
}

const useLogin = () => {
    const inputUsuario = useRef();
    const history = useHistory();
    const { stateUser, signIn } = useContext(UserContext);
    const { mqttConnect } = useContext(SocketContext);
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [mostrarLoading, setMostrarLoading] = useState(false);

    useEffect(() => {
        const isLogged = stateUser.token !== '';
        if (isLogged) history.push('/dashboard');
        inputUsuario.current.focus();
    }, [history, stateUser.token]);

    const ingresar = async (e) => {
        setMostrarLoading(true);
        e.preventDefault();
        const ingreso = await iniciarSesion(user, password, signIn);
        if (ingreso) {
            mqttConnect();
            history.push('/dashboard');

            if (Notification.permission === "default") {
                await Notification.requestPermission();
            }

            if (Notification.permission === "blocked") {
                alert("Notificaciones bloqueadas");
            }

            if (Notification.permission !== "granted") {
                return;
            }
        } else {
            notify('Usuario o contraseña incorrectos', 'error');
        }
        setMostrarLoading(false);
    }

    return {
        user,
        setUser,
        password,
        setPassword,
        ingresar,
        inputUsuario,
        stateUser,
        mostrarLoading
    }
}

export const Login = () => {
    const login = useLogin();
    const [mostrarContrasena, setMostrarContrasena] = useState(false);

    return (
        <Box 
            sx={{ 
                minHeight: '100vh', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                backgroundImage: `url(https://www.dora.pe/hubfs/Beneficios%20de%20un%20software%20contable%20empresarial.jpg)`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                p: 2
            }}
        >
            <Container maxWidth="xs" sx={{ 
                backgroundColor: 'rgba(255, 255, 255, 0.85)', 
                borderRadius: 4, 
                boxShadow: 5, 
                py: 4, 
                px: 3, 
                position: 'relative' 
            }}>
                {login.mostrarLoading && <CircularProgress sx={{ position: 'absolute', top: '10px', right: '10px' }} />}
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
                    <Avatar sx={{ m: 1, bgcolor: 'primary.main', width: 56, height: 56 }}>
                        <LockOutlined />
                    </Avatar>
                    <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
                        Iniciar Sesión
                    </Typography>
                </Box>
                <form onSubmit={login.ingresar}>
                    <TextField
                        inputRef={login.inputUsuario}
                        onChange={(e) => login.setUser(e.target.value)}
                        value={login.user}
                        margin="normal"
                        fullWidth
                        variant="outlined"
                        label="Usuario"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className='fa fa-user' />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        onChange={(e) => login.setPassword(e.target.value)}
                        value={login.password}
                        margin="normal"
                        fullWidth
                        variant="outlined"
                        label="Contraseña"
                        type={mostrarContrasena ? "text" : "password"}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className='fa fa-lock' />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setMostrarContrasena(!mostrarContrasena)}>
                                        {mostrarContrasena ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ 
                            mt: 3, 
                            mb: 2, 
                            bgcolor: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', 
                            color: 'white',
                            '&:hover': {
                                bgcolor: 'linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)'
                            }
                        }}
                    >
                        Ingresar
                    </Button>
                    <Grid container justifyContent="center">
                        <Button 
                            color="secondary" 
                            onClick={() => console.log("Contactarme")}
                            sx={{ 
                                textTransform: 'none', 
                                fontWeight: 'bold' 
                            }}
                        >
                            Contactarme
                        </Button>
                    </Grid>
                </form>
            </Container>
        </Box>
    );
}
