import React, { useRef, useContext, useMemo, useEffect } from 'react';
import { UserContext } from '../../../../context/provider/UserProvider';

export const MapaAlertas = ({ googleMap }) => {
    const { stateUser } = useContext(UserContext);
    const mapDiv = useRef();

    const mostrarZona = (listLatLng) => {
        if (googleMap && googleMap.mapDiv !== '') {
            googleMap.quitarTodosLosPoligonos();

            listLatLng.split('|').forEach((puntosDivididos, index) => {
                if (puntosDivididos !== '') {
                    const completelistLatLng = puntosDivididos.split(',').map(latLng => {
                        const [ lat, lng ] = latLng.split(';');
                        return { lat: Number(lat), lng: Number(lng) };
                    })

                    googleMap.crearPoligono({
                        id: `poligono-zona-${index}`,
                        listLatLng: completelistLatLng,
                        color: '#FF0000',
                        opacidadFondo: 0.1,

                    })

                    if (index === 0) {
                        googleMap.updateCenterAndZoom(completelistLatLng)
                    }
                }
            })
        }
    }

    useEffect(() => {
        if (googleMap) {
            googleMap.inicializarMapa(mapDiv.current);
        }
    }, [googleMap]);

    useEffect(() => {
        if (googleMap) {
            const { puntosPoligonoPais, puntosPoligonoDepartamento, puntosPoligonoProvincia, puntosPoligonoDistrito } = stateUser.poligonosInicial
            mostrarZona(puntosPoligonoDistrito !== '' ? puntosPoligonoDistrito : (puntosPoligonoProvincia !== '' ? puntosPoligonoProvincia : (puntosPoligonoDepartamento !== '' ? puntosPoligonoDepartamento : puntosPoligonoPais)))
        }
    }, [stateUser.poligonosInicial, googleMap])

    return (
        <div ref={mapDiv} style={{width:"100%", height:"100%"}}></div>
    )
}