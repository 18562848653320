import React from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

class ExcelExporter extends React.Component {
    handleExport = () => {
      const { tableId, fileName, sheetName } = this.props;
  
      const table = document.getElementById(tableId);
      const worksheet = XLSX.utils.table_to_sheet(table);
  
      // Establecer estilo de encabezado de columna
      const headerStyle = { fill: { patternType: 'solid', fgColor: { rgb: 'FF0000FF' } }, font: { bold: true } };
  
      // Obtener la referencia a la hoja de cálculo
      const range = XLSX.utils.decode_range(worksheet['!ref']);
  
      // Aplicar estilo al encabezado de columna
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cellAddress = XLSX.utils.encode_cell({ c: col, r: range.s.r });
        const cell = worksheet[cellAddress];
        cell.s = headerStyle;
      }
  
      // Convertir el libro de Excel a un archivo binario y guardarlo
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(data, fileName + '.xlsx');
    };
  
    render() {
      const { buttonText } = this.props;
  
      return (
        <button className='ml-2  bg-green-700 border  px-2 hover:bg-green-500 rounded' onClick={this.handleExport}><i className='text-3xl fa fa-file-excel'></i></button>
      );
    }
  }
  
  export default ExcelExporter;