import React, { useState } from 'react';

export const MetodosPago = () => {
    const [metodosPago, setMetodosPago] = useState([]);
    const [nuevoMetodo, setNuevoMetodo] = useState({ nombre: '', detalles: '', habilitado: true, icono: '', banco: '' });
    const bancos = ['BCP', 'BBVA', 'Interbank', 'Scotiabank', 'Banco de la Nación']; // Lista de bancos

    const handleIconoUpload = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setNuevoMetodo({ ...nuevoMetodo, icono: e.target.result });
            };
            reader.readAsDataURL(file);
        } else {
            alert("Por favor, suba una imagen válida.");
        }
    };

    const agregarMetodoPago = () => {
        setMetodosPago([...metodosPago, { ...nuevoMetodo, id: Date.now() }]);
        setNuevoMetodo({ nombre: '', detalles: '', habilitado: true, icono: '', banco: '' });
    };

    const eliminarMetodoPago = (id) => {
        setMetodosPago(metodosPago.filter(metodo => metodo.id !== id));
    };

    const habilitarDeshabilitarMetodoPago = (id) => {
        setMetodosPago(metodosPago.map(metodo =>
            metodo.id === id ? { ...metodo, habilitado: !metodo.habilitado } : metodo
        ));
    };

    return (
        <div className="p-4 text-black">
            <h2 className="text-xl font-bold mb-4 text-white">Métodos de Pago</h2>
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Nombre del método"
                    value={nuevoMetodo.nombre}
                    onChange={(e) => setNuevoMetodo({ ...nuevoMetodo, nombre: e.target.value })}
                    className="mb-2 p-2 border rounded w-full caret-black"
                />
                <textarea
                    placeholder="Detalles del método"
                    value={nuevoMetodo.detalles}
                    onChange={(e) => setNuevoMetodo({ ...nuevoMetodo, detalles: e.target.value })}
                    className="mb-2 p-2 border rounded w-full caret-black"
                />
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleIconoUpload}
                    className="mb-2 p-2 border rounded w-full text-white "
                />
                <select
                    value={nuevoMetodo.banco}
                    onChange={(e) => setNuevoMetodo({ ...nuevoMetodo, banco: e.target.value })}
                    className="mb-2 p-2 border rounded w-full"
                >
                    <option value="">Seleccione un banco</option>
                    {bancos.map((banco, index) => (
                        <option key={index} value={banco}>{banco}</option>
                    ))}
                </select>
                <button
                    onClick={agregarMetodoPago}
                    className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                >
                    Agregar Método de Pago
                </button>
            </div>
                <h2 className="text-xl font-bold mb-4 text-white">Listado de Métodos de Pago</h2>
            <div className="mt-4 flex ">
                <div className="flex justify-center lg:flex-col ">
                    {metodosPago.map((metodo) => (
                        <div key={metodo.id} className="relative m-2 p-4 border rounded w-full">
                            {metodo.icono && <img src={metodo.icono} alt="icono" className="w-12 h-12 mb-2" />}
                            <h3 className={`text-lg font-bold ${metodo.habilitado ? 'text-green-500' : 'text-red-500'}`}>
                                {metodo.nombre}
                            </h3>
                            <p className='text-white'>{metodo.detalles}</p>
                            {metodo.banco && <p className="text-gray-300 ">Banco: {metodo.banco}</p>}
                            <div className="mt-2 flex space-x-2">
                                <button
                                    onClick={() => habilitarDeshabilitarMetodoPago(metodo.id)}
                                    className={`px-4 py-2 rounded ${metodo.habilitado ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'} text-white`}
                                >
                                    {metodo.habilitado ? 'Deshabilitar' : 'Habilitar'}
                                </button>
                                <button
                                    onClick={() => eliminarMetodoPago(metodo.id)}
                                    className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                                >
                                    Eliminar
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
