import React, { useEffect, useState, useRef } from 'react';
import { BotonNuevo } from '../components/buttons/BotonNuevo';
import { ContenedorParametros } from '../components/utils/ContenedorParametros';
import { SearchTable } from '../components/utils/SearchTable';
import { Modal } from '../components/modal/Modal';
import { useServicios } from '../../hooks/useServicios';

// import { BotonNuevo } from '../components/buttons/BotonNuevo';


export const ListaServicio = () => {
    const { servicio, listarServicios,servicios, editarValorServicio, obtenerServicio,
        isOpenModal, closeModal, eliminarServicio, isOpenModalEliminar, 
        closeModalEliminar, servicioEliminar ,guardarEditarServicio} = useServicios()

        const idTablaServicios = "tablaServicios"
    return (
        <>
            <ContenedorParametros>

            <BotonNuevo onClick={() => obtenerServicio()} icono="fa fa-plus-circle"> Agregar</BotonNuevo>
                <SearchTable tablaId={idTablaServicios}></SearchTable>
            </ContenedorParametros>

            {/* containerScroll */}
            <div className="containerScroll relative contenedor-tabla ">

                <table id={idTablaServicios} className="tableResponsive " >
                    <thead className=''>
                        <tr >
                            <th>Descripcion</th>
                            <th>PrecioVenta</th>
                            <th>Estado</th>
                            <th></th>
                        </tr>

                    </thead>
                    <tbody>
                        {servicios.map((servicio) => (

                            <tr key={servicio.CodServicio}  style={{textAlign:"center",alignContent:"center",alignItems:"center"}}>
                                
                                <td data-th="Descripcion">{servicio.Descripcion}</td>
                                <td data-th="PrecioVenta">{servicio.PrecioVenta}</td>
                                <td data-th="Estado">{servicio.Estado}</td>
                                <td data-th="Opciones">
                                    <button onClick={() => obtenerServicio(servicio.CodServicio)} className="hover:text-green-300 text-green-500 text-xl px-[5px] py-1 rounded">
                                        <i className="fas fa-edit"></i>
                                    </button>
                                    <button  onClick={()=> servicioEliminar(servicio.CodServicio)} className="hover:text-red-300 text-red-500 text-xl px-[5px] py-1 rounded ml-3">
                                        <i className="fas fa-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <Modal 
                isOpen={isOpenModal} closeModal={closeModal} 
                action={() => guardarEditarServicio()}
                title="Registrar Servicio"
            >
                <div className='h-[500px]  lg:h-[500px] '>
                    <div className="wrapper">
                        <form className='form'> 
                            <div className="group">
                                <textarea className='textareaRegistro' type="textarea" rows="8" required="required" defaultValue={servicio.Descripcion} onChange={(e) => editarValorServicio("Descripcion", e.target.value)}></textarea><span className="highlight"></span><span className="bar"></span>
                                <label className='labelRegistro' >Descripción</label>
                            </div>
                            <div className="group mt-5">
                                <input  className='inputRegistro' type="number" required="required" defaultValue={servicio.PrecioVenta} onChange={(e) => editarValorServicio("PrecioVenta", e.target.value)}/><span className="highlight"></span><span className="bar"></span>
                                <label className='labelRegistro' >Precio Venta S/</label>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
            <Modal 
                isOpen={isOpenModalEliminar} closeModal={closeModalEliminar} action={() => eliminarServicio(servicio.CodServicio)}
                title= "Eliminar Servicio"
                spinner={{}}
                textButtons={{ confirm: 'Si', denied: 'No' }}
            >
                    <div className="flex gap-8 items-center flex-wrap justify-center">
                        <div className="w-[400px] px-4">
                            <label className="text-center text-gray-800">¿Está seguro de eliminar el producto? </label>
                            <hr></hr>
                            <div className='text-center mt-5  content-center items-center'>
                                <div className='border'>
                                    <label className="text-center  text-gray-800  ">---- {servicio.Descripcion}----</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                
            </Modal>   
        </>
    )

}