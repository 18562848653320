import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function SelectText(props) {
    console.log("props select -> ", props.options)

    return (
        <Autocomplete
            disableClearable
            className='mt-5'
            id={props.id}
            options={props.options}
            getOptionLabel={(option) => option.label} // Utilizamos getOptionLabel para personalizar cómo se muestra el texto en las opciones
            value={props.defaultValue}
            onChange={(event, value) => props.onChange(value)} // Pasamos el valor seleccionado a través de onChange
            
            sx={{
                width: 300,
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: props.color || '', // Cambia el color del borde a anaranjado
                    },
                    '&:hover fieldset': {
                        borderColor: props.color || '#f6682f', // Cambia el color del borde al pasar el mouse
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: props.color || '#f6682f', // Cambia el color del borde en focus
                    },
                    '& input': {
                        color: 'black', // Cambia el color del texto dentro del input
                        caretColor: 'black', // Cambia el color del caret (cursor) a negro
                    },
                },
            }}
                renderInput={(params) => <TextField {...params} label={props.label} variant="outlined"/>}
        />
    );
}









// import * as React from 'react';
// import TextField from '@mui/material/TextField';
// import Autocomplete from '@mui/material/Autocomplete';
// import Dialog from '@mui/material/Dialog';
// import DialogContent from '@mui/material/DialogContent';

// export default function SelectText(props) {
//     const [open, setOpen] = React.useState(false);
//     const inputRef = React.useRef(null);

//     const handleOpen = () => {
//         setOpen(true);
//     };

//     const handleClose = () => {
//         setOpen(false);
//     };

//     const handleDialogEntered = () => {
//         if (inputRef.current) {
//             inputRef.current.focus(); // Enfocar el campo de entrada cuando se abre el Dialog
//         }
//     };

//     return (
//         <React.Fragment>
//             <TextField
//                 className='mt-5'
//                 label={props.label}
//                 variant="outlined"
//                 onClick={handleOpen}
//                 value={props.defaultValue?.label || ''}
//                 InputProps={{
//                     readOnly: true,
//                 }}
//             />
//             <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs" onEntered={handleDialogEntered}>
//                 <DialogContent>
//                     <Autocomplete
//                         disableClearable
//                         id={props.id}
//                         options={props.options}
//                         getOptionLabel={(option) => option.label}
//                         value={props.defaultValue}
//                         onChange={(event, value) => {
//                             handleClose();
//                             props.onChange(value);
//                         }}
//                         renderInput={(params) => (
//                             <TextField
//                                 {...params}
//                                 label={props.label}
//                                 variant="outlined"
//                                 autoFocus
//                                 fullWidth
//                                 inputRef={inputRef} // Referencia al campo de entrada
//                             />
//                         )}
//                     />
//                 </DialogContent>
//             </Dialog>
//         </React.Fragment>
//     );
// }




