import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

export default function InputEtiqueta(props) {
    // const handleInput = (event) => {
    //     const { value } = event.target;
    //     // Permite solo números y un punto decimal
    //     const newValue = value.replace(/[^0-9.]/g, '');
    //     // Evita que haya más de un punto decimal
    //     if (newValue.split('.').length > 2) {
    //         return;
    //     }
    //     props.onChange(newValue); // Pasar el valor validado al componente padre
    // };

    return (
        <Box
            component="form"
            className={`${props.classBox} mt-9 caret-black w-full`}
            noValidate
            autoComplete="off"
        >
            <TextField
                fullWidth
                className={`${props.classField} w-full focus:ring-orange-500 focus:border-orange-500`}
                label={props.label}
                id={props.id}
                type={props.type || 'text'}
                required={props.required}
                value={props.value}
                defaultValue={props.defaultValue}
                onChange={props.onChange}
                multiline
                rows={props.multilinea}
                onKeyPress={props.onKeyPress}
                sx={{
                    '& .MuiInputLabel-root': {
                        color: props.labelColor || 'gray',
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: props.borderColor || '',
                        },
                        '&:hover fieldset': {
                            borderColor: props.borderColor || '#f6682f',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: props.borderColor || '#f6682f',
                        },
                        '& input': {
                            color: 'black',
                            backgroundColor: 'white',
                            borderRadius: '8px',
                            padding: '10px',
                            caretColor: '#000',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            outline: 'none',
                        },
                    },
                }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">S/</InputAdornment>,
                    style: {
                        backgroundColor: 'white',
                        borderRadius: '8px',
                        padding: '10px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        outline: 'none',
                    },
                }}
            />
        </Box>
    );
}
