import React, { useState, useEffect, useContext, useRef } from 'react';

import { UserContext } from '../../../context/provider/UserProvider';
import { useHistory } from "react-router-dom";
import logo from '../../../assets/images/logoPrometheuslargo.png'
import { SocketContext } from '../../../context/provider/SocketProvider'



import { Timer } from './Timer';

// import io from "socket.io-client";

// const socket = io(process.env.REACT_APP_SOCKET_SESION);

export const Header = ({openMenu}) => {

    const dropDown = useRef()
    const { stateUser, signOut } = useContext(UserContext)
    const { mqttDisconnect } = useContext(SocketContext)
    const history = useHistory();
    const fotoUrl= `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOIL45mNyKyZR5EXbVjTNJj894jTz5qR7BpCw_-0_UIYxN8K-kcTMCmY6PiVoqVtMJt6Q&usqp=CAU`

//    const showNotification = async () => {
//         const registration = await navigator.serviceWorker.getRegistration()
//         if( ! registration ) return alert("No hay un Service Worker Header :(")
    
//         registration.showNotification("NOTIFICACION!", {
//           body: 'Notificacion de prueba Header',
//           img: logo
//         })
//       }

    // socket.on("notificacionPush", mensajeNotificacion=>{
    //     showNotification()
    //     console.log("NOTIFICACION PUSH -> ", mensajeNotificacion)
    // })


    const singOutSession = () => {        
        // mqttDisconnect();
        signOut()
        // history.push('/login')
    }

    const toggleUser = () => {
        const isClosed = dropDown.current.classList.contains('hidden')
        isClosed && dropDown.current.classList.remove('hidden')
        !isClosed && dropDown.current.classList.add('hidden')
    }

    const verMiPerfil= ()=>{
        history.push('/perfil')  
    }

    return (
        <>
            <div className="header z-50 " >

                <div className='sticky justify-between '>

                    <div className="flex justify-between  width-full justify-center items-center ">
                        <div className="justify-center ml-2 lg:ml-1 " > 
                            <button onClick={openMenu} type="button"  className="inline-flex items-center justify-center p-2 rounded text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
                                <i className="fas fa-bars text-xl"></i>
                            </button>
                        </div>

                        <div className=" mt-1 lg:mt-2" >
                            <img  className=" w-[270px] lg:w-[200px] " src={logo} alt="Workflow"/>
                        </div>

                        <div className='mr-2 lg:mr-1 ' >
                            <button  className="right-2  px-1 py-1 hover:bg-[#f6682f]  rounded-full ">
                                <img onClick={() => toggleUser()} className='rounded-full w-[50px] h-[50px]' src={stateUser.foto || fotoUrl} alt=''/>
                                {/* <label className='w-[100px]'>{stateUser.Usuario}</label> */}
                            </button>
                        </div>

                        <div ref={dropDown} className="justify-between absolute top-[105px] right-2 hidden bg-white rounded  ring-[#f6682f] hover:ring-2  divide-y divide-blue-100 shadow bg-[#1c3958] z-50" >
                            
                            <ul className="py-2 text-sm text-white-700 " aria-labelledby="dropdownButton">
                                <li>
                                    <div className='m-3 '>
                                        <div className='mb-1' >
                                            <img className='rounded-full w-[50px] h-[50px]' src={stateUser.foto || fotoUrl} alt=''/>
                                        </div>
                                        <div className='flex items-center' >
                                            <span className="">{stateUser.nombre || "nombres"} {stateUser.apellidoPaterno || "Apellidos"}</span>
                                        </div>
                                        <div className='flex items-center mt-1'>
                                            <strong className="">{stateUser.tipoUsuario || "Administrador"} </strong>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            
                            <ul className="py-1 text-sm text-white-700 " aria-labelledby="dropdownButton">
                                <li><a onClick={()=>verMiPerfil()} className="block py-2 px-4 hover:bg-[#f6682f]"><i className='fa fa-user' /> Perfil</a></li>
                            </ul>
                            <ul className="py-1 text-sm text-white-700 " aria-labelledby="dropdownButton">
                                <li><a onClick={()=>console.log("CLICK MENSAJES")} className="block py-2 px-4 hover:bg-[#f6682f]"><i className='fa fa-envelope' /> Mensajes</a></li>
                            </ul>
                            <ul className="py-1 text-sm text-white-700 " aria-labelledby="dropdownButton">
                                <li><a onClick={()=>console.log("CLICK EN CONFIGURACION")} className="block py-2 px-4 hover:bg-[#f6682f]"><i className='fa fa-cog' /> Configuración</a></li>
                            </ul>
                            <ul className="py-1 text-sm text-white-700 text-white-900" aria-labelledby="dropdownButton">
                                <li><a onClick={() => singOutSession()} className="block py-2 px-4 hover:bg-[#f6682f]">Cerrar Sesión</a></li>
                            </ul>
                        </div> 
                    </div>

                </div>

            </div>

        </>
    )
        
}