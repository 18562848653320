import React, { useEffect, useState, useRef, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { UserContext } from '../../context/provider/UserProvider';
import { SocketContext } from '../../context/provider/SocketProvider';



export const Perfil = () => {
    const { stateUser, signOut } = useContext(UserContext)
    const fotoUrl= `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOIL45mNyKyZR5EXbVjTNJj894jTz5qR7BpCw_-0_UIYxN8K-kcTMCmY6PiVoqVtMJt6Q&usqp=CAU`
    useEffect(()=>{
        console.log("user -> ", stateUser)
    },[])

    // codUsuario: datosSession.codUsuario || 0,
    // nomUsuario: datosSession.nomUsuario || '',
    // nombre: datosSession.nombre || '',
    // apellidoPaterno:  datosSession.apellidoPaterno || '',
    // apellidoMaterno:  datosSession.apellidoMaterno || '',
    // fechaNacimiento:  datosSession.fechaNacimiento || '',
    // direccion:  datosSession.direccion || '',
    // celular:  datosSession.celular || '',
    // email:  datosSession.email || '',
    // empresa:  datosSession.empresa || '',
    // foto:  datosSession.foto || '',

    // token: datosSession.token || '',
    // tokenExpire: datosSession.tokenExpire || 0,
    // menus: datosSession.menus || [],
    // permisos: datosSession.permisos || [],

    return (
        <>          
            <div>
                <img className='rounded w-[100px] h-[100px]' src={stateUser.foto || fotoUrl} alt=''/>

            </div>

            {/* etiqueta de nombre */}
                <div className='mt-2 border'>  
                    <label>{stateUser.nomUsuario}</label>
                </div>
    
                <div className='mt-2 border'>  
                    <label>{stateUser.apellidoMaterno}</label>
                </div>

            <div>
                
            </div>


        </>
    )

}