// import CryptoJS from 'crypto-js';

// // Recibe 'ciphertext' y 'clave' desde Python (de manera segura)
// const ciphertext = '...' // Coloca el valor recibido aquí
// const clave = '...' // Coloca el valor recibido aquí

// // Decodifica la clave desde base64
// const claveDecodificada = CryptoJS.enc.Base64.parse(clave);

// // Desencripta los datos
// const bytes = CryptoJS.AES.decrypt(ciphertext, claveDecodificada, {
//   mode: CryptoJS.mode.CFB, // Puedes ajustar el modo según cómo se cifró
// });

// // Convierte los bytes desencriptados a una cadena JSON
// const dataJson = bytes.toString(CryptoJS.enc.Utf8);

// // Convierte la cadena JSON a un objeto JavaScript
// const data = JSON.parse(dataJson);

// console.log(data); // Deberías ver el diccionario original

import { useContext } from "react";
import { UserContext } from "../context/provider/UserProvider";



export const AuthFetch = async ({
    url,
    method,
    headers,
    body,
    typeResponse
}) => {
    // const { stateUser, signOut } = useContext(UserContext)
    const decifrado = atob(localStorage.getItem('pm-session') || '')
    const datosSession = decifrado == '' ? {} : JSON.parse(decifrado);
    const token = 'Bearer' + ' ' + datosSession?.token;
    
    const headersDefault = headers || {
        'Content-Type': 'application/json',
    };

    const response = await fetch(url, {
        method: method || 'GET',
        headers: {
            ...headersDefault,
            'Authorization': token
        },
        body: body || null
    });

    if ([401].includes(Number(response.status))) {
        localStorage.clear();
        window.location.reload(false);
    }

    return await response[typeResponse || 'json']()
}




export const Fetch = async ({
    url,
    method,
    headers,
    body,
    typeResponse
}) => {

    const headersDefault = headers || {
        'Content-Type': 'application/json',
    };

    const response = await fetch(url, {
        method: method || 'GET',
        headers: {
            ...headersDefault,
        },
        body: body || null
    });

    if ([401].includes(Number(response.status))) {
        localStorage.clear();
        window.location.reload(false);
    }

    return await response[typeResponse || 'json']()
}