import React from "react";
import { Spinner } from '../utils/Spinner';
import { useSpinner } from "../../../hooks/useSpinner";
import Draggable from "react-draggable";

export const Side = ({
    isOpen, 
    closeModal, 
    action, 
    width, 
    children, 
    title, 
    cerrarAlGuardar = false,
    textButtons = { confirm: 'Guardar', denied: 'Cancelar' }, 
    validButton = { confirm: true , denied: true}
}) => {
    const [ spinner, mostrarSpinner, ocultarSpinner ] = useSpinner(); 

    return (
    <>  
        {isOpen ? (
            <>
            
                        <div className="z-50 h-[86%] w-[40%] lg:h-[80%] lg:w-full text-white fixed right-0 top-[100px] bottom-0 bg-gray-200 ">
                            {/*header*/}
                            <strong>
                                <div className=" h-[50px] text-center bg-[#f6682f]   flex items-start  p-2  ">
                                    <button
                                        className="fa fa-angle-double-right text-white-500 hover:text-orange-400 bg-steel-500  rounded capitalize px-2 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-3xl"
                                        type="button"
                                        onClick={closeModal}
                                        >
                                        
                                    </button>
                                    <h3 className="text-2xl mr-10 font-semibold">
                                        {title}
                                    </h3>
                                </div>
                            </strong>

                            {/* CONTENT */}
                            <div className="h-[97%] shadow-2xl relative flex flex-col w-full bg-[#ffff] outline-none focus:outline-none ">
                                {/*body*/}
                                <div className=" relative p-2 flex-auto overflow-y-scroll containerScroll">
                                    { children }
                                </div>
                                

                                {/*footer*/}
                                {/* <div className=" h-[50px] text-center bg-[#f6682f]  rounded-t-xl flex items-start  p-2   "> */}
                                <div className="border-t border-gray-300 p-1 flex items-center justify-center ">
                                {validButton.confirm ?
                                    <button
                                        className="bg-green-600 hover:bg-green-700 text-white active:bg-green-600 font-bold capitalize text-xl px-5 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 "
                                        type="button"
                                            onClick={async () => {
                                                mostrarSpinner();
                                                await action();
                                                ocultarSpinner();
                                                // cerrarAlGuardar && closeModal()
                                            }}
                                        >
                                            {
                                                spinner 
                                                ? <Spinner spinner={spinner.toString()}></Spinner>
                                                : <span>{textButtons.confirm}</span>
                                            }
                                    </button>
                                    : ''
                                }
                                {validButton.denied ?
                                    <button
                                    className="text-white-500 hover:bg-red-600 bg-red-500 rounded font-bold capitalize px-6 py-2 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-[20px]"
                                    type="button"
                                    onClick={closeModal}
                                    >
                                        {textButtons.denied} 
                                    </button>
                                    : ''
                                }
                            </div>

                        </div>
                    </div>
            </>
        ) : null}
        </>
    );
}