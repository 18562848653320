import React, { useState } from 'react';

export const Banners = () => {
    const [images, setImages] = useState([]);
    const [previewImage, setPreviewImage] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file && (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp')) {
            setLoading(true);
            const reader = new FileReader();
            reader.onload = (e) => {
                setPreviewImage({ src: e.target.result, file });
                setLoading(false);
            };
            reader.readAsDataURL(file);
        } else {
            alert("Por favor, suba una imagen en formato JPEG, PNG o WebP.");
        }
    };

    const handleConfirmImage = () => {
        setImages([...images, previewImage]);
        setPreviewImage(null);
    };

    const handleRemovePreviewImage = () => {
        setPreviewImage(null);
    };

    const handleImageRemove = (index) => {
        const updatedImages = images.filter((_, i) => i !== index);
        setImages(updatedImages);
    };

    return (
        <div className="p-4">
            <div className="mb-4 flex flex-col items-center">
                <div className="relative w-[1500px] h-[400px] border rounded overflow-hidden flex items-center justify-center bg-gray-600">
                    {previewImage ? (
                        <>
                            <img src={previewImage.src} alt="preview" className="max-w-none max-h-full"/>
                            <button
                                onClick={handleRemovePreviewImage}
                                className="absolute top-2 right-2 bg-red-500 text-white rounded-full px-2 py-1 text-sm"
                            >
                                Eliminar
                            </button>
                        </>
                    ) : (
                        <span className="text-gray-500">Tamaño recomendado (1500x400 pixels) Mantenga el contenido principal al centro</span>
                    )}
                </div>
                {previewImage && (
                    <button
                        onClick={handleConfirmImage}
                        className="mt-2 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                    >
                        Guardar Imagen
                    </button>
                )}
            </div>
            <div>
                <h2 className="text-xl font-bold mb-4">Subir imagen</h2>
                <input type="file" accept="image/jpeg,image/png,image/webp" onChange={handleImageUpload} className="mb-4 p-2 border rounded"/>
                {loading && (
                    <div className="flex justify-center items-center mb-4">
                        <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-4 border-gray-400"></div>
                        <span className="ml-2">Cargando...</span>
                    </div>
                )}
            </div>
            <div className="flex flex-wrap">
                {images.map((image, index) => (
                    <div key={index} className="relative m-2">
                        <div className="relative w-[300px] h-[150px] border rounded overflow-hidden">
                            <img src={image.src} alt={`banner-${index}`} className="object-cover w-full h-full"/>
                        </div>
                        <button
                            onClick={() => handleImageRemove(index)}
                            className="absolute top-2 right-2 bg-red-500 text-white rounded-full px-2 py-1 text-sm"
                        >
                            Eliminar
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};
