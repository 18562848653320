import React, { useState, useRef, useEffect } from 'react';
import { ResizableBox } from 'react-resizable';
import Draggable from 'react-draggable';
import 'react-resizable/css/styles.css';

export const Pedidos = () => {
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [overlayImages, setOverlayImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const canvasRef = useRef(null);
    const [banners, setBanners] = useState([]);

    const handleBackgroundImageUpload = (event) => {
        const file = event.target.files[0];
        if (file && (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp')) {
            setLoading(true);
            const reader = new FileReader();
            reader.onload = (e) => {
                setBackgroundImage(e.target.result);
                setLoading(false);
            };
            reader.readAsDataURL(file);
        } else {
            alert("Por favor, suba una imagen en formato JPEG, PNG o WebP.");
        }
    };

    const handleOverlayImageUpload = (event) => {
        const file = event.target.files[0];
        if (file && file.type === 'image/png') {
            const reader = new FileReader();
            reader.onload = (e) => {
                const newOverlay = {
                    src: e.target.result,
                    position: { x: 0, y: 0 },
                    size: { width: 100, height: 100 },
                    id: Date.now()
                };
                setOverlayImages([...overlayImages, newOverlay]);
            };
            reader.readAsDataURL(file);
        } else {
            alert("Por favor, suba una imagen en formato PNG.");
        }
    };

    const handleSaveImage = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const background = new Image();
        background.src = backgroundImage;

        background.onload = () => {
            ctx.drawImage(background, 0, 0, canvas.width, canvas.height);
            overlayImages.forEach(overlay => {
                const img = new Image();
                img.src = overlay.src;
                img.onload = () => {
                    ctx.drawImage(
                        img,
                        overlay.position.x,
                        overlay.position.y,
                        overlay.size.width,
                        overlay.size.height
                    );
                    const combinedImage = canvas.toDataURL('image/jpeg');
                    setBanners([...banners, combinedImage]);
                    // Limpia el área de vista previa
                    setBackgroundImage(null);
                    setOverlayImages([]);
                };
            });
        };
    };

    const updateOverlayPosition = (id, x, y) => {
        setOverlayImages(overlayImages.map(overlay =>
            overlay.id === id ? { ...overlay, position: { x, y } } : overlay
        ));
    };

    const updateOverlaySize = (id, width, height) => {
        setOverlayImages(overlayImages.map(overlay =>
            overlay.id === id ? { ...overlay, size: { width, height } } : overlay
        ));
    };

    useEffect(() => {
        if (backgroundImage) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            const background = new Image();
            background.src = backgroundImage;

            background.onload = () => {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(background, 0, 0, canvas.width, canvas.height);
                overlayImages.forEach(overlay => {
                    const img = new Image();
                    img.src = overlay.src;
                    img.onload = () => {
                        ctx.drawImage(
                            img,
                            overlay.position.x,
                            overlay.position.y,
                            overlay.size.width,
                            overlay.size.height
                        );
                    };
                });
            };
        }
    }, [backgroundImage, overlayImages]);

    return (
        <div className="p-4">
            <h2 className="text-xl font-bold mb-4">Subir imagen de fondo</h2>
            <input type="file" accept="image/jpeg,image/png,image/webp" onChange={handleBackgroundImageUpload} className="mb-4 p-2 border rounded" />
            <h2 className="text-xl font-bold mb-4">Subir imagen superpuesta (PNG)</h2>
            <input type="file" accept="image/png" onChange={handleOverlayImageUpload} className="mb-4 p-2 border rounded" />
            {loading && (
                <div className="flex justify-center items-center mb-4">
                    <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-4 border-gray-400"></div>
                    <span className="ml-2">Cargando...</span>
                </div>
            )}
            <div className="relative w-[1500px] h-[400px] border rounded overflow-hidden bg-gray-100">
                <canvas ref={canvasRef} width={1500} height={400} />
                {overlayImages.map((overlay) => (
                    <Draggable
                        key={overlay.id}
                        bounds="parent"
                        position={overlay.position}
                        onStop={(e, data) => updateOverlayPosition(overlay.id, data.x, data.y)}
                    >
                        <ResizableBox
                            width={overlay.size.width}
                            height={overlay.size.height}
                            minConstraints={[50, 50]}
                            maxConstraints={[400, 400]}
                            onResizeStop={(e, data) => updateOverlaySize(overlay.id, data.size.width, data.size.height)}
                        >
                            <img src={overlay.src} alt="overlay" className="w-full h-full" />
                        </ResizableBox>
                    </Draggable>
                ))}
            </div>
            <div className="flex space-x-2 mt-2">
                <button
                    onClick={handleSaveImage}
                    className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                >
                    Guardar Imagen
                </button>
                <button
                    onClick={() => {
                        setBackgroundImage(null);
                        setOverlayImages([]);
                    }}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                    Subir a Banners
                </button>
            </div>
            <div className="mt-4">
                <h2 className="text-xl font-bold mb-4">Banners Subidos</h2>
                <div className="flex flex-wrap">
                    {banners.map((banner, index) => (
                        <div key={index} className="relative m-2">
                            <div className="relative w-[300px] h-[150px] border rounded overflow-hidden">
                                <img src={banner} alt={`banner-${index}`} className="object-cover w-full h-full" />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
