import React, { useState, useEffect } from 'react';
import { notify } from '../../utils/utils';
import jsQR from 'jsqr';

const Reporte1 = () => {
  const [stream, setStream] = useState(null);
  const [facingMode, setFacingMode] = useState('environment');
  const videoRef = React.createRef();
  const canvasRef = React.createRef();
  const [errorMessage, setErrorMessage] = useState('');
  const [valorQR, setValorQR] = useState('');

  useEffect(() => {
    const openCamera = async () => {
      try {
        const mediaStream = await navigator?.mediaDevices?.getUserMedia({ video: { facingMode } });

        videoRef.current.srcObject = mediaStream;
        setStream(mediaStream);
      } catch (error) {
        console.error('Error al abrir la cámara:', error);
        setErrorMessage('Error al abrir la cámara: ' + error.message);
      }
    };

    openCamera();

    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, [facingMode]);

  const toggleCamera = () => {
    setErrorMessage('');
    setFacingMode(facingMode === 'environment' ? 'user' : 'environment');
  };

  const extractQR = () => {
      if (videoRef.current && videoRef.current.videoWidth > 0 && videoRef.current.videoHeight > 0) {
        // console.log("entro a la funcion current")
      const video = videoRef.current;
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      const { videoWidth, videoHeight } = video;

      canvas.width = videoWidth;
      canvas.height = videoHeight;
      context.drawImage(video, 0, 0, videoWidth, videoHeight);

      // Extrae el código QR de la imagen
      const imageData = context.getImageData(0, 0, videoWidth, videoHeight);
      const code = jsQR(imageData.data, videoWidth, videoHeight);

      if (code) {
        notify('Código QR detectado y extraído automáticamente: ' + code.data, 'success');
        console.log("QR -> ", code.data);
        setValorQR(code.data);
      }
    }

    // Solicita la próxima animación de fotograma
    requestAnimationFrame(extractQR);
  };

  const captureImageAndExtractQR = () => {
        // setAutoScan(true); // Habilita el escaneo automático cuando se hace clic en el botón
        extractQR(); // Inicia el escaneo automático inmediatamente después de extraer manualmente
      };
//   Comienza la extracción automática al montar el componente
  useEffect(() => {
    extractQR();
  }, []);

  return (
    <>
      <div>
        <video ref={videoRef} autoPlay />
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <button className='bg-green-700 p-2' onClick={toggleCamera}>Cambiar Cámara</button>
        <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
        <button className='m-2 bg-blue-800 p-2' onClick={captureImageAndExtractQR}>Extraer QR</button>

        <br />
        <div className='border w-[500px] h-[100px] overflow-y-auto break-words whitespace-pre-wrap'>
          {valorQR}
        </div>
      </div>
    </>
  );
};

export default Reporte1;



// import React, { useState, useEffect } from 'react';
// import { notify } from '../../utils/utils';
// import jsQR from 'jsqr';

// const Reporte1 = () => {
//   const [stream, setStream] = useState(null);
//   const [facingMode, setFacingMode] = useState('environment');
//   const videoRef = React.createRef();
//   const canvasRef = React.createRef();
//   const [errorMessage, setErrorMessage] = useState('');
//   const [autoScan, setAutoScan] = useState(true); // Variable para habilitar/deshabilitar el escaneo automático
// const [valorQR, setValorQR ] = useState()

//   useEffect(() => {
//     const openCamera = async () => {
//       try {
//         const mediaStream = await navigator?.mediaDevices?.getUserMedia({ video: { facingMode } });

//         videoRef.current.srcObject = mediaStream;
//         setStream(mediaStream);
//       } catch (error) {
//         console.error('Error al abrir la cámara:', error);
//         setErrorMessage('Error al abrir la cámara: ' + error.message);
//       }
//     };

//     openCamera();

//     return () => {
//       if (stream) {
//         stream.getTracks().forEach((track) => {
//           track.stop();
//         });
//       }
//     };
//   }, [facingMode]);

//   const toggleCamera = () => {
//     setErrorMessage('');
//     setFacingMode(facingMode === 'environment' ? 'user' : 'environment');
//   };

//   const extractQR = () => {
//     if (autoScan && videoRef.current && videoRef.current.videoWidth > 0 && videoRef.current.videoHeight > 0) {
//       const video = videoRef.current;
//       const canvas = canvasRef.current;
//       const context = canvas.getContext('2d');
//       const { videoWidth, videoHeight } = video;

//       canvas.width = videoWidth;
//       canvas.height = videoHeight;
//       context.drawImage(video, 0, 0, videoWidth, videoHeight);

//       // Extrae el código QR de la imagen
//       const imageData = context.getImageData(0, 0, videoWidth, videoHeight);
//       const code = jsQR(imageData.data, videoWidth, videoHeight);

//       if (code) {
//         notify('Código QR detectado y extraído automáticamente: ' + code.data, 'success');
//         console.log("QR -> ",code.data)
//         setValorQR(code.data)
//         setAutoScan(false); // Desactiva el escaneo automático después de encontrar un código QR
//       }
//     }

//     // Solicita la próxima animación de fotograma
//     requestAnimationFrame(extractQR);
//   };

//   const captureImageAndExtractQR = () => {
//     setAutoScan(true); // Habilita el escaneo automático cuando se hace clic en el botón
//     extractQR(); // Inicia el escaneo automático inmediatamente después de extraer manualmente
//   };

//   // Comienza la extracción automática al montar el componente
//   useEffect(() => {
//     extractQR();
//   }, []);

//   return (
//     <>
//       <div>
//         <video ref={videoRef} autoPlay />
//         {errorMessage && <p className="error-message">{errorMessage}</p>}
//         <button className='bg-green-700 p-2' onClick={toggleCamera}>Cambiar Cámara</button>
//         <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
//         <button className='m-2 bg-blue-800 p-2' onClick={captureImageAndExtractQR}>Extraer QR</button>
//         <br/>
//         <div className='border w-[500px] h-[100px] overflow-y-auto break-words whitespace-pre-wrap'>
//             {valorQR}
//         </div>
//       </div>
//     </>
//   );
// };

// export default Reporte1;
