import React, { useEffect, useState, useRef } from 'react';
import { BotonNuevo } from '../../components/buttons/BotonNuevo';
import { ContenedorParametros } from '../../components/utils/ContenedorParametros';
import { SearchTable } from '../../components/utils/SearchTable';
import { Modal } from '../../components/modal/Modal';
import fotoDefault from '../../../assets/images/productoDefault.jpg';
import { useProductos } from '../../../hooks/useProductos';
import { useCategorias } from '../../../hooks/useCategorias';
import { Side } from '../../components/modal/Side';
import InputText from '../../components/InputText';
import SelectText from '../../components/SelectText';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { CloudUpload } from '@mui/icons-material';
import { Button} from '@mui/material';
import InputEtiqueta from '../../components/InputEtiqueta';
import { soloDecimal } from '../../../utils/utils';
// import ImageCompressor from 'image-compressor'
import Switch, { SwitchProps } from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import iconoPublicarProducto from '../../../assets/images/iconPublicarProducto.fw.png'
import iconoNOPublicarProducto from '../../../assets/images/iconNOPublicarProducto.fw.png'
import iconoDestacar from '../../../assets/images/productoDestacado.fw.png'
import iconoNODescatar from '../../../assets/images/productoNODestacado.fw.png'
import { useMarcas } from '../../../hooks/useMarcas';



export const Productos = () => {
    // const { personas, listarPersonas, guardarEditarPersona, persona, editarValorPersona, obtenerPersona, isOpenModal, closeModal , cargarDatosPersona, fotoUrlPersona, setFotoFile,setFotoUrlPersona,eliminarPersona,isOpenModalEliminar,closeModalEliminar,personaEliminar} = useProductos();
    const { 
        producto,
        listarProductos,
        guardarProductoConFoto,
        productos,
        editarValorProducto,
        obtenerProducto,
        isOpenModal,
        closeModal,
        fotoUrlProducto,
        fotoUrlProducto2,
        fotoUrlProducto3,
        setFotoFile,
        setFotoFile2,
        setFotoFile3,
        setFotoUrlProducto,
        setFotoUrlProducto2,
        setFotoUrlProducto3,
        eliminarProducto,
        isOpenModalEliminar,
        closeModalEliminar,
        productoEliminar
    } = useProductos()
    const { categoria, listarCategorias, categorias, editarValorCategoria, obtenerCategoria,
        isOpenModalC, closeModalC,eliminarCategoria, isOpenModalEliminarC, 
        closeModalEliminarC, categoriaEliminar,guardarEditarCategoria } = useCategorias()

const {marca, listarMarcas, marcas, editarValorMarca, obtenerMarca,
    isOpenModalM, closeModalM,eliminarMarca, isOpenModalEliminarM, 
    closeModalEliminarM, marcaEliminar,guardarEditarMarca } = useMarcas()


    const opcionesCategoria = categorias.map((opcion) => ({
        id: opcion.CodCategoria,
        label: opcion.Nombre,
    }));
    
    const opcionesMarca = marcas.map((opcion) => ({
        id: opcion.CodMarca,
        label: opcion.Nombre,
    }));

    const [ switchPublicar, setSwitchPublicar] = useState(true); 
    const [ switchDestacar, setSwitchDestacar] = useState(true); 



    const idTablaProductos = "tablaProductos"
    const [selectedFile, setSelectedFile] = useState()
    const [selectedFile2, setSelectedFile2] = useState()
    const [selectedFile3, setSelectedFile3] = useState()
    const inputFoto = useRef();
    const inputFoto2 = useRef();
    const inputFoto3 = useRef();

    const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif'];

    useEffect(() => {
        if (!selectedFile) {
            setFotoUrlProducto(fotoDefault)
            // setSelectedFile(null)
            return
        }
        const objectUrl = URL.createObjectURL(selectedFile)
        setFotoUrlProducto(objectUrl || fotoDefault)
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])

    useEffect(() => {
        if (!selectedFile2) {
            setFotoUrlProducto2(fotoDefault)
            // setSelectedFile(null)
            return
        }
        const objectUrl = URL.createObjectURL(selectedFile2)
        setFotoUrlProducto2(objectUrl || fotoDefault)
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile2])

    useEffect(() => {
        if (!selectedFile3) {
            setFotoUrlProducto3(fotoDefault)
            // setSelectedFile(null)
            return
        }
        const objectUrl = URL.createObjectURL(selectedFile3)
        setFotoUrlProducto3(objectUrl || fotoDefault)
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile3])


    const onSelectFile = async e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(fotoDefault)
            return
        }
        setSelectedFile(e.target.files[0])

        setFotoFile(e.target.files[0])
    }

    const onSelectFile2 = async e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile2(fotoDefault)
            return
        }
        setSelectedFile2(e.target.files[0])

        setFotoFile2(e.target.files[0])
    }

    const onSelectFile3 = async e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile3(fotoDefault)
            return
        }
        setSelectedFile3(e.target.files[0])

        setFotoFile3(e.target.files[0])
    }

    const cambiarPublicado = () => {
        setSwitchPublicar(!switchPublicar); 
        editarValorProducto("Publicado", !switchPublicar)
    };
    const cambiarDestacado = () => {
        setSwitchDestacar(!switchDestacar); 
        editarValorProducto("Destacado", !switchDestacar)
    };



    const MaterialUISwitch = styled(Switch)(({ theme }) => ({
        width: 120,
        height: 35,
        padding: 10,
        margin:1,
        '& .MuiSwitch-switchBase': {
          margin: 1,
          padding: 1,
          transform: 'translateX(6px)',
          '&.Mui-checked': {
            color: '#000000',
            transform: 'translateX(75px)', 
            '& .MuiSwitch-thumb:before': {
              backgroundImage: `url(${iconoPublicarProducto})`,
              backgroundSize: 'cover',
            },
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aaafbe',
            },
          },
        },
        '& .MuiSwitch-thumb': {
          backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
          width: 40,
          height: 40,
          '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url(${iconoNOPublicarProducto})`,
            backgroundSize: 'cover',
          },
        },
        '& .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aaafbe',
          borderRadius: 20 / 2,
        },
        '& .MuiFormControlLabel-label': {
            // Agrega tus estilos específicos para el label aquí
            // Por ejemplo, puedes cambiar el color del texto
            color: '#FF0000',
            // Añadir más estilos según tus necesidades
          },


    }));
    
    const MaterialUISwitchDestacado = styled(Switch)(({ theme }) => ({
    width: 120,
    height: 35,
    padding: 10,
    margin:1,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 1,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
        color: '#000000',
        transform: 'translateX(75px)', 
        '& .MuiSwitch-thumb:before': {
            backgroundImage: `url(${iconoDestacar})`,
            backgroundSize: 'cover',
        },
        '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aaafbe',
        },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
        width: 40,
        height: 40,
        '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url(${iconoNODescatar})`,
        backgroundSize: 'cover',
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aaafbe',
        borderRadius: 20 / 2,
    },
    }));


    return (
        <>

        <ContenedorParametros>

        <BotonNuevo onClick={() => obtenerProducto()} icono="fa fa-plus-circle"> Nuevo</BotonNuevo>
            <SearchTable tablaId={idTablaProductos}></SearchTable>
        </ContenedorParametros>
        
        <div className="containerScroll relative contenedor-tabla ">

            <table id={idTablaProductos} className="tableResponsiveProductos  " >
                <thead className=''>
                    <tr >
                        <th>Foto</th>
                        <th>Nombre</th>
                        <th>Descripcion</th>
                        <th>Categoria</th>
                        <th>Marca</th>
                        <th>Stock</th>
                        <th>Precio Venta</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        productos.length > 0 ?
                        productos.map((producto) => (

                        <tr key={producto.CodProducto} className="text-center center center-items">
                            <td  data-th="Foto" className=' pt-2 center-items  '>
                                <img src={producto.Foto1} alt={producto.Descripcion} className=" lg:ml-20  lg:rounded rounded-full w-[60px] h-[60px] lg:w-[200px] lg:h-[200px]"/>
                            </td>
                            <td data-th="Nombre" className=''>{producto.Nombre}</td>
                            <td data-th="Descripcion" className='w-[40%]'>{producto.Descripcion}</td>
                            <td data-th="Categoria" className='w-auto'>{producto.NombreCategoria}</td>
                            <td data-th="Marca" className='w-auto'>{producto.NombreMarca}</td>
                            <td data-label='Stock ' data-th="Stock" className=''>{producto.Stock}</td>
                            <td data-label='Precio Venta ' data-th="PrecioVenta">S/ {producto.PrecioVenta}</td>
                            <td data-th="Opciones" className='w-[90px]'>
                                <BorderColorRoundedIcon className="hover:text-green-300 text-green-500  rounded mr-3" style={{fontSize:"25px"}} onClick={() => obtenerProducto(producto.CodProducto)} />
                                <DeleteRoundedIcon className="hover:text-red-300 text-red-500  rounded lg:ml-3 " style={{fontSize:"25px"}} onClick={()=> productoEliminar(producto.CodProducto)} />

                                {/* <button onClick={() => obtenerProducto(producto.CodProducto)} className="hover:text-green-300 text-green-500 text-xl px-[5px] py-1 rounded">
                                    <i className="fas fa-edit"></i>
                                </button> */}
                                {/* <button  onClick={()=> productoEliminar(producto.CodProducto)} className="hover:text-red-300 text-red-500 text-xl px-[5px] py-1 rounded ml-1 lg:ml-3">
                                    <i className="fas fa-trash"></i>
                                </button> */}
                            </td>
                        </tr>
                    ))
                    :<tr ><td colSpan='7' className='text-center'>No tiene productos registrados o hubo un problema al cargar... Intente Nuevamente</td></tr>

                    }

                </tbody>
            </table>
                </div>
            <Modal 
                isOpen={isOpenModalEliminar} closeModal={closeModalEliminar} action={() => eliminarProducto(producto.CodProducto)}
                title= "Eliminar Producto"
                spinner={{}}
                textButtons={{ confirm: 'Si', denied: 'No' }}
            >
                    
                    <div className=' text-center '>

                    <ErrorOutlineIcon className='mb-4 center text-yellow-500 ' style={{fontSize:"100px"}} />

                    <div className="  flex items-center justify-center">
                        <div className=" text-center">
                            <label className="text-center text-xl text-gray-800">¿Está seguro de eliminar el producto? </label>
                            <hr></hr>
                            <div className='text-center mt-5 justify-center  content-center items-center'>
                                <div className=''>
                                    <label className="text-center  font-bold text-gray-800  ">-- {producto.Nombre} --</label>
                                </div>
                                <div className='mt-5 flex justify-center content-center text-center'>
                                    <img className=' rounded-xl w-[150px] h-[150px] mb-1 cursor-pointer' src={fotoUrlProducto} alt=''  />
                                </div>

                            </div>
                        </div>
                    </div>
                    </div>
            </Modal>   

                <Modal
      title={producto.CodProducto > 0 ? "Editar Producto" : "Registrar Producto"}
      isOpen={isOpenModal} closeModal={closeModal}
      action={() => guardarProductoConFoto()}
    >
      <div className='lg:h-[500px] containerScroll max-h-[400px]'>
        <div className="flex flex-wrap justify-center items-center">

          <div className="flex flex-col items-center mx-5">
            <input type="file" ref={inputFoto} style={{ display: 'none' }} accept="image/*" onChange={onSelectFile} />
            <img
              className='hover:ring-2 hover:ring-[#E2504C] rounded w-[250px] h-[250px] mb-1 lg:w-[200px] lg:h-[200px] cursor-pointer'
              src={fotoUrlProducto}
              onClick={() => inputFoto.current.click()}
              alt=''
            />
          </div>

          <div className="flex flex-col items-center mx-5">
            <input type="file" ref={inputFoto2} style={{ display: 'none' }} accept="image/*" onChange={onSelectFile2} />
            <img
              className='hover:ring-2 hover:ring-[#E2504C] rounded w-[100px] h-[100px] mb-1 lg:w-[80px] lg:h-[80px] cursor-pointer'
              src={fotoUrlProducto2}
              onClick={() => inputFoto2.current.click()}
              alt=''
            />
            <input type="file" ref={inputFoto3} style={{ display: 'none' }} accept="image/*" onChange={onSelectFile3} />
            <img
              className='hover:ring-2 hover:ring-[#E2504C] rounded w-[100px] h-[100px] mb-1 lg:w-[80px] lg:h-[80px] cursor-pointer'
              src={fotoUrlProducto3}
              onClick={() => inputFoto3.current.click()}
              alt=''
            />
          </div>

          <div className="mt-2">
            <Fab
              variant="extended"
              color="success"
              size="small"
              onClick={() => inputFoto.current.click()}
            >
              <CloudUpload sx={{ mr: 1 }} />
              <span className="text-xs">Subir Imagen</span>
            </Fab>
          </div>
        </div>

        <div className='flex flex-wrap justify-between'>
          <div className='w-full lg:w-1/3 px-2'>
            <InputText
              classBox=''
              id='Nombre'
              label="Nombre"
              defaultValue={producto.Nombre}
              onChange={(e) => editarValorProducto("Nombre", e.target.value)}
            />
          </div>

          <div className='w-full lg:w-1/3 px-2'>
            <InputEtiqueta
              classBox=''
              id='PrecioVenta'
              label="Precio de Venta"
              type="number"
              defaultValue={producto.PrecioVenta}
              onChange={(e) => editarValorProducto("PrecioVenta", e.target.value)}
              onKeyPress={(e) => soloDecimal(e, e.target)}
            />
          </div>

          <div className='w-full lg:w-1/3 px-2'>
            <SelectText
              options={opcionesCategoria}
              label="Categoria"
              id="comboCategoria"
              defaultValue={producto.CodCategoria ? opcionesCategoria.find((option) => option.id === producto.CodCategoria) : null}
              onChange={(valor) => {
                editarValorProducto("CodCategoria", valor.id);
                editarValorProducto("NombreCategoria", valor.label)
              }}
            />
            <SelectText
              options={opcionesMarca}
              label="Marca"
              id="comboMarcas"
              defaultValue={producto.CodMarca ? opcionesMarca.find((option) => option.id === producto.CodMarca) : null}
              onChange={(valor) => {
                editarValorProducto("CodMarca", valor.id);
                editarValorProducto("NombreMarca", valor.label)
              }}
            />
          </div>

          <div className='w-full px-2'>
            <InputText
              classBox=''
              multilinea={4}
              id='DescripcionProducto'
              label="Descripción"
              type="text"
              defaultValue={producto.Descripcion}
              onChange={(e) => editarValorProducto("Descripcion", e.target.value)}
            />
          </div>
        </div>

        <div className='flex flex-wrap border text-gray-800 p-4'>
          <div className='w-full lg:w-1/2 px-2'>
            <FormControlLabel
              control={
                <MaterialUISwitchDestacado
                  style={{
                    margin: 1,
                    transition: 'transform 900ms cubic-bezier(0.4, 0, 0.2, 1)',
                    transform: switchDestacar ? 'translateX(75px)' : 'translateX(5px)',
                  }}
                  checked={switchDestacar}
                  onChange={cambiarDestacado}
                />
              }
              label="Destacar"
            />
          </div>

          <div className='w-full lg:w-1/2 px-2'>
            <FormControlLabel
              control={
                <MaterialUISwitch
                  style={{
                    margin: 1,
                    transition: 'transform 900ms cubic-bezier(0.4, 0, 0.2, 1)',
                    transform: switchPublicar ? 'translateX(75px)' : 'translateX(5px)',
                  }}
                  checked={switchPublicar}
                  onChange={cambiarPublicado}
                  defaultChecked
                />
              }
              label="Publicar"
            />
          </div>

          <div className='w-full px-2'>
            <InputEtiqueta
              classBox=''
              id='PrecioPromocional'
              label="Precio Promocional"
              type="number"
              defaultValue={producto.PrecioPromocional}
              onChange={(e) => editarValorProducto("PrecioPromocional", e.target.value)}
              onKeyPress={(e) => soloDecimal(e, e.target)}
            />
          </div>
        </div>
      </div>
    </Modal>



        </>
    )

}